<template>
  <div class="header">
  	<el-dialog :close-on-click-modal="false"
		  title="修改密码"
		  :visible.sync="editPassword"
		  width="30%"
		  :before-close="handleDialogClose"
		  :append-to-body="true">
		  	<el-form :model="form" :rules="rules" ref="form" label-width="100px">
			    <el-form-item label="原密码"  prop="firstPass">
			      <el-input type="password" v-model="form.firstPass" size="small" autocomplete="off"></el-input>
			    </el-form-item>
			    <el-form-item label="新密码"  prop="lastPass">
			      <el-input type="password" v-model="form.lastPass" size="small" autocomplete="off"></el-input>
			    </el-form-item>
			    <el-form-item label="确认密码"  prop="checkPass">
			      <el-input type="password" v-model="form.checkPass" size="small" autocomplete="off"></el-input>
			    </el-form-item>
			  </el-form> 
		  <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="closeEdit('form')">取&nbsp;消</el-button>
        <el-button size="small" type="primary"  @click="commitPass('form')">确&nbsp;认</el-button>
		  </span>
		</el-dialog>
    <!-- 折叠按钮 -->
    <el-tooltip effect="dark" :content="collapse ? `展开菜单` : `折叠菜单`" placement="bottom" >
      <div class="collapse-btn" @click="collapseChange">
        <i class="el-icon-menu"></i>
      </div>
    </el-tooltip>
    <div class="logo">
      <div>
      <!-- <img style="height:40px;margin-top:15px;" :src="`${publicPath}logo/title-logo.png`" /> -->
      </div>
      <span style="color:#2ccfff">{{ title }}</span>
    </div>
    <div class="header-right inlineblk">
          <div>
            <!-- <svg-icon :icon-class="isFullscreen?'exit-fullscreen':'fullscreen'" @click="click" /> -->
            <el-tooltip class="item" effect="dark" :content="isFullscreen?'退出全屏':'全屏'" placement="bottom">
              <i class="iconfont icon-icon_fullscreen_expand" style="font-size:30px;cursor: pointer;" v-if="!isFullscreen" @click="screenfullFn"></i>
              <i class="iconfont icon-exitfullscreen" style="font-size:30px;cursor: pointer;" v-if="isFullscreen" @click="screenfullFn"></i>
            </el-tooltip>
          </div>

          <div class="header-user-con">
              <!-- 用户头像 -->
              <div class="user-avator"><img src="../assets/images/img.jpg"></div>
              <!-- 用户名下拉菜单 -->
              <el-dropdown class="user-name" trigger="click" @command="handleCommand">
                  <span class="el-dropdown-link">
                      {{realname}} <i class="el-icon-caret-bottom"></i>
                  </span>
                  <el-dropdown-menu slot="dropdown">
                  	  <el-dropdown-item command="upPassword">密码修改</el-dropdown-item>
                      <el-dropdown-item command="loginout">退出登录</el-dropdown-item>
                      
                  </el-dropdown-menu>
              </el-dropdown>
          </div>
      </div>
  </div>
</template>
<script>
import { mapState, mapMutations , mapActions } from "vuex";
import {getName} from "@/utils/auth"; 
import bus from "utils/bus";
import BaseUrl from '@/utils/baseUrl'
import screenfull from 'screenfull'
import '../../public/config.js'
export default {
  data() {
  		var validatePass3 = (rule, value, callback) => {
        if (value === '') {
          callback(new Error('请输入原密码'));
        }else{
         	callback();
         }
      };
  	  var validatePass = (rule, value, callback) => {
        if (value === '') {
          callback(new Error('请输入新密码'));
        }else if (value.length < 6){
        	callback(new Error('请输入六位或以上的字符'));
        }else if(value === this.form.firstPass){
        	callback(new Error('新密码与旧密码不能相同'));
        } else{
          callback();
        }
      };
      var validatePass2 = (rule, value, callback) => {
        if (value === '') {
          callback(new Error('请再次输入密码'));
        } else if (value !== this.form.lastPass) {
          callback(new Error('两次输入密码不一致!'));
        } else {
          callback();
        }
      };
    return {
      isFullscreen:false,//是否全屏
    	editPassword: false,
      collapse: false,
      realname:getName("real_name"),
      title: title,
      form: {
          firstPass: '',
          lastPass: '',
          checkPass: ''
        },
      rules: {
      	firstPass: [
          { validator: validatePass3, trigger: 'blur',required: true }
        ],
        lastPass: [
          { validator: validatePass, trigger: 'blur',required: true }
        ],
        checkPass: [
          { validator: validatePass2, trigger: 'blur',required: true }
        ]
      }
    };
  },
//computed:{
//  ...mapState({
//    	realname : state=>state.real_name
//  })
//},
  mounted() {
    this.init()
  },
  beforeDestroy() {
    // this.destroy()
  },
  methods: {
    ...mapActions(['Login','LogOut','FedLogOut']),
    // 侧边栏折叠
    collapseChange() {
      this.collapse = !this.collapse;
      bus.$emit("collapse", this.collapse);
    },
    handleCommand(command){
      if(command == 'loginout'){
        this.LogOut();
        this.FedLogOut();
      }else if(command == 'upPassword'){
      	this.editPassword = true;
      }
    },
    //点击关闭修改密码弹框
    handleDialogClose() {
       this.$refs["form"].resetFields();
       this.editPassword = false;
    },

    commitPass(formName){
    	this.$refs[formName].validate((valid) => {
          if (valid) {
            this.$http.post(BaseUrl.updatePassword,this.form).then(res=>{
                    if(res.data.code == 0){
						this.LogOut();
        				this.FedLogOut();
                        this.$message({
                          message: res.data.msg,
                          type: 'success'
                    		})
                        this.editVisible = false;
                    }else{
                      this.$message({
                        message: res.data.msg,
                        type: 'error'
                    })
                };
                            
            })
          } else {
            return false;
          }
        });
    },
    closeEdit(formName){
    	this.$refs[formName].resetFields();
    	this.editPassword = false;
    },
    screenfullFn() {
      if (!screenfull.isEnabled) {
        this.$message({ message: '你的浏览器不支持全屏', type: 'warning' })
        return false
      }
      screenfull.toggle()
    },
    change() {
      this.isFullscreen = screenfull.isFullscreen
    },
    init() {
      if (screenfull.isEnabled) {
        screenfull.on('change', this.change)
      }
    }
  }
}  
</script>
<style scoped>
.header {
  position: relative;
  box-sizing: border-box;
  width: 100%;
  height: 70px;
  font-size: 22px;
  color: #fff;
  background-image: url(../assets/images/title.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  z-index: 9;
}
.logo {
  float: left;
  width: 700px;
  height: 70px;
  line-height: 70px;
  position: relative;
  /*padding-left: 10px;*/
}
.collapse-btn{
    float: left;
    padding: 0 21px;
    cursor: pointer;
    line-height: 70px;
}
.header .logo{
    float: left;
    width:700px;
    height: 70px;
    line-height: 70px;
    position: relative;
/*padding-left: 10px;*/
}
.logo div {
  height: 70px;
  line-height: 70px;
  display: inline-block;
  float: left;
  margin-left: 20px;
}
.logo img {
  height: 50px;
  margin-top: 10px;
}
.logo span {
  float: left;
  height: 70px;
  line-height: 70px;
  margin-left: 20px;
}
.header-right{
    float: right;
    padding-right: 50px;
}
.header-user-con{
    display: flex;
    height: 70px;
    align-items: center;
}
.btn-fullscreen{
    transform: rotate(45deg);
    margin-right: 5px;
    font-size: 24px;
}
.btn-bell, .btn-fullscreen{
    position: relative;
    width: 30px;
    height: 30px;
    text-align: center;
    border-radius: 15px;
    cursor: pointer;
}
.btn-bell-badge{
    position: absolute;
    right: 0;
    top: -2px;
    width: 8px;
    height: 8px;
    border-radius: 4px;
    background: #f56c6c;
    color: #fff;
}
.btn-bell .el-icon-bell{
    color: #fff;
}
.user-name{
    margin-left: 10px;
}
.user-avator{
    margin-left: 20px;
}
.user-avator img{
    display: block;
    width:40px;
    height:40px;
    border-radius: 50%;
}
.el-dropdown-link{
    color: #fff;
    cursor: pointer;
}
.el-dropdown-menu__item{
    text-align: center;
}
.inlineblk{
  display: flex;
  align-items: center;
  margin-right: 10px;
}
</style>
