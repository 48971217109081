<template>
  <div class="table">
    <div class="container">
      <el-form :inline="true" :model="searchForm" ref="searchForm">
        <el-form-item label="药房名称">
          <el-select v-model="searchForm.company_name" filterable size="small" clearable placeholder="请选择药房名称">
            <el-option v-for="item in companyOptions" :key="item.company_id" :label="item.company_name" :value="item.company_id"></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="药品类别">
          <el-select v-model="searchForm.drug_type" size="small" clearable placeholder="请选择药品类别">
            <el-option v-for="item in drugTypeOptions" :key="item.value" :label="item.label" :value="item.value"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="药品名称">
          <el-input v-model.trim="searchForm.drug_name" size="small" placeholder="请输入药品名称或简称"></el-input>
        </el-form-item>
        <el-form-item label="药品状态">
          <el-select v-model="searchForm.use_flag" size="small" clearable placeholder="请选择药品状态">
            <el-option v-for="item in drugStateOptions" :key="item.value" :label="item.label" :value="item.value"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" plain icon="el-icon-search" size="small" @click="getData()">查询</el-button>
          <el-button type="warning" plain icon="el-icon-refresh" size="small" @click="reset()">重置</el-button>
          <el-button type="success" plain size="small" icon="el-icon-plus" @click="showAddVisible('add')">新增
          </el-button>
          <el-button type="primary" plain icon="el-icon-download" size="small" @click="exportExcel()">导出</el-button>
        </el-form-item>
      </el-form>
      <el-table v-loading="loading" ref="table" highlight-current-row @current-change="handleCurrentChangeT" :data="tableData" border stripe size="mini" style="width: fit-content;">
        <el-table-column prop="drug_code" label="编码" width="200" show-overflow-tooltip></el-table-column>
        <el-table-column prop="company_name" label="药房名称" width="300" show-overflow-tooltip></el-table-column>
        <el-table-column prop="drug_name" label="药品名称" width="300" show-overflow-tooltip></el-table-column>
        <el-table-column prop="drug_jp" label="药品简称" width="150" show-overflow-tooltip></el-table-column>
        <el-table-column prop="drug_type" label="药品类别" width="150" show-overflow-tooltip></el-table-column>
        <el-table-column prop="spec" label="药品规格" width="100" show-overflow-tooltip></el-table-column>
        <el-table-column prop="unit" label="计量单位" width="130" show-overflow-tooltip></el-table-column>
        <el-table-column prop="price" label="售价" width="100" show-overflow-tooltip></el-table-column>
        <el-table-column prop="" label="操作" width="300">
          <template slot-scope="scope">
            <el-button size="mini" type="primary" icon="el-icon-edit" plain @click="showAddVisible('edit',scope.row)" :loading="scope.row.editLoading">编辑</el-button>
            <el-button size="mini" type="danger" plain icon="el-icon-delete" @click="deleteStation(scope.row)">删除</el-button>
            <el-button size="mini" type="warning" plain icon="el-icon-close" v-if="scope.row.use_flag == '0'" @click="prohibit(scope.row)">下架</el-button>
            <el-button size="mini" type="primary" plain icon="el-icon-check" v-if="scope.row.use_flag == '1'" @click="enable(scope.row)">上架</el-button>
          </template>
        </el-table-column>
      </el-table>
      <!--TODO 分页-->
      <div class="pagination table-width-b" v-if="totalPage!=0" :style="'width:'+footWidth+';max-width:100%;'">
        <el-pagination @size-change="handleSizeChange" :page-sizes="[10,20,50]" :page-size="searchForm.limit" :current-page.sync="searchForm.currentPage" @current-change="handleCurrentChange" layout="jumper, prev, pager, next, sizes, total" :total="total"></el-pagination>
      </div>
      <!--新增/修改弹框-->
      <el-dialog :close-on-click-modal="false" :title='titleName' v-if="addVisible" :visible.sync="addVisible" width="700px" top="20vh" :append-to-body="true" :before-close="noClick">
        <el-form :inline="true" ref="addForm" :model="addForm" label-width="100px" :rules="addFormRules">
          <el-form-item label="药品编码" prop="drug_code">
            <el-input v-model.trim="addForm.drug_code" size="small" placeholder="请输入药品编码"></el-input>
          </el-form-item>
          <el-form-item label="药品名称" prop="drug_name">
            <el-input v-model.trim="addForm.drug_name" size="small" placeholder="请输入药品名称"></el-input>
          </el-form-item>
          <el-form-item label="药品简称" prop="drug_jp">
            <el-input v-model.trim="addForm.drug_jp" size="small" placeholder="请输入药品简称"></el-input>
          </el-form-item>
          <el-form-item label="药品类别" prop="drug_type">
            <el-select v-model="addForm.drug_type" @change="changeDrug_type" :disabled="titleName==='修改药品'" size="small" placeholder="请选择药品类别" class="selectWdith">
              <el-option v-for="item in drugTypeOptions" :key="item.value" :label="item.label" :value="item.value"></el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="药品规格" prop="spec">
            <el-input v-model.trim="addForm.spec" size="small" placeholder="请输入药品规格"></el-input>
          </el-form-item>
          <el-form-item label="计量单位" prop="unit">
            <el-select v-model="addForm.unit" size="small" clearable placeholder="请选择计量单位" class="selectWdith">
              <el-option v-for="item in unitOptions" :key="item.value" :label="item.label" :value="item.value"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="售价(元)" prop="price">
            <el-input v-model.trim="addForm.price" maxlength="10" size="small" placeholder="请输入售价"></el-input>
          </el-form-item>
          <el-form-item label="成本价(元)" prop="cost_price">
            <el-input v-model.trim="addForm.cost_price" maxlength="10" size="small" placeholder="请输入成本价"></el-input>
          </el-form-item>
          <el-form-item prop="company_name" label="所属药房">
            <el-select v-model="addForm.company_name" :disabled="!addForm.drug_type||titleName==='修改药品'" placeholder="请选择药房" size="small" filterable class="selectWdith">
              <el-option v-for="item in companys" :key="item.company_id" :label="item.company_name" :value="item.company_name"></el-option>
            </el-select>

          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
					<el-button size="small" @click="noClick()">取&nbsp;消</el-button>
					<el-button type="primary" size="small" @click="saveEdit('addForm',type)">保&nbsp;存</el-button>
				</span>
      </el-dialog>
    </div>
  </div>
</template>
<script>
import BaseUrl from '@/utils/baseUrl';
import regulars from '@/utils/regulars.js';

export default {
  components: {},
  created() {
    this.getData()
    this.getList()
  },
  data() {
    //数字校验
    var checkValue = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('请输入售价'));
      } else {
        const reg = /^\d+$|^\d+[.]?\d+$/;
        if (reg.test(value)) {
          callback();
        } else {
          return callback(new Error('仅允许输入数字'));
        }
      }
    };
    //接种中心校验
    var centerVerify = (rule, value, callback) => {
      if (value == '' || value == null) {
        callback(new Error('请输入药房名称!'));
      } else if (this.addForm.company_id == '' || this.addForm.company_id == null) {
        callback(new Error('未找到厂家，请前往厂家管理中新增!'));
      } else {
        callback();
      }
    };
    var hospitalVerify = (rule, value, callback) => {
      if (value == '' || value == null) {
        callback();
      } else if (this.addForm.hospital_id == '' || this.addForm.hospital_id == null) {
        callback(new Error('未找到医院，请前往医院管理中新增!'));
      } else {
        callback();
      }
    };

    return {
      footWidth: '0px',
      loading: true, //列表loading
      tableData: [],
      searchForm: {
        company_name: '',
        drug_name: '',
        drug_type: '',
        limit: limit,
        currentPage: 1
      },
      companys: [ //生产厂家
        {
          company_id: '',
          company_name: ''
        }
      ],
      total: 0,
      totalPage: 0,
      titleName: '',
      addVisible: false,
      addForm: {},
      addFormRules: {
        drug_code: [{
          required: true,
          message: "请输入药品编码",
          trigger: "blur"
        }],
        drug_name: [{
          required: true,
          message: "请输入药品名称",
          trigger: "blur"
        }],
        drug_jp: [{
          required: true,
          message: "请输入药品简称",
          trigger: "blur"
        }],
        drug_type: [{
          required: true,
          message: "请输入药品类别",
          trigger: "blur"
        }],
        // spec: [{
        // 	required: true,
        // 	message: "请输入药品规格",
        // 	trigger: "blur"
        // }],
        unit: [{
          required: true,
          message: "请输入计量单位",
          trigger: "blur"
        }],
        price: [{
          required: true,
          trigger: 'blur',
          validator: checkValue
        }],
        company_name: [
          /*{ required: true,  trigger: 'blur', validator:centerVerify },*/
          {
            required: true,
            message: "请选择所属药房",
            trigger: "blur"
          }
        ],
        hospital_name: [{
          required: false,
          trigger: 'blur',
          validator: hospitalVerify
        },]
      },
      type: '', //add,edit
      row: null,
      deptOptions: [], //组织机构树
      deptInfo: {
        id: '',
        name: ''
      },
      unitOptions: [{
        label: '克',
        value: '克'
      },
        {
          label: '袋',
          value: '袋'
        }
      ],
      drugTypeOptions: [],
      companyOptions: [],
      hospitalOptions: [],
      drugStateOptions: [{
        label: '上架',
        value: '0'
      },
        {
          label: '下架',
          value: '1'
        }
      ]
    }
  },
  mounted() {
    //this.getTableProp();
    var that = this;
    document.getElementsByClassName('content')[0].onclick = function () {
      if (that.currentChange) {
        that.currentChange = false;
      } else {
        that.setCurrent();
      }
    }
    that.getCompanySelect();
    that.footWidth = that.$refs.table.bodyWidth;
  },

  methods: {

    getList() {
      this.$http.post(BaseUrl.getDrugTypePageList, {}).then(res => {
        if (res.data.code == 0) {
          this.drugTypeOptions = []
          for (let i = 0; i < res.data.data.length; i++) {
            var obj = {
              label: '',
              value: ''
            } //用于接收被改变的对象
            obj.label = res.data.data[i].type_name
            obj.value = res.data.data[i].type_name
            this.drugTypeOptions.push(obj)
          }
        } else {
          this.$message({
            message: res.data.msg,
            type: 'warning'
          })
        }
      })
    },
    getCompanySelect() {
      this.$http.post(BaseUrl.getCompanySelect).then(res => {
        if (res.data.code == 0) {
          this.companyOptions = res.data.data;
        } else {
          this.$message({
            message: res.data.msg,
            type: 'warning'
          })
        }
      });
    },
    //校验更改值
    jiaoyanHospitalChangeVal() {
      if (this.selectHasId) {
        this.addForm.hospital_id = '';
        this.selectHasId = false;
      }
    },
    prohibit(row) {
      this.$confirm('是否禁用此药品', '', {
        distinguishCancelAndClose: true,
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      }).then(() => {
        let obj = {
          id: row.id,
          use_flag: "1"
        };
        this.$http.post(BaseUrl.updDrugUseFlag, obj).then(res => {
          if (res.data.code == 0) {
            this.$message({
              message: res.data.msg,
              type: 'success'
            });
            this.getData();
          }
        })
      }).catch(action => {
      });
    },
    enable(row) {
      this.$confirm('是否启用此药品', '', {
        distinguishCancelAndClose: true,
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      }).then(() => {
        let obj = {
          id: row.id,
          use_flag: "0"
        };
        this.$http.post(BaseUrl.updDrugUseFlag, obj).then(res => {
          if (res.data.code == 0) {
            this.$message({
              message: res.data.msg,
              type: 'success'
            });
            this.getData();
          }
        })
      }).catch(action => {
      });
    },
    setCurrent(row) { //取消单选
      //this.$refs.table.setCurrentRow(row);
    },
    handleCurrentChangeT(row, index) {
      this.currentChange = true;
      this.$refs.table.setCurrentRow(row);
    },
    getData() {
      this.loading = true; //列表loading
      this.$http.post(BaseUrl.getDrugPageList, this.searchForm).then(res => {
        if (res.data.code == 0) {
          this.tableData = res.data.data.data;
          this.totalPage = res.data.data.totalPage;
          this.total = res.data.data.total;
          this.loading = false; //列表loading
        } else {
          this.$message({
            message: res.data.msg,
            type: 'warning'
          })
        }

      })
    },
    handleSizeChange(val) {
      this.searchForm.limit = val;
      this.getData();
    },
    handleCurrentChange(val) {
      this.searchForm.currentPage = val;
      this.getData();
    },
    reset() {
      this.resetSearchForm(this.searchForm, [{
        k: 'limit',
        v: this.searchForm.limit
      }, {
        k: 'currentPage',
        v: this.searchForm.currentPage
      }]);
      // this.getData();
    },
    showAddVisible(type, row) {
      this.addForm = {
        //unit:'克'
      };
      this.deptInfo = {
        id: '',
        name: '' //row.dept_name
      }
      this.type = type;

      if (type == 'add') {
        this.titleName = "新增药品";
        this.addVisible = true;
        /*this.listDept().then(res=>{
            this.deptOptions = this.handleTree(res.data.data,"dept_id");

        });*/
      } else {
        this.titleName = "修改药品";
        this.row = row;
        this.$set(row, 'editLoading', true);
        this.$http.post(BaseUrl.getDrugById, {
          id: row.id
        }).then(res => {
          if (res.data.code == 0) {
            this.deptInfo = {
              id: res.data.data.dept_id,
              name: row.dept_name //row.dept_name
            }
            this.addForm = res.data.data;
            this.addVisible = true;
            this.$set(row, 'editLoading', false);
            /*this.listDept().then(res=>{
                this.deptOptions = this.handleTree(res.data.data,"dept_id");

            });*/
          } else {
            this.$message({
              message: res.data.msg,
              type: 'warning'
            })
          }

        })

      }

    },
    //保存新增/修改
    saveEdit(form, type) {
      this.$refs[form].validate((valid) => {
        if (valid) {
          for (var i = 0; i < this.companys.length; i++) {
            if (this.companys[i].company_name == this[form].company_name) {
              this[form].company_id = this.companys[i].company_id
            }
          }
          console.log('this[form]: ', this[form])
          if (type == 'add') {
            this.$http.post(BaseUrl.addDrugInfo, this[form]).then(res => {
              if (res.data.code == 0) {
                this.titleName = '';
                this.getData();
                this.addVisible = false;
                this.$message({
                  message: res.data.msg,
                  type: 'success'
                })
              } else {
                this.$message({
                  message: res.data.msg,
                  type: 'warning'
                })
              }
            })
          } else {
            this[form].id = this.row.id;
            this.$http.post(BaseUrl.saveDrugById, this[form]).then(res => {
              if (res.data.code == 0) {
                this.titleName = '';
                this.getData();
                this.addVisible = false;
                this.$message({
                  message: res.data.msg,
                  type: 'success'
                })
              } else {
                this.$message({
                  message: res.data.msg,
                  type: 'warning'
                })
              }
            })
          }


        }
      })

    },
    deleteStation(row) {
      var that = this;
      this.$confirm('是否确认删除药品"' + row.drug_name + '"的数据项?', "警告", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(function () {
        that.$http.post(BaseUrl.delDrug, {
          id: row.id
        }).then(res => {
          if (res.data.code == 0) {
            that.getData();
            that.$message({
              message: '删除成功',
              type: 'success'
            })
          } else {
            that.$message({
              message: res.data.msg,
              type: 'warning'
            })
          }

        })

      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    },
    noClick() {
      this.getData();
      this.addVisible = false;
    },
    changeDrug_type(value) {
      console.log('更改了类型:' + value)
      console.log('this.addForm.company_name: ', this.addForm.company_name)

      //获取所属药房下拉列表
      this.$http.post(BaseUrl.getCompanySelect, { type: value }).then(res => {
        if (res.data.code == 0) {
          this.companys = []
          this.$set(this.addForm, 'company_name', null)
          this.companys = res.data.data;

        }
      });

    },
    exportExcel() {
      let obj = this.searchForm;
      this.$http.postFile(BaseUrl.drugExport, obj, {
        responseType: "arraybuffer",
      }).then((res) => {
        var blob = new Blob([res.data], {
          type: "application/vnd.ms-excel;charset=utf-8",
        });
        var downloadElement = document.createElement("a");
        var objectUrl = URL.createObjectURL(blob);
        downloadElement.href = objectUrl;
        downloadElement.download = "药品信息.xls"; //下载后文件名
        document.body.appendChild(downloadElement);
        downloadElement.click(); //点击下载
        document.body.removeChild(downloadElement); //下载完成移除元素
        window.URL.revokeObjectURL(objectUrl); //释放掉blob对象
      }).catch(function (res) {
      });
    }


  }

}
</script>
<style scoped>
.selectWdith {
  width: 200px;
}
</style>
