<template>
  <div class="container">
    <div class="serachBox">
      <el-form :inline="true" :model="searchForm" ref="searchForm">
        <el-form-item label="调剂中心名称">
          <el-input v-model="searchForm.tjzx_name" placeholder="请输入调剂中心名称" size="mini" style="width: 200px; margin-right: 10px;"/>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" size="mini" @click="handleSearch">查询</el-button>
          <el-button type="success" size="mini" @click="handleAdd">新增</el-button>
        </el-form-item>
      </el-form>

    </div>
    <el-table ref="table" :data="tableData" border style="width: 1441px" size="mini" :loading="tableLoading">
      <el-table-column align="center" type="index" label="序号" width="50"></el-table-column>
      <el-table-column align="center" prop="tjzx_name" label="调剂中心名称" width="220" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column align="center" prop="tjzx_addr" label="调剂中心地址" width="300" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column align="center" prop="account" label="登录账号" width="120" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column align="center" prop="username" label="登录名称" width="220" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column align="center" prop="create_user" label="创建人" width="100" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column align="center" prop="create_time" label="创建时间" width="180" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column align="center" label="操作" width="250">
        <template slot-scope="scope">
          <el-button size="mini" @click="handleEdit(scope.row)">编辑</el-button>
          <el-button size="mini" type="danger" @click="handleDelete(scope.row)">删除</el-button>
          <el-button size="mini" type="success" :loading="resetLoading" @click="handleResetPassword(scope.row)">重置密码</el-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="pagination table-width-b" v-if="total!==0" :style="'width:'+footWidth+';max-width:100%;'">
      <el-pagination background layout="jumper, prev, pager, next, sizes, total" :total="total" :page-size="limit" :current-page.sync="searchForm.currentPage" @current-change="handlePageChange" size="mini"/>
    </div>

    <el-dialog :visible.sync="dialogVisible" :title="title" width="800px">
      <el-form :model="form" :rules="rules" ref="form" label-width="200px">
        <el-form-item label="调剂中心名称" prop="tjzx_name">
          <el-input size="mini" style="width: 350px" v-model="form.tjzx_name"></el-input>
        </el-form-item>
        <el-form-item label="调剂中心地址" prop="tjzx_addr">
          <el-input size="mini" style="width: 350px" v-model="form.tjzx_addr"></el-input>
        </el-form-item>
        <el-form-item label="登录账号" prop="account">
          <el-input size="mini" style="width: 350px" v-model="form.account"></el-input>
        </el-form-item>
        <el-form-item label="登录名称" prop="username">
          <el-input size="mini" style="width: 350px" v-model="form.username"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button size="mini" @click="dialogVisible = false">取消</el-button>
        <el-button size="mini" type="primary" @click="handleSave">保存</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import BaseUrl from '@/utils/baseUrl';

export default {
  data() {
    return {
      searchForm: {
        tjzx_name: '',
        currentPage: 1, // 确保初始化
      },
      limit: 10,
      total: 0,
      tableLoading: false,
      resetLoading: false,
      dialogVisible: false,
      form: {
        tjzx_name: '',
        tjzx_addr: '',
        account: '',
        username: '',
      },
      rules: {
        tjzx_name: [{ required: true, message: '请输入调剂中心名称', trigger: 'blur' }],
        tjzx_addr: [{ required: true, message: '请输入调剂中心地址', trigger: 'blur' }],
        account: [{ required: true, message: '请输入登录账号', trigger: 'blur' }],
        username: [{ required: true, message: '请输入登录名称', trigger: 'blur' }],
      },
      tableData: [],
      footWidth: '100%',
      title: '',
    };
  },
  mounted() {
    this.footWidth = this.$refs.table.bodyWidth;
    this.getList();
  },
  methods: {
    getList() {
      this.tableLoading = true;
      this.$http.post(BaseUrl.getTjzxPageList, {
        tjzx_name: this.searchForm.tjzx_name,
        currentPage: this.searchForm.currentPage,
        limit: this.limit,
      }).then(res => {
        this.tableData = res.data.data.data;
        this.total = res.data.data.total;
        this.tableLoading = false;
      });
    },
    handleSearch() {
      this.searchForm.currentPage = 1;
      this.getList();
    },
    handleAdd() {
      this.title = '新增调剂中心';
      this.dialogVisible = true;
      this.form = { tjzx_name: '', tjzx_addr: '', account: '', username: '' };
    },
    handleEdit(row) {
      this.title = '编辑调剂中心';
      this.dialogVisible = true;
      this.$http.post(BaseUrl.getTjzxById, { id: row.id }).then(res => {
        this.form = res.data.data;
      });
    },
    handleDelete(row) {
      this.$confirm('是否确认删除该条目?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        this.tableLoading = true;
        this.$http.post(BaseUrl.delTjzxById, { id: row.id }).then((res) => {
          if (res.data.code === 0) {
            this.getList();
            this.$message({
              type: 'success',
              message: '删除成功',
            });
          } else {
            this.$message({
              type: 'error',
              message: res.data.msg,
            });
          }
        }).catch((error) => {
          this.$message({
            type: 'error',
            message: error,
          });
        });
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除',
        });
      });
    },
    handleResetPassword(row) {
      this.$confirm('是否要重置该账号密码?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        this.resetLoading = true;
        this.$http.post(BaseUrl.resetTjzxPassword, { id: row.id }).then((res) => {
          this.resetLoading = false;
          if (res.data.code !== 0) {
            this.$message({
              type: 'error',
              message: res.data.msg,
            });
            return;
          }
          this.$message({
            type: 'success',
            message: res.data.msg,
          });
        });
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消重置',
        });
      });
    },
    handleSave() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          const url = this.form.id ? BaseUrl.updTjzxById : BaseUrl.addTjzx;
          this.$http.post(url, this.form).then((res) => {
            if (res.data.code === 0) {
              this.getList();
              this.dialogVisible = false;
              this.$message({
                type: 'success',
                message: this.form.id ? '更新成功' : '新增成功',
              });
            } else {
              this.$message({
                type: 'error',
                message: res.data.msg,
              });
            }
          }).catch(error => {
            this.$message({
              type: 'error',
              message: error,
            });
          });
        } else {
          this.$message({
            type: 'error',
            message: '请填写完整信息',
          });
          return false;
        }
      });
    },
    handlePageChange(page) {
      this.searchForm.currentPage = page;
      this.getList();
    },
  },
};
</script>

<style scoped>
.serachBox {
  margin-bottom: 10px;
}
</style>