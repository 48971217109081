<template>
	<div class="table">
		<div class="container">
			<el-form :inline="true" :model="searchForm" ref="searchForm">
				<el-form-item label="患者信息">
					<el-input v-model.trim="searchForm.see_name" size="small" placeholder="请输入患者姓名或编号"></el-input>
				</el-form-item>
				<el-form-item label="应诊时间">
					<dateChoose v-model="searchForm.seeTime" :time="searchForm.seeTime" width="220px" dateType="0">
					</dateChoose>
					<!-- <el-date-picker 
                style="width:150px;"
                v-model="searchForm.seeStartTime" 
                type="date" size="small" 
                placeholder="应诊开始时间" 
                format="yyyy-MM-dd"
                value-format="yyyy-MM-dd"
                :picker-options="pickerOptionsSeeStartSearch"
                >
            </el-date-picker>
            <span>至</span>
            <el-date-picker 
                style="width:150px;"
                v-model="searchForm.seeEndTime" 
                type="date" size="small" 
                placeholder="应诊结束时间" 
                format="yyyy-MM-dd"
                value-format="yyyy-MM-dd"
                :picker-options="pickerOptionsSeeEndSearch"
                >
            </el-date-picker> -->
				</el-form-item>
				<el-form-item label="开方时间">
					<dateChoose v-model="searchForm.openTime" :time="searchForm.openTime" width="220px" dateType="0">
					</dateChoose>
					<!-- <el-date-picker 
                style="width:150px;"
                v-model="searchForm.openStartTime" 
                type="date" size="small" 
                placeholder="开方开始时间" 
                format="yyyy-MM-dd"
                value-format="yyyy-MM-dd"
                :picker-options="pickerOptionsOpenStartSearch"
                >
            </el-date-picker>
            <span>至</span>
            <el-date-picker 
                style="width:150px;"
                v-model="searchForm.openEndTime" 
                type="date" size="small" 
                placeholder="开方结束时间" 
                format="yyyy-MM-dd"
                value-format="yyyy-MM-dd"
                :picker-options="pickerOptionsOpenEndSearch"
                >
            </el-date-picker> -->
				</el-form-item>
				<el-form-item>
					<el-button type="primary" plain icon="el-icon-search" size="small" @click="getData()">查询</el-button>
					<el-button type="warning" plain icon="el-icon-refresh" size="small" @click="reset()">重置</el-button>
				</el-form-item>
			</el-form>
			<el-table v-loading="loading" ref="table" highlight-current-row @selection-change="handleSelectionChange"
				:data="tableData" border stripe size="mini" style="width: fit-content;">
				<!--<el-table-column type="selection" width="55"></el-table-column>-->
				<el-table-column prop="seePatTime" label="应诊时间" width="150"></el-table-column>
				<el-table-column prop="id" label="应诊编号" width="150"></el-table-column>
				<el-table-column prop="see_name" label="患者姓名" width="100"></el-table-column>
				<el-table-column prop="see_sex" label="性别" width="100"></el-table-column>
				<el-table-column prop="see_age" label="年龄" width="100"></el-table-column>
				<el-table-column prop="deptId" label="科室" width="130"></el-table-column>
				<el-table-column prop="doctorName" label="应诊医生" width="130"></el-table-column>
				<el-table-column prop="result" label="中医症型" width="550" show-overflow-tooltip></el-table-column>
				<el-table-column prop="" label="操作" width="100">
					<template slot-scope="scope">
						<el-button size="mini" type="warning" icon="el-icon-view" plain
							@click="showViewVisible(scope.row.id)">查看</el-button>
					</template>
				</el-table-column>
			</el-table>
			<!--TODO 分页-->
			<div class="pagination table-width-b" v-if="totalPage!=0" :style="'width:'+footWidth+';max-width:100%;'">
				<el-pagination @size-change="handleSizeChange" :page-sizes="[10,20,50]" :page-size="searchForm.limit"
					:current-page.sync="searchForm.currentPage" @current-change="handleCurrentChange"
					layout="jumper, prev, pager, next, sizes, total" :total="total">
				</el-pagination>
			</div>
			<see-info v-if="viewVisible" ref="detailInfo" :titleName="titleName" :seeInfo="seeInfo"
				:openRecipes="openRecipes" :showOrder="showOrder" @refreshTable="getData"></see-info>
		</div>
	</div>
</template>

<script>
	import BaseUrl from '@/utils/baseUrl';
	import seeInfo from "@/components/see-info";
	import dateChoose from "@/components/date-choose"
	import moment from 'moment'
	export default {
		components: {
			seeInfo,
			dateChoose
		},
		data() {
			return {
				footWidth: '0px',
				searchForm: {
					see_name: '', //患者姓名
					seeStartTime: '', //应诊开始时间
					seeEndTime: '', //应诊结束时间
					openStartTime: '', //开方开始时间
					openEndTime: '', //开方结束时间
					limit: limit,
					currentPage: 1,
					seeTime: [],
					openTime: []
				},
				// pickerOptionsSeeStartSearch: {
				// 	disabledDate: time => {
				// 		let endDateVal = this.searchForm.seeEndTime;
				// 		if (endDateVal) {
				// 			return time.getTime() > new Date(endDateVal).getTime();
				// 		}
				// 	}
				// },
				// pickerOptionsSeeEndSearch: {
				// 	disabledDate: time => {
				// 		let beginDateVal = this.searchForm.seeStartTime;
				// 		if (beginDateVal) {
				// 			return (time.getTime() < new Date(beginDateVal).getTime() - 1 * 24 * 60 * 60 * 1000);
				// 		}
				// 	}
				// },
				// pickerOptionsOpenStartSearch: {
				// 	disabledDate: time => {
				// 		let endDateVal = this.searchForm.openEndTime;
				// 		if (endDateVal) {
				// 			return time.getTime() > new Date(endDateVal).getTime();
				// 		}
				// 	}
				// },
				// pickerOptionsOpenEndSearch: {
				// 	disabledDate: time => {
				// 		let beginDateVal = this.searchForm.openStartTime;
				// 		if (beginDateVal) {
				// 			return (time.getTime() < new Date(beginDateVal).getTime() - 1 * 24 * 60 * 60 * 1000);
				// 		}
				// 	}
				// },
				loading: false,
				tableData: [],
				total: 0,
				totalPage: 0,
				multipleSelection: [],
				viewVisible: false,
				titleName: "应诊明细",
				seeInfo: {},
				openRecipes: [],
				showOrder: false,
			}
		},
		watch: {
			'searchForm.seeTime': {
				handler(newName, oldName) {
					this.searchForm.seeStartTime = newName[0];
					this.searchForm.seeEndTime = newName[1];
					//执行代码
				},
				deep: true
			},
			'searchForm.openTime': {
				handler(newName, oldName) {
					this.searchForm.openStartTime = newName[0];
					this.searchForm.openEndTime = newName[1];
					//执行代码
				},
				deep: true
			}
		},
		created() {},
		mounted() {
			this.searchForm.seeStartTime = this.searchForm.seeTime[0];
			this.searchForm.seeEndTime = this.searchForm.seeTime[1];
			this.searchForm.openStartTime = this.searchForm.openTime[0];
			this.searchForm.openEndTime = this.searchForm.openTime[1];
			var that = this;
			that.getData();
			that.footWidth = that.$refs.table.bodyWidth;
		},
		methods: {
			getData() {
				this.loading = true;
				this.$http.post(BaseUrl.getDoctorSeePageLimit, this.searchForm).then(res => {
					if (res.data.code == 0) {
						this.tableData = res.data.data.data;
						this.totalPage = res.data.data.totalPage;
						this.total = res.data.data.total;
						this.loading = false; //列表loading
					} else {
						this.$message({
							message: res.data.msg,
							type: 'warning'
						})
					}
				})
			},
			reset() {
				this.resetSearchForm(this.searchForm, [{
						k: 'limit',
						v: this.searchForm.limit
					}, {
						k: 'currentPage',
						v: this.searchForm.currentPage
					}, {
						k: 'seeStartTime',
						v: ''
					},
					{
						k: 'seeEndTime',
						v: ''
					},
					{
						k: 'openStartTime',
						v: ''
					},
					{
						k: 'openEndTime',
						v: ''
					},
					{
						k: 'seeTime',
						v: []
					}, {
						k: 'openTime',
						v: []
					}
				]);
			},
			handleSelectionChange(val) {
				this.multipleSelection = val;
			},
			handleSizeChange(val) {
				this.searchForm.limit = val;
				this.getData();
			},
			handleCurrentChange(val) {
				this.searchForm.currentPage = val;
				this.getData();
			},
			showViewVisible(id) {
				var _this = this;
				_this.$http.post(BaseUrl.getSeeRecordBySeeId, {
					seeId: id
				}).then(res => {
					if (res.data.code == 0) {
						_this.seeInfo = res.data.data;
						_this.openRecipes = res.data.data.openRecipes;
						_this.viewVisible = true;
						_this.$nextTick(() => {
							_this.$refs.detailInfo.init();
						});
					} else {
						this.$message({
							message: res.data.msg,
							type: 'warning'
						})
					}
				})

			},
		}
	}
</script>

<style scoped>
</style>