<template>
  <div class="table">
    <div class="container">
      <el-form :inline="true" :model="searchForm" ref="searchForm">
        <el-row>
          <el-form-item label="医生名称">
            <el-input
              v-model.trim="searchForm.doctor_name"
              size="small"
              placeholder="请输入医生名称"
            ></el-input>
          </el-form-item>
          <el-form-item label="手机号" prop="phone">
            <el-input
              v-model.trim="searchForm.phone"
              size="small"
              placeholder="请输入手机号"
              style="width: 160px;"
            ></el-input>
          </el-form-item>
          <!-- <el-form-item prop="deptId" label="所属科室">
                        <el-select v-model="searchForm.dept_name" size="small" filterable clearable placeholder="请选择" style= "width: 220px;">
                            <el-option
                            v-for="(item,index) in deptSeOptions"
                            :key="index"
                            :label="item.dept_name"
                            :value="item.dept_name">
                            </el-option>
                        </el-select>
			        </el-form-item> -->
          <el-form-item>
            <el-button
              type="primary"
              plain
              icon="el-icon-search"
              size="small"
              @click="getData()"
              >查询</el-button
            >
            <el-button
              type="warning"
              plain
              icon="el-icon-refresh"
              size="small"
              @click="reset()"
              >重置</el-button
            >
            <!-- <el-button type="success" plain size="small" icon="el-icon-plus" @click="showAddVisible('add')" >新增</el-button> -->
          </el-form-item>
        </el-row>
        <el-row>
          <!-- <el-form-item label="职称评级" prop="level">
                        <el-select v-model.trim="searchForm.level" placeholder="请选择职称评级" size="small" style= "width: 200px;">
                            <el-option
                                v-for="(item,index) in levels"
                                :key="index"
                                :label="item.name"
                                :value="item.id">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item  label="审核状态">
                        <el-select v-model="searchForm.is_audit" size="small" clearable placeholder="请选择审核状态" style= "width: 200px;">
                                <el-option
                                v-for="item in drugTypeOptions"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value">
                                </el-option>
                    </el-select>
                    </el-form-item>
                    <el-form-item prop="order_state" label="注册时间">
                        <dateChoose v-model="searchForm.time" :time="searchForm.time" width="220px" dateType="11"></dateChoose>
                    </el-form-item> -->
          <!-- <el-form-item>
                        <el-button type="primary" plain icon="el-icon-search" size="small" @click="getData()">查询</el-button>
                        <el-button type="warning" plain icon="el-icon-refresh" size="small" @click="reset()">重置</el-button>
                        <el-button type="success" plain size="small" icon="el-icon-plus" @click="showAddVisible('add')" >新增</el-button>
                    </el-form-item> -->
        </el-row>
      </el-form>
      <el-table
        v-loading="loading"
        ref="table"
        :data="tableData"
        border
        stripe
        size="mini"
        style="width: fit-content;"
      >
        <el-table-column
          prop="doctor_id"
          label="医生ID"
          width="80"
          show-overflow-tooltip
        ></el-table-column>

        <el-table-column
          prop="doctor_name"
          label="医生姓名"
          width="200"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          prop="phone"
          label="手机号"
          width="140"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          prop="sex"
          label="性别"
          width="100"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          prop=""
          label="所属地区"
          width="200"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <span>{{
              scope.row.province
                ? scope.row.province + scope.row.city + scope.row.county
                : ""
            }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="rate"
          label="返现比例"
          width="100"
          show-overflow-tooltip
        ></el-table-column>

        <el-table-column prop="" label="操作" width="100" fixed="right">
          <template slot-scope="scope">
            <el-button
              size="mini"
              type="primary"
              icon="el-icon-edit"
              plain
              @click="showSetVisible(scope.row)"
              >设置</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <!--TODO 分页-->
      <div
        class="pagination table-width-b"
        v-if="totalPage != 0"
        :style="'width:' + footWidth + ';max-width:100%;'"
      >
        <el-pagination
          @size-change="handleSizeChange"
          :page-sizes="[10, 20, 50]"
          :page-size="searchForm.limit"
          :current-page.sync="searchForm.currentPage"
          @current-change="handleCurrentChange"
          layout="jumper, prev, pager, next, sizes, total"
          :total="total"
        >
        </el-pagination>
      </div>
      <!--设置弹框-->
      <el-dialog
        :close-on-click-modal="false"
        :title="titleName"
        v-if="setVisible"
        :visible.sync="setVisible"
        width="600px"
        top="20vh"
        :append-to-body="true"
        :before-close="noClick"
      >
        <el-form
          :inline="true"
          ref="setForm"
          :model="setForm"
          label-width="120px"
          :rules="setFormRules"
        >
          <el-form-item prop="ratio" label="医生提现设置">
            <el-input
              v-model.trim="setForm.ratio"
              size="small"
              placeholder="请输入提现设置"
              style="width: 300px;"
            ></el-input>
            <span style="margin-left:10px"
              ><el-button type="primary" size="small" @click="defaultRatio()"
                >设置标准比例</el-button
              >
            </span>
            <div style="color:red">
              填写说明：0表示不参与提成，0.5表示50%，0.3表示30%
            </div>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button size="small" @click="setVisible = false"
            >取&nbsp;消</el-button
          >
          <el-button
            type="primary"
            size="small"
            @click="saveDoctorRatio('setForm', type)"
            >保&nbsp;存</el-button
          >
        </span>
      </el-dialog>
    </div>
  </div>
</template>
<script>
import BaseUrl from "@/utils/baseUrl";
import regulars from "@/utils/regulars.js";
import options from "@/utils/country-data.js";
import dateChoose from "@/components/date-choose";
import moment from "moment";

export default {
  components: {
    dateChoose
  },
  created() {},
  watch: {
    "searchForm.time": {
      handler(newName, oldName) {
        this.searchForm.start_time = newName[0];
        this.searchForm.end_time = newName[1];
        //执行代码
      },
      deep: true
    }
  },
  data() {
    ////校验手机号，号段主要有(不包括上网卡)：130~139、150~153，155~159，180~189、170~171、    176~178。14号段为上网卡专属号段
    const telReg = /^((0\d{2,3}-\d{7,8})|(((13[0-9])|(14[0-9])|(15[0-9])|(16[0-9])|(17[0-9])|(18[0-9])|(19[0-9]))\d{8}))|400[0-9]{7}$/;
    var telValue = regulars.checkRegular(
      telReg,
      "请输入手机号码",
      "手机号码输入不合法!"
    );
    const personIDReg = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/;
    var personIDValue = regulars.checkRegular2(
      personIDReg,
      "身份证号码输入不合法"
    );
    //校验手机号重复性
    var checkRepeat = (rule, value, callback) => {
      if (value == "" || value == null) {
        callback(new Error("请输入手机号码"));
      } else {
        this.$http
          .post(BaseUrl.checkDoctorPhone, {
            id: this.addForm.id,
            phone: this.addForm.phone
          })
          .then(res => {
            if (res.data.code == 0) {
              if (res.data.data == 0) {
                callback();
              } else {
                callback(new Error("当前手机号码重复"));
              }
            } else {
              callback(new Error("手机号码校验失败"));
            }
          });
      }
    };
    var numReg = /^0(\.\d+)?$/; // 修改正则表达式以允许0和0.x

    let checkRatio = (rule, value, callback) => {
      if (value > 1 || value == 1) {
        callback(new Error("提现设置应小于1"));
      } else if (value < 0) {
        callback(new Error("提现设置不能小于0"));
      } else if (!numReg.test(value)) {
        callback(new Error("提现设置不合法"));
      } else {
        callback();
      }
    };
    return {
      fileList1: [],
      fileList2: [],
      fileList3: [],
      showBigImg: false,
      showBigImgUrl: "",
      footWidth: "0px",
      loading: true, //列表loading
      tableData: [],
      searchForm: {
        hospital_name: "",
        doctorName: "",
        limit: limit,
        currentPage: 1,
        start_time: "",
        end_time: "",
        time: []
      },
      sexs: [
        { name: "男", id: "男" },
        { name: "女", id: "女" },
        { name: "保密", id: "保密" }
      ],
      levels: [
        { name: "主任医师", id: "主任医师" },
        { name: "副主任医师", id: "副主任医师" },
        { name: "主治医师", id: "主治医师" },
        { name: "医师", id: "医师" }
      ],
      drugTypeOptions: [
        { label: "未审核", value: "0" },
        { label: "审核未通过", value: "1" },
        { label: "审核通过", value: "2" }
      ],
      total: 0,
      totalPage: 0,
      titleName: "",
      addVisible: false,
      isAuditVisible: false,
      auditForm: {
        is_audit: "",
        audit_script: "",
        audit_spec: ""
      },

      type: "", //add,edit
      row: null,
      deptOptions: [], //组织机构树
      deptSeOptions: [],
      aduitOptions: [],
      restaurants: [],
      deptInfo: {
        id: "",
        name: ""
      },
      referrerOptions: [],
      hospitalOptions: [],
      options: options,

      setForm: {
        ratio: ""
      },
      setFormRules: {
        ratio: [
          { required: true, message: "请输入提现设置", trigger: "blur" },
          { required: true, trigger: "blur", validator: checkRatio }
        ]
      },
      setVisible: false //医生设置
    };
  },
  mounted() {
    this.getSeDeptOptions();
    var that = this;
    this.searchForm.start_time = this.searchForm.time[0];
    this.searchForm.end_time = this.searchForm.time[1];
    that.footWidth = that.$refs.table.bodyWidth;
    this.getData();
  },
  methods: {
    getSeDeptOptions() {
      this.$http.post(BaseUrl.getDept).then(res => {
        if (res.data.code == 0) {
          this.deptSeOptions = res.data.data;
        } else {
          this.$message({
            message: res.data.msg,
            type: "warning"
          });
        }
      });
    },
    getData() {
      this.loading = true; //列表loading
      this.$http
        .post(BaseUrl.getDoctorRatioPageLimit, this.searchForm)
        .then(res => {
          if (res.data.code == 0) {
            this.tableData = res.data.data.data;
            this.totalPage = res.data.data.totalPage;
            this.total = res.data.data.total;
            this.loading = false; //列表loading
          } else {
            this.$message({
              message: res.data.msg,
              type: "warning"
            });
          }
        });
    },
    handleSizeChange(val) {
      this.searchForm.limit = val;
      this.getData();
    },
    handleCurrentChange(val) {
      this.searchForm.currentPage = val;
      this.getData();
    },
    reset() {
      this.resetSearchForm(this.searchForm, [
        { k: "limit", v: this.searchForm.limit },
        { k: "currentPage", v: this.searchForm.currentPage },
        {
          k: "start_time",
          v: moment()
            .subtract(7, "days")
            .format("YYYY-MM-DD")
        },
        { k: "end_time", v: moment().format("YYYY-MM-DD") },
        {
          k: "time",
          v: [
            moment()
              .subtract(7, "days")
              .format("YYYY-MM-DD"),
            moment().format("YYYY-MM-DD")
          ]
        }
      ]);
      // this.getData();
    },
    noClick() {
      this.getData();
      this.setVisible = false;
    },
    //显示设置弹框
    showSetVisible(row) {
      this.titleName = "提现设置";
      this.setForm.ratio = row.rate ? row.rate : "";
      this.setForm.doctor_id = row.doctor_id;
      this.setVisible = true;
    },
    //保存医生返利
    saveDoctorRatio(form) {
      this.$refs[form].validate(valid => {
        if (valid) {
          this.$http.post(BaseUrl.updDoctorRatio, this[form]).then(res => {
            if (res.data.code == 0) {
              this.titleName = "";
              this.getData();
              this.setVisible = false;
              this.$message({
                message: res.data.msg,
                type: "success"
              });
            } else {
              this.$message({
                message: res.data.msg,
                type: "warning"
              });
            }
          });
        }
      });
    },
    //初始化医生返利
    defaultRatio() {
      this.$http
        .post(BaseUrl.updDoctorRatio, {
          doctor_id: this.setForm.doctor_id,
          ratio: ""
        })
        .then(res => {
          if (res.data.code == 0) {
            this.titleName = "";
            this.getData();
            this.setVisible = false;
            this.$message({
              message: res.data.msg,
              type: "success"
            });
          } else {
            this.$message({
              message: res.data.msg,
              type: "warning"
            });
          }
        });
    }
  }
};
</script>
<style scoped>
.selectWdith {
  width: 200px;
}
</style>
