<template>
	<div class="table">
		<div class="container"  >
			<el-form :inline="true" :model="searchForm" ref="searchForm">
				<!--<el-form-item  label="医院名称">
                    <el-input v-model.trim="searchForm.hospital_name" size="small" placeholder="请输入医院名称"></el-input>
                </el-form-item>-->
				<el-form-item label="医生名称">
					<el-input v-model.trim="searchForm.doctor_name" size="small" placeholder="请输入医生名称"></el-input>
				</el-form-item>
				<!--<el-form-item  label="病症分类">
                    <el-input v-model.trim="searchForm.disease_type" size="small" placeholder="请输入病症分类"></el-input>
                </el-form-item>-->
				<el-form-item>
					<el-button type="primary" plain icon="el-icon-search" size="small" @click="getData()">查询</el-button>
					<el-button type="warning" plain icon="el-icon-refresh" size="small" @click="reset()">重置</el-button>
					<el-button type="success" plain size="small" icon="el-icon-plus"
						@click="showAddVisible('add')">添加中医症型</el-button>
				</el-form-item>
			</el-form>
			<el-table v-loading="loading" ref="table" highlight-current-row @current-change="handleCurrentChangeT"
				  :data="tableData" border stripe size="mini" style="width: fit-content;">
				<el-table-column prop="diag_result" label="中医症型" width="200" show-overflow-tooltip></el-table-column>
				<el-table-column prop="diag_des" label="症状描述" width="600" show-overflow-tooltip></el-table-column>
				<el-table-column prop="disease_type" label="类型" width="100" show-overflow-tooltip>
					<template slot-scope="scope">
						{{scope.row.disease_type==0 ?'系统模板':'我的模板'}}
					</template>
				</el-table-column>
				<el-table-column prop="doctor_name" label="创建人" width="120" show-overflow-tooltip>
					<!-- <template slot-scope="scope">
						{{scope.row.doctor_name==null ?'admin':scope.row.doctor_name}}
					</template> -->
				</el-table-column>
				<el-table-column prop="create_time" label="创建时间" width="150" show-overflow-tooltip></el-table-column>
				<!--<el-table-column prop="recipe_name" label="匹配处方" width="500" show-overflow-tooltip></el-table-column>-->
				<el-table-column prop="" label="操作" width="200">
					<template slot-scope="scope">
						<el-button size="mini" type="primary" icon="el-icon-edit" plain
							@click="showAddVisible('edit',scope.row)" :loading="scope.row.editLoading">编辑</el-button>
						<el-button size="mini" type="danger" plain icon="el-icon-delete"
							@click="deleteStation(scope.row)">删除</el-button>
					</template>
				</el-table-column>
			</el-table>
			<!--TODO 分页-->
			<div class="pagination table-width-b" v-if="totalPage!=0" :style="'width:'+footWidth+';max-width:100%;'">
				<el-pagination @size-change="handleSizeChange" :page-sizes="[10,20,50]" :page-size="searchForm.limit"
					:current-page.sync="searchForm.currentPage" @current-change="handleCurrentChange"
					layout="jumper, prev, pager, next, sizes, total" :total="total">
				</el-pagination>
			</div>
			<!--修改弹框-->
			<el-dialog :close-on-click-modal="false" :title='titleName' v-if="addVisible" :visible.sync="addVisible"
				width="600px" top="20vh" :append-to-body="true" :before-close="noClick">
				<el-form ref="addForm" :model="addForm" label-width="100px" :rules="addFormRules">
					<el-form-item label="中医症型" prop="diag_result">
						<el-input v-model.trim="addForm.diag_result" size="small" placeholder="请输入中医症型"></el-input>
					</el-form-item>
					<el-form-item label="症状描述" prop="diag_des">
						<el-input v-model.trim="addForm.diag_des" size="small" placeholder="请输入症状描述"></el-input>
					</el-form-item>
					<!--<el-form-item  label="匹配处方">
                        <el-select v-model="addForm.recipeIds" multiple placeholder="请选择" class="selectWdith" size="small">
                            <el-option
                            v-for="item in recipeOptions"
                            :key="item.recipeId"
                            :label="item.recipeName"
                            :value="item.recipeId">
                            </el-option>
                        </el-select>
                    </el-form-item>-->
				</el-form>
				<span slot="footer" class="dialog-footer">
					<el-button size="small" @click="noClick()">取&nbsp;消</el-button>
					<el-button type="primary" size="small" @click="saveEdit('addForm',type)">保&nbsp;存</el-button>
				</span>
			</el-dialog>
		</div>
	</div>
</template>
<script>
	import BaseUrl from '@/utils/baseUrl';
	import regulars from '@/utils/regulars.js';

	export default {
		components: {},
		created() {
			this.getData()
		},
		data() {
			return {
				footWidth: '0px',
				loading: true, //列表loading
				tableData: [],
				searchForm: {
					hospital_name: '',
					doctor_name: '',
					disease_type: '',
					limit: limit,
					currentPage: 1
				},
				total: 0,
				totalPage: 0,
				titleName: '',
				addVisible: false,
				addForm: {},
				addFormRules: {
					diag_result: [{
						required: true,
						message: "请输入中医症型",
						trigger: "blur"
					}],
					diag_des: [{
						required: true,
						message: "请输入症状描述",
						trigger: "blur"
					}],
				},
				type: '', //add,edit
				row: null,
				deptOptions: [], //组织机构树
				deptInfo: {
					id: '',
					name: ''
				},
				recipeOptions: [], //处方下拉选
			}
		},
		mounted() {
			//this.getTableProp();
			var that = this;
			document.getElementsByClassName('content')[0].onclick = function() {
				if (that.currentChange) {
					that.currentChange = false;
				} else {
					that.setCurrent();
				}
			}
			that.footWidth = that.$refs.table.bodyWidth;
		},
		methods: {
			getRecipeSelect() {
				this.$http.post(BaseUrl.getRecipeSelect).then(res => {
					if (res.data.code == 0) {
						this.recipeOptions = res.data.data;
					} else {
						this.$message({
							message: res.data.msg,
							type: 'warning'
						})
					}
				})
			},
			setCurrent(row) { //取消单选
				//this.$refs.table.setCurrentRow(row);
			},
			handleCurrentChangeT(row, index) {
				this.currentChange = true;
				this.$refs.table.setCurrentRow(row);
			},
			getData() {
				this.loading = true; //列表loading
				this.$http.post(BaseUrl.getDiagPageList, this.searchForm).then(res => {
					if (res.data.code == 0) {
						this.tableData = res.data.data.data;
						this.totalPage = res.data.data.totalPage;
						this.total = res.data.data.total;
						this.loading = false; //列表loading
					} else {
						this.$message({
							message: res.data.msg,
							type: 'warning'
						})
					}

				})
			},
			handleSizeChange(val) {
				this.searchForm.limit = val;
				this.getData();
			},
			handleCurrentChange(val) {
				this.searchForm.currentPage = val;
				this.getData();
			},
			reset() {
				this.resetSearchForm(this.searchForm, [{
					k: 'limit',
					v: this.searchForm.limit
				}, {
					k: 'currentPage',
					v: this.searchForm.currentPage
				}]);
				// this.getData();
			},
			showAddVisible(type, row) {
				this.getRecipeSelect();
				this.addForm = {};
				this.deptInfo = {
					id: '',
					name: '' //row.dept_name
				}
				this.type = type;
				if (type == 'add') {
					this.titleName = "添加中医症型";
					this.addVisible = true;
					/*this.listDept().then(res=>{
					    this.deptOptions = this.handleTree(res.data.data,"dept_id");
					    
					});*/
				} else {
					this.titleName = "修改中医症型";
					this.row = row;
					this.$set(row, 'editLoading', true);
					this.$http.post(BaseUrl.getDiagById, {
						id: row.id
					}).then(res => {
						if (res.data.code == 0) {
							this.deptInfo = {
								id: res.data.data.dept_id,
								name: row.dept_name //row.dept_name
							}
							this.addForm = res.data.data;
							this.addVisible = true;
							this.$set(row, 'editLoading', false);
							/*this.listDept().then(res=>{
							    this.deptOptions = this.handleTree(res.data.data,"dept_id");
							    
							});*/
						} else {
							this.$message({
								message: res.data.msg,
								type: 'warning'
							})
						}

					})

				}

			},
			//保存新增/修改
			saveEdit(form, type) {
				this.$refs[form].validate((valid) => {
					if (valid) {
						delete this[form].dept_name;
						if (type == 'add') {
							this.$http.post(BaseUrl.addDiagInfo, this[form]).then(res => {
								if (res.data.code == 0) {
									this.titleName = '';
									this.getData();
									this.addVisible = false;
									this.$message({
										message: res.data.msg,
										type: 'success'
									})
								} else {
									this.$message({
										message: res.data.msg,
										type: 'warning'
									})
								}
							})
						} else {
							this[form].id = this.row.id;
							this.$http.post(BaseUrl.saveDiagById, this[form]).then(res => {
								if (res.data.code == 0) {
									this.titleName = '';
									this.getData();
									this.addVisible = false;
									this.$message({
										message: res.data.msg,
										type: 'success'
									})
								} else {
									this.$message({
										message: res.data.msg,
										type: 'warning'
									})
								}
							})
						}


					}
				})

			},
			deleteStation(row) {
				var that = this;
				this.$confirm('是否确认删除"' + row.diag_result + '"的数据项?', "警告", {
					confirmButtonText: "确定",
					cancelButtonText: "取消",
					type: "warning"
				}).then(function() {
					that.$http.post(BaseUrl.delDiag, {
						id: row.id
					}).then(res => {
						if (res.data.code == 0) {
							that.getData();
							that.$message({
								message: '删除成功',
								type: 'success'
							})
						} else {
							that.$message({
								message: res.data.msg,
								type: 'warning'
							})
						}

					})

				}).catch(() => {
					this.$message({
						type: 'info',
						message: '已取消删除'
					});
				});
			},
			noClick() {
				this.getData();
				this.addVisible = false;
			},

			getFatherAncestors(val) {}

		}

	}
</script>
<style scoped>
	.selectWdith {
		width: 460px;
	}
</style>