<template>
    <div class="table">
        <div class="container"  >
        	<!--搜索框-->
        	<el-form :inline="true" :model="searchForm" ref="searchForm">
                <el-form-item  label="登录账号或姓名">
                    <el-input v-model.trim="searchForm.account" size="small" placeholder="请输入登录账号或姓名"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" plain icon="el-icon-search" size="small" @click="search()">查询</el-button>
                    <el-button type="warning" plain icon="el-icon-refresh" size="small" @click="reset()">重置</el-button>
					<el-button type="success" plain size="small"  icon="el-icon-plus" @click="addPerson()" v-hasPermi="['sys:user:add']">新增</el-button>
                </el-form-item>
            </el-form>
        	<!--内容列表-->
			<div style="position:relative;">
				<el-row :gutter="20">
					<!--部门数据-->
					<el-col :span="4" :xs="24"  v-if="showTree">
						<div class="head-container">
						
						</div>
						<div class="head-container">
						<el-tree
							:style="'height:'+tableH+'px;overflow: auto;'"
							:data="deptOptions"
							:props="defaultProps"
							:expand-on-click-node="false"
							:filter-node-method="filterNode"
							ref="tree"
							
							@node-click="handleNodeClick"
						/><!--default-expand-all-->
						</div>
					</el-col>
					<!--用户数据-->
					<el-col :span="tableSpan" :xs="24" style="position:relative">
                        <div @click="showTree=!showTree" style="position: absolute;top: 200px;z-index: 9;left: -10px; color:#ccc; cursor: pointer;">
                            <el-tooltip class="item" effect="dark" :content="treeContent" v-model="contentChange" transition="none" placement="right">
                                <i class="caret caret-right" v-if="!showTree"></i>
                                <i class="caret caret-left" v-if="showTree"></i>
                            </el-tooltip>
                        </div>
						<el-table v-loading="loading" ref="table" highlight-current-row @current-change="handleCurrentChangeT"   :data="tableData" border stripe size="mini">
							<el-table-column type="index" label="序号" width="50" align="center">
							</el-table-column>
							<el-table-column prop="real_name" label="姓名" show-overflow-tooltip width="120"></el-table-column>
							<el-table-column prop="account" label="登录账号" show-overflow-tooltip width="120"></el-table-column>
							<el-table-column prop="emp_number" label="员工号"  show-overflow-tooltip></el-table-column>
							<el-table-column prop="mobile" label="联系方式"  show-overflow-tooltip></el-table-column>
							<el-table-column prop="dept_name" label="所属科室" show-overflow-tooltip></el-table-column>
							<el-table-column prop="role_name" label="所属角色" show-overflow-tooltip></el-table-column>
							<el-table-column prop="state" label="状态" width="50" show-overflow-tooltip >
								<template slot-scope="scope">{{ scope.row.state | stateFilter }}</template>
							</el-table-column>
							<el-table-column prop="remark" label="备注" width="150" show-overflow-tooltip></el-table-column>
							<el-table-column label="操作" width="420">
							<template slot-scope="scope">
								<el-button size="mini"  type="primary" :disabled="scope.row.account == 'admin'" plain icon="el-icon-edit" @click="handleEdit(scope.$index,scope.row)" :loading="scope.row.editLoading" v-hasPermi="['sys:user:upd']">修改</el-button>
								<el-button size="mini"  type="warning" :disabled="scope.row.account == 'admin'" icon="el-icon-refresh" plain @click="resetPwd(scope.row)" v-hasPermi="['sys:user:upd']">重置密码</el-button>
								<el-button size="mini"  type="danger" :disabled="scope.row.account == 'admin'" icon="el-icon-delete" plain @click="deleteUser(scope.row)" v-hasPermi="['sys:user:del']">删除用户</el-button>
								
								<el-button size="mini"  type="warning" :disabled="scope.row.account == 'admin'" plain icon="el-icon-close" v-if="scope.row.state == '0'" @click="prohibit(scope.row)" v-hasPermi="['sys:user:upd']">停用</el-button>
								<el-button size="mini"  type="primary" :disabled="scope.row.account == 'admin'" plain icon="el-icon-check" v-if="scope.row.state == '1'" @click="enable(scope.row)" v-hasPermi="['sys:user:upd']">启用</el-button>
							</template>
						</el-table-column>
						</el-table>
						<!--TODO 分页-->
						<div class="pagination table-width-b" v-if="totalPage!=0" style="width:1700px;max-width:100%;">
							<el-pagination 
								@size-change="handleSizeChange" 
								:page-sizes="[10,20,50]"
								:page-size="searchForm.limit"
								:current-page.sync="searchForm.currentPage"
								@current-change="handleCurrentChange"
								layout="jumper, prev, pager, next, sizes, total"
								:total="total">
							</el-pagination>
						</div>
					</el-col>
				</el-row>
			</div>
			<el-dialog :close-on-click-modal="false" :title='titleName' v-if="detailVisible" :visible.sync="detailVisible" width="900px" top="20vh" :append-to-body="true" :before-close="noClick">
			    <el-form :inline="true"  ref="form" :model="form" label-width="100px" :rules="editRules">
					<el-form-item label="登录账号" prop="account">
			            <el-input :disabled="disabled" v-model="form.account" size="small" placeholder="请输入登录账号" style= "width: 300px;" maxlength = "16"></el-input>
			        </el-form-item>
					<el-form-item label="姓名" prop="real_name">
			            <el-input v-model.trim="form.real_name" size="small" placeholder="请输入姓名" style= "width: 300px;" maxlength = "20" ></el-input>
			     </el-form-item>
			        <el-form-item label="性别" prop="sex">
						<el-select v-model="form.sex" placeholder="请选择性别" size="small" style= "width: 300px;">
							<el-option
								v-for="(item,index) in sexs"
								:key="index"
								:label="item.name"
								:value="item.id">
							</el-option>
						</el-select>
			        </el-form-item>
					<el-form-item label="员工号" prop="emp_number">
						<el-input v-model.trim="form.emp_number" size="small" placeholder="请输入员工号" style= "width: 300px;" maxlength = "16"></el-input>
			        </el-form-item>
					<el-form-item label="状态" prop="state">
                        <el-radio-group v-model="form.state" style= "width: 300px;">
                            <el-radio
                            v-for="item in stateOptions"
                            :key="item.value"
                            :label="item.value"
                            >{{item.label}}</el-radio>
                        </el-radio-group>
                    </el-form-item>
					<el-form-item label="角色名称" prop="role_id">
						<el-select v-model="form.role_id" style= "width: 300px;" size="small" placeholder="请选择角色名称">
							<el-option
								v-for="(item,index) in roleNameOptions"
								:key="index"
								:label="item.values"
								:value="item.key">
							</el-option>
						</el-select>
				    </el-form-item>
			        <el-form-item label="联系方式" prop="mobile">
			            <el-input v-model.trim="form.mobile" style= "width: 300px;" placeholder="请输入联系方式" maxlength = "20" size="small"></el-input>
			        </el-form-item>
			        
			        <el-form-item label="备注" prop="remark">
			            <el-input v-model.trim="form.remark" type="textarea" rows="3" cols = '60' size="small" placeholder="请输入备注，限制输入长度小于500字符" maxlength="500" style= "width: 710px;"></el-input>
			        </el-form-item>
			    </el-form>
			    <span slot="footer" class="dialog-footer">
					<el-button size="small" @click="noClick()">取&nbsp;消</el-button>
			        <el-button type="primary" size="small" @click="saveEdit('form',type)" v-if="!btnDisabled">保&nbsp;存</el-button>
			        <el-button size="small" type="success" plain  v-else loading disabled>保&nbsp;存</el-button>
			        
			    </span>
			</el-dialog>
        </div>
    </div>
</template>
<script>
import BaseUrl from '@/utils/baseUrl';
import regulars from '@/utils/regulars.js';
import Cookie from "js-cookie";
export default {
	components: {  },
    data(){
		//手机号校验
		// const telReg = /^((13[0-9])|(14[0-9])|(15[0-9])|(16[0-9])|(17[0-9])|(18[0-9])|(19[0-9]))\d{8}$/;
		// const telReg = /^(^[0-9]{3,4}\-[0-9]{3,8}$)|(^[0-9]{3,8}$)|(^[0−9]3,4[0-9]{3,8}$)|(^0{0,1}13[0-9]{9}$)/;
		const telReg = /^((0\d{2,3}-\d{7,8})|(((13[0-9])|(14[0-9])|(15[0-9])|(16[0-9])|(17[0-9])|(18[0-9])|(19[0-9]))\d{8}))|400[0-9]{7}$/;
		var telValue = regulars.checkRegular2(telReg,'联系方式输入不合法!');
		//账号名称校验
		const accountReg = /^[A-Za-z0-9_]+$/;
		var accountValue = regulars.checkRegular(accountReg,'账号名称不能为空','最多输入16位数字、字母和下划线!');
		//姓名校验
		const nameReg = /^[a-z0-9A-Z_\u4e00-\u9fa5]+$/;
		var nameValue = regulars.checkRegular(nameReg,'姓名不能为空','允许输入汉字、字母、数字、下划线');
		var validatePass=(rule, value, callback) => {
			if(value===''){
				callback(new Error('请输入密码'));
			}else if(value.length<6){
				callback(new Error('请输入六位或以上的字符'));

			} else{
				callback();
			}

		}
		var checkStation=(rule, value, callback) => {
			console.log(value);
            if (!value) {
				//this.form.station_id=[];
                return callback(new Error('请选择所属站点'));
                
            }else{
				callback();
                // var hasStation=false;
                // for(let item of this.stationList){
                //     if(item.id==value){
                //         hasStation=true;
                //     }
                // }
                // if(!hasStation){
                //     this.form.station_id=[];
                //     return callback(new Error('请从下拉菜单中选择站点'));
                // }else{
                //     callback();
                // }
                
            }
        }
        return{
			loading:true,
			contentChange:false,
			treeContent:"展开",
			tableSpan:24,
			showTree:false,
			row:null,
			deptOptions:[],//组织机构树
			defaultProps:{
				children: 'children',
                label: 'dept_name'
			},
			isAble:true,//
        	searchForm:{
                account:'',
				role_name:'',
                limit:limit,
                currentPage:1
			},
            totalPage:0,
			total:0,
            form:{
				id:'',
				account:'',
				password:'',
               	real_name:'',
				mobile:'',
				sex:'',
				state:'0', 
				remark:'',
				emp_number:'',
				role_name:'',
				role_id:'',
				station_name:[]
			},
			stateOptions:[
                {label:'正常',value:"0"},
                {label:'停用',value:"1"}
            ],
            tableData:[],
            detailVisible:false,
            titleName:'',
            roleNameOptions: [{
				key: '',
				values: ''
			}],
            sexs:[
            	{name:'男',id:'男'},
            	{name:'女',id:'女'},
			],
			stationList:[],//站点列表
            editRules:{
                account: [
                    { required:true, trigger:'blur', validator:accountValue}
                ],
                real_name: [
                    { required:true, trigger:'blur', validator:nameValue }
				],
				password:[
					{ required:true, trigger:'blur', validator:validatePass }
				],
                mobile: [
                  { required:false, trigger:'blur', validator:telValue}
				],
				state: [
				    { required:true, message:'请选择账号状态', trigger:'blur' }
				],
				role_id:[
					{ required:true, message:'请选择角色名称', trigger:'change' }
				]
            },
            disabled:false,
            btnDisabled:false
        }
	},
	filters: {
		stateFilter: function (value) {
			if (value==0){
				return '启用'
			} else{
				return '停用'
			}
		}
	},
    created(){
        this.getData();
		this.operateFormRemovedomain();
	},
	watch: {
	  showTree(val){
			var that=this;
			this.contentChange=false;
			if(val){
				this.tableSpan=20;
				this.treeContent="收起"
			}else{
				that.tableSpan=24;
				this.treeContent="展开"
				
			}
		}
    },
	mounted(){
        //this.getTableProp();
        var that=this;
        document.getElementsByClassName('content')[0].onclick=function(){
            if(that.currentChange){
                that.currentChange=false;
            }else{
                that.setCurrent();
            }
            
        }
    },
    methods:{
		setCurrent(row) {//取消单选
            this.$refs.table.setCurrentRow(row);
        },
        handleCurrentChangeT(row,index){
            this.currentChange=true;
            this.$refs.table.setCurrentRow(row);
        },
    	search(){
            this.getData();
		},
        resetPwd(row){
			this.$confirm('是否对'+row.real_name+'的账号进行重置密码', '重置密码', {
                distinguishCancelAndClose: true,
                confirmButtonText: '确定重置',
                cancelButtonText: '取消',
                center: true
            })
            .then(() => {
				var data={type:'1',id:row.id};
				this.$http.post(BaseUrl.resetPassword,data).then(res=>{
					if(res.data.code == 0){
						this.$message({
							message: res.data.msg,
							type: 'success'
						});
						this.getData();
					}else{
						this.$message({
							message: res.data.msg,
							type: 'warning'
						})
					}
				})
			}).catch(action => {});
			
        },
        getData(){
			this.loading=true;//列表loading
        	//过滤全部'_'
        	var a = this.searchForm.account==undefined?'':this.searchForm.account;
	        if((a.indexOf('_') != -1) && (a.indexOf('\\_') == -1)){
				this.searchForm.account = a.replace(/_/g, '\\_');
			}
            this.$http.post(BaseUrl.getUserListPage,this.searchForm).then(res=>{
				if(res.data.code==0){
					for(let item of res.data.data.data){
						item.editLoading=false;
					}
					this.tableData = res.data.data.data;
					this.totalPage = res.data.data.totalPage;
					this.total = res.data.data.total;
					if(this.searchForm.account !='' && this.searchForm.account!=undefined){
						this.searchForm.account = a;
					}
					this.loading=false;//列表loading
				}else{
					this.$message({
						message: res.data.msg,
						type: 'warning'
					})
				}
                
            })
		},
        getRole(){
			//获取角色下拉列表
			this.$http.post(BaseUrl.queryRoleByStr).then(res => {
			  this.roleNameOptions = res.data.data;
			});
		},
        reset(){
            this.resetSearchForm(this.searchForm,[{k:'limit',v:this.searchForm.limit},{k:'currentPage',v:this.searchForm.currentPage}]);
        },
        addPerson(){
			this.getRole();
			this.titleName = '新增用户';
			this.detailVisible = true;
			this.disabled = false;
			this.type = 'add';
			this.searchForm={
			    account:'',
				role_name:'',
			    purpose:'',
			    limit:limit,
			    currentPage:1
			};
			this.form = {
				id:'',
				account:'',
				password:'',
               	real_name:'',
				mobile:'',
				sex:'',
				state:'0', 
				remark:'',
				emp_number:'',
				role_name:'',
				role_id:'',
				station_name:[]
			}
        },
        handleEdit(index,row){
			this.titleName = '修改用户';
			this.$set(row,'editLoading',true);
			
			this.type = 'edit';
			this.getRole();
            this.$http.post(BaseUrl.getUserById,{id:row.id}).then(res=>{
                if(res.data.code == 0){
					this.form.id=res.data.data.id;
					this.form.account=res.data.data.account;
					this.form.real_name=res.data.data.real_name;
					this.form.mobile=res.data.data.mobile;
					this.form.sex=res.data.data.sex;
					this.form.state=res.data.data.state;
					this.form.remark=res.data.data.remark;
					this.form.emp_number=res.data.data.emp_number;
					this.form.role_name=res.data.data.role_name;
					this.form.role_id=res.data.data.role_id;
					this.disabled = true;
					this.detailVisible = true;
					this.$set(row,'editLoading',false);
                    
                }
            })
        },
        handleSizeChange(val){
            this.searchForm.limit = val;
            this.getData();
        },
        handleCurrentChange(val){
            this.searchForm.currentPage = val;
            this.getData();
        },
        saveEdit(form,type){
            this.$refs[form].validate((valid)=>{
                if(valid){
                    if(this.btnDisabled){
                        return false;
                    }
                    this.btnDisabled = true;
					var form=JSON.parse(JSON.stringify(this.form));
                	if(type == 'edit'){
	                    this.$http.post(BaseUrl.saveUserInfo,form).then(res=>{
	                        if(res.data.code == 0){
	                        	this.getData();
	                            this.$message({
	                                message: res.data.msg,
	                                type:'success'
                                })
                                this.detailVisible = false;
                                this.btnDisabled = false;
	                        }else{
	                            this.getData();
	                            this.$message({
	                                message: res.data.msg,
	                                type: 'warning'
                                })
                                this.detailVisible = false;
                                this.btnDisabled = false;
	                        };
	                    })
	                }else{
	                	this.$http.post(BaseUrl.addUserInfo,form).then(res=>{
							if(res.data.code == 0){
								this.getData();
								// this.$message({
								// 	message: res.data.msg,
								// 	type:'success'
								// })
								const h = this.$createElement;
								this.$msgbox({
								title: '提示',
								message: h('p', null, [
									h('span', null, '用户创建成功，初始密码为: '),
									h('span', null, '123456')
								]),
								showCancelButton: false,
								confirmButtonText: '确定',
								}).then(() => {
									// this.$message({
									// 	type: 'success',
									// 	message: '删除成功!'
									// });
								})
								// this.$confirm('用户创建成功，初始密码为:123456', '提示', {
								// 	confirmButtonText: '确定',
								// 	type: 'success'
								// }).then(() => {
								// 		// this.$message({
								// 		// 	type: 'success',
								// 		// 	message: '删除成功!'
								// 		// });
								// })
								this.detailVisible = false;
								this.btnDisabled = false;
							}else{
								//this.getData();
								this.$message({
									message: res.data.msg,
									type: 'warning'
								})
								this.detailVisible = false;
								this.btnDisabled = false;
							};
		                })
		            }
                }else{
					// this.$message({
					// 	message:"请检查信息是否填写完全",
					// 	type:'error'
	    //             })
					return false;
            	}
        	})
		},
		//删除用户
		deleteUser(row){
			var that=this;
            this.$confirm('是否确认删除用户"' + row.real_name + '"?', "警告", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning"
            }).then(function() {
                that.$http.post(BaseUrl.deleteUser,{id:row.id}).then(res=>{
                    that.getData();
                    that.$message({
                        message:'删除成功',
                        type:'success'
                    })
                })
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                });          
            });
		},
        noClick(){
        	this.getData();
        	if (this.$refs['form'] !== undefined) {
        	 	this.$refs["form"].resetFields();
        	}
        	this.detailVisible = false;
        },
        prohibit(row){
            this.$confirm('是否禁用用户', '', {
                distinguishCancelAndClose: true,
                confirmButtonText: '确定',
                cancelButtonText: '取消'
            })
			.then(() => {
				let obj = {id:row.id,state:"1"};
				this.$http.post(BaseUrl.updateAccountState,obj).then(res=>{
					if(res.data.code == 0){
						this.$message({
							message: res.data.msg,
							type: 'success'
						});
						this.getData();
					}
				})
			})
			.catch(action => {});
        },
        enable(row){
            this.$confirm('是否启用用户', '', {
                distinguishCancelAndClose: true,
                confirmButtonText: '确定',
                cancelButtonText: '取消'
			})
			.then(() => {
				let obj = {id:row.id,state:"0"};
				this.$http.post(BaseUrl.updateAccountState,obj).then(res=>{
					if(res.data.code == 0){
						this.$message({
							message: res.data.msg,
							type: 'success'
						});
						this.getData();
					}
				})
			})
			.catch(action => {});
        },
        operateFormRemovedomain() {
			let domains = sessionStorage.getItem('real_name');
			if (domains == '超级管理员') {
				this.isAble = true; 
			} else {
				this.isAble = false; 
			}
		},
		filterNode(value, data) {
			if (!value) return true;
			return data.dept_name.indexOf(value) !== -1;
		},
		//点击tree，按结果查询
		handleNodeClick(data,node,val){
			this.getData();
		},
		getFatherAncestors(val){
            // console.log('###FatherAncestors',val);
            // this.form.ancestors=val;
		},
		querySearch(queryString, cb) {
            var stationList = this.stationList;
            var results = queryString ? stationList.filter(this.createFilter(queryString)) : stationList;
            // 调用 callback 返回建议列表的数据
            cb(results);
        },
        createFilter(queryString) {
            return (station) => {
                return (station.value.indexOf(queryString) > -1);
            };
        },
    }
}
</script>
<style scoped>
.caret.caret-left{
  background-image: url(../../../src/assets/images/left.png);
}
.caret.caret-right{
  background-image: url(../../../src/assets/images/right.png);
}
</style>