<template>
  <div class="container">
    <!-- 搜索区域 -->
    <div class="search-area">
      <el-form :inline="true" :model="queryParams" size="small">
        <el-form-item label="医生姓名">
          <el-input v-model="queryParams.doctor_name" placeholder="请输入医生姓名" clearable @clear="handleSearch"></el-input>
        </el-form-item>
        <el-form-item label="医嘱类型">
          <el-input v-model="queryParams.name" placeholder="请输入医嘱类型" clearable @clear="handleSearch"></el-input>
        </el-form-item>
        <el-form-item label="医嘱来源">
          <el-select v-model="queryParams.flag" placeholder="请选择" clearable @clear="handleSearch">
            <el-option label="医生自定义" value="1"></el-option>
            <el-option label="系统录入" value="0"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="handleSearch">查询</el-button>
          <!--          重置-->
          <el-button type="info" @click="handleReset">重置</el-button>

        </el-form-item>
      </el-form>
    </div>
    <div class="table-operations">
      <el-button type="primary" size="small" @click="handleAdd">新增医嘱类型</el-button>
    </div>

    <el-table :data="tableData" v-loading="loading" ref="table" border stripe size="mini" style="width: fit-content">
      <el-table-column align="center" type="index" label="编号" width="60"></el-table-column>
      <el-table-column align="center" prop="name" label="医嘱类型" :show-overflow-tooltip="true" width="500"></el-table-column>
      <el-table-column align="center" prop="doctor_name" label="创建医生" width="120"></el-table-column>
      <el-table-column align="center" prop="create_time" label="创建时间" width="180"></el-table-column>
      <el-table-column align="center" label="操作" width="240">
        <template slot-scope="scope">
          <el-button size="mini" type="warning" @click="showDetails(scope.row)">医嘱明细</el-button>
          <el-button size="mini" type="primary" @click="handleEdit(scope.row)">编辑</el-button>
          <el-button size="mini" type="danger" @click="handleDelete(scope.row)">删除</el-button>

        </template>
      </el-table-column>
    </el-table>
    <!-- 主表格分页 -->


    <div class="pagination table-width-b" :style="'width:'+footWidth+';max-width:100%;'">
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="queryParams.currentPage" :page-sizes="[10, 20, 30, 50]" :page-size="queryParams.limit" layout="jumper, prev, pager, next, sizes, total" :total="total"></el-pagination>

    </div>

    <!-- 编辑/新增模板对话框 -->
    <el-dialog :title="dialogTitle" :visible.sync="dialogVisible" width="500px" :append-to-body="true" :before-close="cancel">
      <el-form :model="form" ref="form" :rules="rules" label-width="100px">
        <el-form-item label="医嘱类型" prop="name">
          <el-input v-model="form.name"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer">
        <el-button size="mini" @click="cancel()">取消</el-button>
        <el-button size="mini" type="primary" @click="submitForm">确定</el-button>
      </span>
    </el-dialog>

    <!-- 详情对话框 -->
    <el-dialog title="详情信息" :visible.sync="detailsVisible" width="800px" :append-to-body="true" :before-close="cancel">
      <div class="details-operations">
        <el-button type="primary" size="mini" @click="handleAddDetail">新增</el-button>
      </div>

      <el-table :data="detailsData" v-loading="detailLoading" border>
        <el-table-column type="index" label="编号" align="center" width="60"></el-table-column>
        <el-table-column prop="desc" label="内容" align="center" :show-overflow-tooltip="true"></el-table-column>
        <el-table-column prop="doctor_name" label="医生姓名" align="center" width="120"></el-table-column>
        <el-table-column label="操作" width="180" align="center">
          <template slot-scope="scope">
            <el-button size="mini" @click="handleEditDetail(scope.row)">编辑</el-button>
            <el-button size="mini" type="danger" @click="handleDeleteDetail(scope.row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>

      <!-- 详情表格分页 -->
      <el-pagination @size-change="handleDetailSizeChange" @current-change="handleDetailCurrentChange" :current-page="detailsPagination.currentPage" :page-sizes="[10, 20, 30, 50]" :page-size="detailsPagination.limit" layout="jumper, prev, pager, next, sizes, total" :total="detailTotal"></el-pagination>
    </el-dialog>

    <!-- 详情编辑对话框 -->
    <el-dialog :title="detailDialogTitle" :visible.sync="detailDialogVisible" width="500px">
      <el-form :model="detailForm" ref="detailForm" :rules="detailRules" label-width="100px">
        <el-form-item label="内容" prop="desc">
          <el-input v-model="detailForm.desc"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer">
        <el-button size="mini" @click="detailDialogVisible=false ">取消</el-button>
        <el-button size="mini" type="primary" @click="submitDetailForm">确定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import BaseUrl from "@/utils/baseUrl";

export default {
  name: 'TemplateManagement',
  data() {
    return {
      footWidth: '0px',
      loading: false,
      detailLoading: false,
      // 查询参数对象
      queryParams: {
        doctor_name: '',
        name: null,
        flag: null,
        currentPage: 1,
        limit: 10
      },
      total: 0,
      tableData: [],
      // 主表格表单
      dialogVisible: false,
      dialogTitle: '',
      form: {
        name: ''
      },
      rules: {
        name: [{ required: true, message: '请输入模板名称', trigger: 'blur' }]
      },

      // 详情表格数据
      detailsVisible: false,
      detailsData: [],
      selectedDetails: [],
      currentTemplateId: null,
      detailsPagination: {
        currentPage: 1,
        limit: 10,
        advice_id: null
      },
      detailTotal: 0,
      // 详情表格表单
      detailDialogVisible: false,
      detailDialogTitle: '',
      detailForm: {
        desc: ''
      },
      detailRules: {
        desc: [{ required: true, message: '请输入内容', trigger: 'blur' }]
      },
      row: null,
    }
  },
  mounted() {
    this.footWidth = this.$refs.table.bodyWidth;
  },
  created() {
    this.fetchData()
  },
  methods: {
    // 搜索方法
    handleSearch() {
      this.queryParams.currentPage = 1 // 重置到第一页
      this.fetchData()
    },
    // 分页方法
    handleSizeChange(val) {
      this.queryParams.limit = val
      this.fetchData()
    },
    handleCurrentChange(val) {
      this.queryParams.currentPage = val
      this.fetchData()
    },
    // 获取主表格数据
    fetchData() {
      this.loading = true
      this.$http.post(BaseUrl.getAdvicePageList, this.queryParams).then((response) => {
        if (response.data.code === 0) {
          this.tableData = response.data.data.data
          this.total = response.data.total
          this.queryParams.totalPage = response.data.data.totalPage;
          this.total = response.data.data.total;
          this.loading = false
        } else {
          this.$message.error(response.data.msg || '获取数据失败')
          this.loading = false

        }
      }).catch(() => {
        this.$message.error('获取数据失败')
        this.loading = false

      })
    },
    handleAdd() {
      this.dialogTitle = '新增模板'
      this.form = { name: '' }
      this.dialogVisible = true
    },
    handleDelete(row) {
      this.$confirm('确认删除该医嘱明细?', '提示', {
        type: 'warning'
      }).then(() => {
        this.$http.post(BaseUrl.delAdviceById, { id: row.id }).then(response => {
          if (response.data.code === 0) {
            this.$message.success('删除成功');
            this.fetchData();
          } else {
            this.$message.error(response.data.msg || '删除失败');
          }
        }).catch(() => {
          this.$message.error('删除失败');
        });
      }).catch(() => {
      });
    },
    handleEdit(row) {
      this.dialogTitle = '修改模板'
      this.form = { name: '' }
      this.form = row
      this.dialogVisible = true

    },
    cancel(done) {
      this.detailDialogVisible = false
      this.dialogVisible = false
      this.fetchData()
      if (typeof (done) === 'function'){
        done();
      }
    },
    submitForm() {
      this.$refs.form.validate(valid => {
        if (valid) {
          const url = this.form.id ? BaseUrl.updAdviceById : BaseUrl.addAdvice;
          this.$http.post(url, this.form).then(response => {
            if (response.data.code === 0) {
              this.$message.success(response.data.msg);
              this.dialogVisible = false;
              this.fetchData();
            } else {
              this.$message.error(response.data.msg || '操作失败');
            }
          }).catch(() => {
            this.$message.error('操作失败');
          });
        }
      });
    },
    // 详情表格相关方法
    showDetails(row) {
      if (row) {
        this.row = row
      }
      this.detailsPagination.advice_id = this.row.id
      this.$http.post(BaseUrl.getAdviceDetailList, this.detailsPagination).then((response) => {
        if (response.data.code === 0) {
          this.detailsData = response.data.data.data;
          this.detailsVisible = true
          this.detailsPagination.currentPage = response.data.data.currentPage;
          this.detailsPagination.totalPage = response.data.data.totalPage;
          this.detailTotal = response.data.data.total;
        } else {
          this.$message.error(response.data.msg || '获取数据失败')
        }

      })
    },


    handleDetailSizeChange(val) {
      this.detailsPagination.limit = val
      this.showDetails()
    },
    handleReset() {
      this.resetSearchForm(this.queryParams, [{
        k: 'limit',
        v: this.queryParams.limit
      }, {
        k: 'currentPage',
        v: this.queryParams.currentPage
      }]);
      this.fetchData()
    },
    handleDetailCurrentChange(val) {
      this.detailsPagination.currentPage = val
      this.showDetails()
    },

    handleAddDetail() {
      this.detailDialogTitle = '新增详情'
      this.detailForm = { desc: '' }
      this.detailDialogVisible = true
    },

    handleEditDetail(row) {
      this.detailDialogTitle = '编辑详情'
      this.detailForm = { ...row }
      this.detailDialogVisible = true
    },

    handleDeleteDetail(row) {
      this.$confirm('确认删除该详情?', '提示', {
        type: 'warning'
      }).then(() => {
        this.$http.post(BaseUrl.delAdviceDetailById, { id: row.id }).then(response => {
          if (response.data.code === 0) {
            this.$message.success(response.data.msg);
            this.detailDialogVisible = false;
            this.showDetails();
          } else {
            this.$message.error(response.data.msg || '操作失败');
          }
        });
      }).catch(() => {
      });
    },
    submitDetailForm() {
      //   提交
      this.$refs.detailForm.validate(valid => {
        if (valid) {
          const url = this.detailForm.id ? BaseUrl.updAdviceDetailById : BaseUrl.addAdviceDetail;
          this.detailForm.advice_id = this.row.id
          this.$http.post(url, this.detailForm).then(response => {
            if (response.data.code === 0) {
              this.$message.success(response.data.msg);
              this.detailDialogVisible = false;
              this.showDetails();
            } else {
              this.$message.error(response.data.msg || '操作失败');
            }
          });
        }
      });
    }

  }
}
</script>

<style scoped>


.table-operations,
.details-operations {
  margin-bottom: 20px;
}

.el-form-item {
  margin-bottom: 16px !important;
}
</style>