<template>
    <div class="table">
        <div class="container"  >
            <!--TODO 搜索条件框-->
        	<el-form class="handle-box" :inline="true" :model="searchForm" ref="searchForm">
        		<el-form-item label="角色名称">
		    	   <el-input placeholder="请输入角色名称" size="small" v-model="searchForm.role_name" class="handle-input"></el-input>
		    	</el-form-item>
                <el-form-item>
		    	   <el-button type="primary" plain icon="el-icon-search" size="small" @click="search()">查询</el-button>
			       <el-button type="warning" plain icon="el-icon-refresh" size="small" @click="reset()">重置</el-button>
                   <el-button type="success" plain icon="el-icon-plus"  size="small"  @click="handleAdd" v-hasPermi="['sys:role:add']">新增</el-button>
		    	</el-form-item>
        	</el-form>
            <!--TODO 内容列表-->
            <el-table v-loading="loading" ref="table" highlight-current-row @current-change="handleCurrentChangeT"   class = "table-width" :data="tableData" border  stripe size="mini"  style="width: fit-content;">
                <el-table-column type="index" label="序号" width="50" align="center">
                </el-table-column>
                <el-table-column label="角色名称" width="200">
                	<template slot-scope='scope'>
                		{{scope.row.role_name}}
                	</template>
                </el-table-column>
                
                <el-table-column label="备注" width="350" show-overflow-tooltip>
                	<template slot-scope='scope'>
                		{{scope.row.remark}}
                	</template>
                </el-table-column>
                <el-table-column label="操作" width="220" fixed="right">
                    <template slot-scope="scope">
                        <el-button size="mini" type="primary" :disabled="(scope.row.role_id == '1')? true : false " plain icon="el-icon-edit" @click="handleEdit(scope.row)" v-hasPermi="['sys:role:upd']">修&nbsp;改</el-button>
                        <el-button size="mini" type="danger"  :disabled="(scope.row.role_id == '1')? true : false " plain icon="el-icon-delete" @click="handleDel(scope.row)" v-hasPermi="['sys:role:del']">删&nbsp;除</el-button>
                    </template>
                </el-table-column>
            </el-table>
           <!--TODO 分页-->
            <div class="pagination table-width-b" v-if="totalPage!=0" style="width:840px;max-width:100%;">
                <el-pagination 
                	@size-change="handleSizeChange" 
                	:page-sizes="[10,20,50]"
                	:page-size="searchForm.limit"
                	:current-page.sync="searchForm.currentPage"
                	@current-change="handleCurrentChange"
                	layout="jumper, prev, pager, next, sizes, total"
                	:total="total">
                </el-pagination>
            </div>
        </div>
        <!-- 修改弹出框 -->
        <el-dialog :close-on-click-modal="false" @close="closeReset(editForm)" :title='editTitle' :visible.sync="editVisible" width="920px" top="20vh">
            <el-form ref="editForm" :model="editForm" :rules="editFormRules" label-width="100px">
            	<el-row>
                	<el-col :span="12">
                		<el-form-item label="角色名称：" prop='role_name'>
		                    <el-input size="small" v-model="editForm.role_name" placeholder="请输入角色名称"></el-input>
		                </el-form-item>
		                
		                <el-form-item label="备注：" prop='remark'> 
		                    <el-input size="small" v-model="editForm.remark" type="textarea" :rows="5" maxlength="500" placeholder="请输入备注，限制500字符以内"></el-input>
		                </el-form-item>
                	</el-col>
                	<!--树形结构-->
                	<el-col :span="12">
                		<el-form-item>
		                    <el-tree
                                :check-strictly="false"
		                    	:data='treeData'
								node-key="id"
								:props="props"
								:accordion='true'
								show-checkbox
								ref="tree"
								>
                                <!--父子不关联-->
                                <!--@check="checkedTree"-->
							</el-tree>
		                </el-form-item>
                	</el-col>
                </el-row>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="closeEdit(editForm)" size="small">取&nbsp;消</el-button>
				<el-button type="primary" @click="saveEdit()" size="small">保&nbsp;存</el-button>
            </span>
        </el-dialog>
        <!-- 保存提示框 -->
        <el-dialog :close-on-click-modal="false" @closed="closeDialogForLoad" title="提示" :visible.sync="saveVisible" width="300px" center>
            <div class="del-dialog-cnt">是否确定保存？</div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="saveVisible = false" size="small">取 消</el-button>
            	<el-button type="primary" @click="confirmSave" :loading="saveLoading" :disabled= "saveLoading" style="margin-right:10px" size="small">保&nbsp;存</el-button>
                
            </span>
        </el-dialog>
        
        <!-- 删除提示框 -->
        <el-dialog :close-on-click-modal="false" title="提示" :visible.sync="delVisible" width="300px" center>
            <div class="del-dialog-cnt">是否确定删除该条数据？</div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="delVisible = false" size="small">取 消</el-button>
            	<el-button type="danger" @click="confirmDel" style="margin-right:10px" size="small">删 除</el-button>
                
            </span>
        </el-dialog>
        
        <!-- 报错提示框 -->
        <el-dialog :close-on-click-modal="false" title="提示" :modal="false" :visible.sync="errorVisible" width="300px" center>
            <div class="del-dialog-cnt">{{errorMsg}}</div>
            <span slot="footer" class="dialog-footer">
                <el-button type='danger' plain @click="errorVisible = false" size="small">确&nbsp;定</el-button>
            </span>
        </el-dialog>
    </div>
</template>
<script>
import BaseUrl from '@/utils/baseUrl'
export default {
    data(){
        return{
            loading:true,
            searchForm:{
                role_name:'',
                limit:limit,
                currentPage:1
            },
            totalPage:0,
            total:0,
            /*列表变量*/
            tableData: [],	//列表数据
            /*新增修改变量*/
            checkedArr: [],	//选中树形节点的id
            rowId: '',	//当前选中行id
            editVisible: false,	//是否显示修改弹出框
            editTitle:'',	//新增修改弹出框title
            editForm: {	//修改页面表单对象
                role_name: '',
                role_type: '',
                remark: ''
            },
            treeData:[],	//属性结构数据
            roleId:'',
            props: {	//树形结构配置
                label: 'menu_name',
                children: 'children'
            },
            editFormRules: {	//修改dialog角色名非空规则
                role_name: [{
                    required: true,
                    message: '请输入角色名称', 
                    trigger: 'blur'
                }, {
                    max: 15,
                    message: '最多允许输入15位',
                    trigger: 'blur'
                }],
               /* role_type: [{
                    required: true,
                    message: '请输入角色类型', 
                    trigger: 'blur'
                }], */
                remark: [{
                    max: 400,
                    message: '最多允许输入400位',
                    trigger: 'blur'
                    }
                ]
            },
            saveVisible: false,
            delVisible: false,	//是否显示删除dialog 
            errorVisible: false,	//是否显示错误提示弹出框
            errorMsg: '',	//错误信息数据
            saveLoading : false
        }
    },
    created(){
        this.getData();
    },
    mounted(){
        //this.getTableProp();
        var that=this;
        document.getElementsByClassName('content')[0].onclick=function(){
            if(that.currentChange){
                that.currentChange=false;
            }else{
                that.setCurrent();
            }
            
        }
    },
    methods:{
        setCurrent(row) {//取消单选
            this.$refs.table.setCurrentRow(row);
        },
        handleCurrentChangeT(row,index){
            this.currentChange=true;
            this.$refs.table.setCurrentRow(row);
        },
        /*获取列表数据*/
        getData(){
            this.loading=true;//列表loading
            this.$http.post(BaseUrl.getRoleByPage,this.searchForm).then(res=>{
                this.tableData = res.data.data.data;
				this.totalPage = res.data.data.totalPage;
				this.total = res.data.data.total;
                this.loading=false;//列表loading
            })
        },
        search(){
            this.getData();
        },
        reset(){
            this.resetSearchForm(this.searchForm,[{k:'limit',v:this.searchForm.limit},{k:'currentPage',v:this.searchForm.currentPage}]);
        },
        handleAdd(){
            this.editTitle = '新增角色';
            if (this.$refs['editForm']!==undefined) {
                this.$refs['editForm'].resetFields();
            }
            this.getTreeDate();
            this.editVisible = true;
        },
        handleEdit(row){
            this.rowId = row.role_id;
            this.editTitle = '修改角色配置';
            if (this.$refs['editForm']!==undefined) {
                this.$refs['editForm'].resetFields();
            }
            this.getTreeDate();
            this.editVisible = true;
        },
        /*删除*/
        handleDel(row){
            this.rowId = row.role_id;
            // this.delVisible = true;
            this.$confirm('是否确认删除角色"' + row.role_name + '"?', "警告", {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
                }).then(() => {
                    this.confirmDel();
                }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                });          
            });
        },
        handleCurrentChange(val){
            this.searchForm.currentPage = val;	//给当前页变量赋值当前页数
            this.getData();	//调用查询列表方法
        },
        handleSizeChange(val){
            this.searchForm.limit = val;
            this.getData();
        },
        /*获取树形结构角色数据*/
        getTreeDate(){
            var _this=this;
            this.$http.post(BaseUrl.getMenuList).then(res=>{
                if(res.data.code ==0){
                    this.treeData = _this.handleTree(res.data.data,"id","pid","list");
                    console.log(this.treeData);
                    if(this.editTitle == '修改角色配置'){
                    	this.roleId = this.rowId;
                        this.$http.post(BaseUrl.getRoleById,{roleId:this.roleId}).then(_res=>{
                            if(_res.status == 200){
                                this.setEditForm(_res.data.data, this.editForm);
                                // var getCheckedObj = _res.data.data.menus;
                                // for(var i=0; i<getCheckedObj.length; i++){
                                //     this.checkedArr.push(parseInt(getCheckedObj[i].id));
                                // }
                                this.checkedArr=_res.data.data.menuIds
                                this.$refs.tree.setCheckedKeys(this.checkedArr);
                            }else {
                                this.errorVisible = true;
                                this.errorMsg = '请求异常！';
                            }
                        })
                    }
                }else {
                }
            });
        },
        
        /*选中树形结构节点*/
        checkedTree(obj, checked){
            console.log(obj,checked)
            this.checkedArr = [];
            // for(var i=0; i<checked.checkedNodes.length; i++){
            //     if(checked.checkedNodes[i].subs.length>0){
            //         for(var j=0; j<checked.checkedNodes[i].subs.length; j++){
            //             this.checkedArr.push(checked.checkedNodes[i].subs[j].id);
            //         }
            //     }else {
            //         if(this.checkedArr.indexOf(checked.checkedNodes[i].id) == -1){
            //             this.checkedArr.push(checked.checkedNodes[i].id);
            //         }
            //     }
            // }
        },
        /*保存修改/新增点击按钮*/
        saveEdit(){
            this.checkedArr=[];
            console.log(this.$refs.tree.getCheckedNodes())
            for(let item of this.$refs.tree.getCheckedNodes(false,true)){//false,true
                this.checkedArr.push(item.id)
            }
            this.$refs['editForm'].validate((valid) => {
                if (valid) {
                    if(this.checkedArr.length == 0){
                        this.errorVisible = true; //显示错误信息弹出框
                        this.errorMsg = '未选择功能模块！'; //给错误提示文本赋值
                        return;
                    }else {
                        this.confirmSave();
                        //this.saveVisible = true;
                    }
                } else {
                    return;
                }
            });
        },
        /*确定保存*/
        confirmSave(){
            this.saveLoading = true;
            var _url = '';
            if(this.editTitle == '修改角色配置'){
                _url = BaseUrl.updateMenuRole;
                var data = {
                    role_name: this.editForm.role_name,
                    // role_type: this.editForm.role_type,
                    remark: this.editForm.remark,
                    menuIds: this.checkedArr,
                    role_id: this.rowId
                };
            }else {
                _url = BaseUrl.saveRole;
                var data = {
                    
                    role_name: this.editForm.role_name,
                    // role_type: this.editForm.role_type,
                    remark: this.editForm.remark,
                    menuIds: this.checkedArr,
                    t:new Date().valueOf()
                };
            }
            
            this.$http.post(_url, data).then(res=>{
                if(res.status==200){
                    if(res.data.code == 0) {
                        this.$message.success(res.data.msg);
                        this.checkedArr = [];
                        this.saveVisible = false;
                        this.closeEdit();
                        this.getData();
                    }else if(res.data.code == '1005') {
                        alert('连接超时，请重新登录');
                        this.$router.push('/login');
                    }else {
                        this.errorVisible = true; //显示错误信息弹出框
                        this.errorMsg = res.data.msg; //给错误提示文本赋值
                        this.saveVisible = false;
                    }
                }else{
                    this.errorVisible = true; //显示错误信息弹出框
                    this.errorMsg = '请求异常'; //给错误提示文本赋值
                }
            });
        },
        /*关闭修改/新增dialog*/
        closeEdit(){
            this.editVisible = false;
        },
        /*确认删除*/
        confirmDel(){
            this.$http.post(BaseUrl.deleteRole, {roleId:this.rowId}).then(res=>{
                if(res.status == 200){
                    if(res.data.code == 0){
                        this.$message.success(res.data.msg);
                        this.delVisible = false;
                        this.getData();
                    }else if(res.data.code == '1005') {
                        alert('连接超时，请重新登录');
                        this.$router.push('/login');
                    }else {
                        this.$message.error(res.data.msg);
                        this.delVisible = false;
                    }
                }else {
                    this.errorVisible = true;
                    this.errorMsg = '请求异常';
                }
            });
        },
        closeReset(form){
            this.clearObj(form);
            this.treeData = [];
            this.checkedArr = [];
            this.$refs.tree.setCheckedKeys([]);
        },
        //控制二次提交
        closeDialogForLoad(){
            setTimeout(()=>{
                this.saveLoading = false;
            },100);
        },
    }
}
</script>
<!--TODO 页面私有样式-->
<style scoped>
    .handle-select {
        width: 185px;
    }
    .handle-input {
        width: 185px;
        margin-bottom: 10px;
        margin-right: 15px;
    }
    .table-width {
    	max-width:100%;
    	width: 1086px;
    }
</style>