import router from './router'
import store from "./store";

import { Message } from "element-ui";
import { getToken , getUid ,setToken,setUid } from "@/utils/auth"; 

router.beforeEach((to, from, next) => {
    //next();
    const token  = getToken();
    if(!!token || to.path === '/login'){
        next();
    }else{
        next('/login')
    }
})

router.afterEach(() => {})
