<template>
    <div>
        <el-dialog :close-on-click-modal="false" :title="titleName" v-if="visible" :visible.sync="visible" width="1250px" top="10vh" :append-to-body="true" :before-close="noClick">
            <el-row type="flex">
                <el-col>
                    <div class="watchText">处方单号：</div>
                    <div class="watchText">{{openRecipe.recipe_id}}</div>
                </el-col>
                <el-col>
                    <div class="watchText">患者姓名：</div>
                    <div class="watchText">{{openRecipe.pati_name}}</div>
                </el-col>
                <el-col>
                    <div class="watchText">患者电话：</div>
                    <div class="watchText">{{openRecipe.pati_phone}}</div>
                </el-col>
            </el-row>
            <el-row type="flex">
                <el-col>
                    <div class="watchText">性别：</div>
                    <div class="watchText">{{openRecipe.pati_sex}}</div>
                </el-col>
                <el-col>
                    <div class="watchText">年龄：</div>
                    <div class="watchText">{{openRecipe.age}}</div>
                </el-col>
                <el-col>
                    <div class="watchText">处方类型：</div>
                    <div class="watchText">{{openRecipe.recipe_state}}</div>
                </el-col>
            </el-row>
            <el-row type="flex">
                <el-col>
                    <div class="watchText">科室：</div>
                    <div class="watchText">{{openRecipe.dept_name}}</div>
                </el-col>
                <el-col>
                    <div class="watchText">处方开具时间：</div>
                    <div class="watchText">{{openRecipe.recipe_time}}</div>
                </el-col>
                <el-col>
                    <div class="watchText">医生姓名：</div>
                    <div class="watchText">{{openRecipe.doctor_name}}</div>
                </el-col>
            </el-row>
            <el-row type="flex">
                <el-col>
                    <div class="watchText">处方来源：</div>
                    <div class="watchText">{{openRecipe.recipe_source}}</div>
                </el-col>
                <el-col>
                    <div class="watchText"></div>
                    <div class="watchText"></div>
                </el-col>
                <el-col>
                    <div class="watchText"></div>
                    <div class="watchText"></div>
                </el-col>
            </el-row>
            
            
            <el-card class="mt30">
                <el-row type="flex">
                    <el-col>
                        <div class="watchTableKey">处方名称：</div>
                        <div class="watchTableValue">{{openRecipe.recipe_name}}</div>
                    </el-col>
                    <!--<el-col>
                        <div class="watchTableKey">剂数：</div>
                        <div class="watchTableValue">{{openRecipe.dosage}}</div>
                    </el-col>-->
                    <el-col>
                        <div class="watchTableKey">付数：</div>
                        <div class="watchTableValue">{{openRecipe.hand_num}}</div>
                    </el-col>
                </el-row>
                
                <el-table :data="openRecipe.orderGetherDetail" border :summary-method="getSummaries" show-summary style="width: 100%">
                    <!--<el-table-column prop="recipe_id" label="处方单号"> </el-table-column>-->
                    <el-table-column prop="part_num" label="颗粒序号"> </el-table-column>
                    <el-table-column prop="part_id" label="颗粒ID"> </el-table-column>
                    <el-table-column prop="part_name"  label="颗粒名称"> </el-table-column>
                    <el-table-column prop="prep_measure"  label="饮片计量"> </el-table-column>
                    <el-table-column prop="part_measure"  label="颗粒计量"> </el-table-column>
                    <el-table-column prop="weight"  label="当量"> </el-table-column>
                </el-table>
                <el-row type="flex">
                    <el-col>
                        <div class="box-b">总计：       处方金额：{{openRecipe.total_price}}元     </div>
                    </el-col>
                </el-row>
            </el-card>
        </el-dialog>
    </div>
</template>
<script>
export default {
    props: {
        //标题
        titleName:{
            type: String,
            default: ''
        },
        //患者应诊信息
        seeInfo:{
            type: Object,
            default () {
                return {}
            }
        },
        //开方
        openRecipe:{
            type: Object,
            default () {
                return {}
            }
        },
        //是否显示订单信息
        showOrder:{
            type: Boolean,
            default: false
        },
        //订单信息
        orders:{
            type: Array,
            default () {
                return []
            }
        },

    },
    data() {
        return{
            visible:false
        }
    },
    created(){},
    mounted(){},
    methods:{
        init(){
            this.visible = true;
        },
        noClick(){
            this.visible=false;
            this.$emit('refreshTable');
        },
        getSummaries(param) {
            const { columns, data } = param;
            let sums = [];
            columns.forEach((column, index) => {/*
                if (index === 0) {
                    sums[index] = '小计';
                    return;
                }
                const values = data.map(item => Number(item[column.property]));
                if (!values.every(value => isNaN(value))) {
                    sums[index] = values.reduce((prev, curr) => {
                        prev = parseFloat(prev);
                        if (!isNaN(prev)) {
                            if(index==3){//克数不要小数
                                return (Math.round((prev + curr) * 100) / 100)
                            }else{
                                return (Math.round((prev + curr) * 100) / 100).toFixed(2)
                            }
                            
                        } else {
                            return prev.toFixed(2);
                        }
                    }, 0);
                    if(index==3){
                        sums[index] += '';
                    }else{
                        sums[index] += '';
                    }
                } else {
                    sums[index] = '';
                }
            */});
            return sums;
        }
    },
}
</script>
<style scoped>
.watchTableKey{
    display: inline-block;
    margin:8px 10px;
    vertical-align: top;
}
.watchTableKey:first-of-type{
    text-align: left;
    width: 70px;
}
.watchTableValue{
    display: inline-block;
    margin:8px 10px;
    vertical-align: top;
}
.watchTableValue:first-of-type{
    text-align: left;
    width: 120px;
}
.watchText{
    display: inline-block;
    margin:8px 10px;
    width: 200px;
    vertical-align: top;
}
.watchText:first-of-type{
    text-align: right;
    width: 120px;
}
.watchTextLong{
    width: calc(100% - 200px);
    display: inline-block;
    margin: 8px 10px;
    vertical-align: top;

}
.mt30{
    margin-top: 30px;
}
.box-b{
	width: calc(100% - 200px);
    display: inline-block;
    margin: 8px 30px;
    vertical-align: top;
    margin-left:850px;
	}
</style>