import _this from '../main.js'
import BaseUrl from '@/utils/baseUrl';
export default {
	install: function(Vue, opt){
		Vue.prototype.screenHeight = window.screen.availHeight;
		Vue.prototype.ERRORMSG = '请求异常';
		Vue.prototype.ERROR9999 = '其他异常';
		/*	@Author：YL
		 * 	@Method：printReport(idName)
		 * 	@Function：打印表单
		 * 	@Params：div的id名
		 */
		Vue.prototype.printReport = function(idName){
			var jubuData = document.getElementById(idName).innerHTML;            
			var pwindow = window.open();     
			pwindow.document.write(jubuData);
			// pwindow.document.getElementsByTagName('body')[0].style.zoom=0.6; 
			pwindow.print();
			pwindow.close();
		};
		/*	@Author：YL
		 * 	@Method：resetSearchForm(obj,defaultValue)
		 * 	@Function：清空用户条件搜索对象
		 * 	@Params：需要清空的对象，特殊默认值(obj格式,键值对key，value分别为k，v（必须）)
		 */
		Vue.prototype.resetSearchForm = function(form,defaultValue){
			for(var key in form){
         		form[key]  = '';
			}
			if(defaultValue){
				for(var i=0; i<defaultValue.length; i++){
					for(var key in form){
						if(defaultValue[i].k == key){
							form[key] = defaultValue[i].v;
						}
					}
				}
			}
		};
		/*	@Author：YL
		 * 	@Method：clearObj(obj)
		 * 	@Function：清空对象
		 * 	@Params：需要清空的对象
		 */
		Vue.prototype.clearObj = function(form){
			for(var key in form){
         		form[key]  = '';
			}
		};
		/*	@Author：YL
		 * 	@Method：setParams(obj)
		 * 	@Function：设置ajax中的params
		 * 	@Params：需要设置为URLSearchParams格式的对象
		 */
		Vue.prototype.setParams = function(data){
			var params = new URLSearchParams();
			for(var key in data){
				if(data[key]===null) params.append(key, '');
				else params.append(key, data[key]);
			}
			return params;
		};
		/*	@Author：YL
		 * 	@Method：setEditForm(data,form)
		 * 	@Function：将返回数据赋值到form表单对象
		 * 	@Params：ajax返回的数据，form对象
		 */
		Vue.prototype.setEditForm = function(data,editForm){
			for(var key in editForm){
				for(var datakey in data){
					if(key == datakey){
						editForm[key] = data[datakey];
					}
				}
			}
		};
		/*	@Author：YL
		 * 	@Method：copyObj(obj)
		 * 	@Function：复制对象，返回复制出的对象，避免同栈，影响原对象
		 * 	@Params：原对象
		 */
		Vue.prototype.copyObj = function(obj){
			var newObj = {};
			for(var key in obj){
				newObj[key] = obj[key];
			}
			return newObj;
		};
		/*	@Author：YL
		 * 	@Method：tableRowClassName({row, rowIndex})
		 * 	@Function：给table数据每行赋值不同颜色
		 * 	@Params：{每行数据，每行数据的行号}
		 */
		Vue.prototype.tableRowClassName = function({row, rowIndex}){
			if(row.subject_max != null){
	        	if (Number(row.subject_count) > Number(row.subject_max)) {
		        	return 'yl-tr-warm';
		        } else if (Number(row.subject_count) < Number(row.subject_min)) {
		        	return 'yl-tr-danger';
		        }
		        return '';
	        }else {
	        	return '';
	        }
        }
		/*	@Author：YL
		 * 	@Method：downloadFiles(url,fileName)
		 * 	@Function：下载固定路径文件
		 * 	@Params：文件路径，文件名
		 */
		Vue.prototype.downloadFiles = function(url,fileName){
			var _url = url;
			var aTag = document.createElement('a');
			aTag.href = _url;
			aTag.download = fileName;
			aTag.click();
		}
		/*	@Author：YL
		 * 	@Method：showMoreSearch(btnId,divId)
		 * 	@Function：展开更多搜索
		 * 	@Params：展开/折叠按钮id、更多搜索容器div的id
		 */
		Vue.prototype.showMoreSearch = function(btnId,divId){
			var a = document.getElementById(divId);
			if(this.moreSearchIcon == 'el-icon-arrow-down'){
				this.moreSearchIcon = 'el-icon-arrow-up';
				if(a.children.length==1){
					a.style.height = '55px';
				}else if(a.children.length==2){
					a.style.height = '110px';
				}else if(a.children.length==3){
					a.style.height = '175px';
				}
			}else if(this.moreSearchIcon == 'el-icon-arrow-up'){
				this.moreSearchIcon = 'el-icon-arrow-down';
				a.style.height = '0px';
			}
		}
		/*	@Author：YL
		 * 	@Method：limitDate(startTime, endTime)
		 * 	@Function：开始时间不能大于结束时间
		 * 	@Params：字符串形式的开始时间与结束时间："yyyy-mm-dd"
		 */
		Vue.prototype.limitDate = function(startTime,endTime){
			var _start = new Date(startTime);
			var _end = new Date(endTime);
			if(_start - _end > 0){
				_this.$tipsDialog({
					text: '开始时间不能大于结束时间！',
					redBg: true,
					showConfirmBtn: false,
					cancelBtnText: '确 定'
				}).catch(()=>{});
				return false;
			}else {
				return true;
			}
		}
		/*	@Author：YL
		 * 	@Method：Array.clone
		 * 	@Function：克隆数组
		 * 	@Params：无参数，数组调用方法
		 */
		Array.prototype.clone = function(){
			var a = [];
			for(var i=0; i<this.length; i++){
				a.push(this[i]);
			}
			return a;
		}
		/*	@Author：YL
		 * 	@Method：getRouterPath
		 * 	@Function：获取当前页面路由
		 * 	@Params：无参数，直接调用方法
		 */
		Vue.prototype.getRouterPath = function(){
			return _this.$route.path.replace('/','')
		}
		/*	@Author：YL
		 * 	@Method：getTableSession
		 * 	@Function：当前页面是否存在缓存数据，若存在返回数据，若不存在返回false
		 * 	@Params：无参数，直接调用方法
		 */
		Vue.prototype.getTableSession = function(){
			var path = _this.getRouterPath();
			var data = JSON.parse(sessionStorage.getItem(path + 'tagsExist'));
			if(data !=null){
				return data
			}else {
				return false;
			}
		}
		/*	@Author：YL
		 * 	@Method：setTableSession
		 * 	@Function：设置页面缓存数据，用以切换模块时页面不刷新
		 * 	@Params：tableData,searchForm,limit,curPage,pagerTotalNum
		 */
		Vue.prototype.setTableSession = function(tableData,searchForm,limit,curPage,pagerTotalNum){
			var path = _this.getRouterPath();
			var data = {
				tableData: tableData,
				searchForm: searchForm,
				limit: limit,
				curPage: curPage,
				pagerTotalNum: pagerTotalNum
			}
			sessionStorage.setItem(path+'tagsExist', JSON.stringify(data));
		}
		/*	@Author：YL
		 * 	@Method：removeTableSession
		 * 	@Function：删除页面缓存数据
		 * 	@Params：无参数，直接调用方法
		 */
		Vue.prototype.removeTableSession = function(tableData,searchForm,limit,curPage,pagerTotalNum){
			var path = _this.getRouterPath();
			sessionStorage.removeItem(path+'tagsExist');
		}
		
		/*	@Author：YL
		 * 	@Method：numberToCH
		 * 	@Function：删除页面缓存数据
		 * 	@Params：无参数，直接调用方法
		 */
		var chnNumChar = ["零","一","二","三","四","五","六","七","八","九"];
		var chnUnitSection = ["","万","亿","万亿","亿亿"];
		var chnUnitChar = ["","十","百","千"];
		Vue.prototype.numberToCH = function(num){
			var unitPos = 0;  
			var strIns = '';
			var chnStr = '';
			var needZero = false;  
		   
			if(num === 0){  
				return chnNumChar[0];  
			}  
			while(num > 0){  
				var section = num % 10000;  
				if(needZero){  
					chnStr = chnNumChar[0] + chnStr;  
				}  
				strIns = sectionToChinese(section);  
				strIns += (section !== 0) ? chnUnitSection[unitPos] : chnUnitSection[0];  
				chnStr = strIns + chnStr;  
				needZero = (section < 1000) && (section > 0);  
				num = Math.floor(num / 10000);  
				unitPos++;  
			}  
			return chnStr; 
		}
		/**
		 * 构造树型结构数据
		 * @param {*} data 数据源
		 * @param {*} id id字段 默认 'id'
		 * @param {*} parentId 父节点字段 默认 'parentId'
		 * @param {*} children 孩子节点字段 默认 'children'
		 * @param {*} rootId 根Id 默认 0
		 */
		Vue.prototype.handleTree = function(data, id, parentId, children, rootId) {
			id = id || 'dept_id'
			parentId = parentId || 'parent_id'
			children = children || 'children'
			if(rootId!=null&&rootId!=undefined){
				rootId = rootId
			}else{
				rootId = Math.min.apply(Math, data.map(item => { return item[parentId] })) || 0
			}
			//对源数据深度克隆
			const cloneData = JSON.parse(JSON.stringify(data))
			//循环所有项
			const treeData = cloneData.filter(father => {
				let branchArr = cloneData.filter(child => {
					//返回每一项的子级数组
					return father[id] === child[parentId]
				});
				branchArr.length > 0 ? father.children = branchArr : '';
				//返回第一层
				return father[parentId] === rootId;
			});
			return treeData != '' ? treeData : data;
		};
		// 日期格式化
		Vue.prototype.parseTime =function(time, pattern) {
			if (arguments.length === 0 || !time) {
				return null
			}
			const format = pattern || '{y}-{m}-{d} {h}:{i}:{s}'
			let date
			if (typeof time === 'object') {
				date = time
			} else {
				if ((typeof time === 'string') && (/^[0-9]+$/.test(time))) {
					time = parseInt(time)
				} else if (typeof time === 'string') {
					time = time.replace(new RegExp(/-/gm), '/');
				}
				if ((typeof time === 'number') && (time.toString().length === 10)) {
					time = time * 1000
				}
				date = new Date(time)
			}
			const formatObj = {
				y: date.getFullYear(),
				m: date.getMonth() + 1,
				d: date.getDate(),
				h: date.getHours(),
				i: date.getMinutes(),
				s: date.getSeconds(),
				a: date.getDay()
			}
			const time_str = format.replace(/{(y|m|d|h|i|s|a)+}/g, (result, key) => {
				let value = formatObj[key]
				// Note: getDay() returns 0 on Sunday
				if (key === 'a') { return ['日', '一', '二', '三', '四', '五', '六'][value] }
				if (result.length > 0 && value < 10) {
					value = '0' + value
				}
				return value || 0
			})
			return time_str
		};
		// 表单重置
		Vue.prototype.resetForm=function(refName) {
			if (this.$refs[refName]) {
				this.$refs[refName].resetFields();
			}
		};
		/**
		 * 获取区域管理列表
		 *  @param {*} data 接口请求参数
		 */
		Vue.prototype.listArea=function(data){
			var json={}
			if(data){
				json=data;
			}
            return new Promise((resolve, reject) => {
				this.$http.post(BaseUrl.getAreaInfoList,json).then(res=>{
					resolve(res);
				}).catch(err => {
					reject(err);
				});
            })
		};
		/**
		 * 获取网页可视区body高度
		 */
		Vue.prototype.tableH=parseFloat(document.body.offsetHeight)-350;//-350 -336(计算得出)
		Vue.prototype.publicPath= process.env.BASE_URL;
		function sectionToChinese(section){
			var strIns = '', chnStr = '';
			var unitPos = 0;
			var zero = true;
			while(section > 0){
				var v = section % 10;
				if(v === 0){
					if(!zero){
						zero = true;
						chnStr = chnNumChar[v] + chnStr;
					}
				}else{
					zero = false;
					strIns = chnNumChar[v];
					strIns += chnUnitChar[unitPos];
					chnStr = strIns + chnStr;
				}
				unitPos++;
				section = Math.floor(section / 10);
			}
			return chnStr;
		}
	}
}
