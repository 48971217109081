import Cookies from 'js-cookie';

export function setName(name) {
	return Cookies.set("name", name);
}
export function getName() {
	return Cookies.get("name");
}
export function removeName() {
	return Cookies.remove("name");
}
export function setUid(uid) {
	return Cookies.set("uid", uid);
}
export function getUid() {
	return Cookies.get("uid");
}
export function removeUid() {
	return Cookies.remove("uid");
}
export function setToken(token) {
	return Cookies.set("token", token);
}
export function getToken() {
	return Cookies.get("token");
}
export function removeToken() {
	return Cookies.remove("token");
}