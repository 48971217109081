<template>
 <div id="app">
	 	<!-- <el-button @click="change('purple')">深色主题</el-button>
		<el-button @click="change('teal')">浅色主题</el-button> -->
        <router-view v-if="isRouterAlive"></router-view>
    </div>
</template>

<script>
	export default {
		name: "app",
		provide(){
			return {
				reload: this.reload
			}
		},
		data(){
			return {
				isRouterAlive: true
			}
		},
		created() {
			$("#style").attr("href","./theme/teal/index-green.css");
            localStorage.setItem("style","./theme/teal/index-green.css");
			// var style = localStorage.getItem("style");
			// if(style){
			// $("#style").attr("href",style); //实现将主题保存在内存中刷新浏览器不改变
			// }
		},
		// watch:{
		// 	black:function(val){
		// 		if(val){
		// 			document.documentElement.style="@import './styles/purple/index.css';"
		// 		}else{
		// 			document.documentElement.style="@import './styles/teal/index-green.css';"
		// 		}
		// 	}
		// },
		methods: {
			reload(){
				this.isRouterAlive = false;
				this.$nextTick(function(){
					this.isRouterAlive = true;
				})
			},
			change(val){
				if(val=='purple'){
					$("#style").attr("href","./theme/purple/index.css");
            		localStorage.setItem("style","./theme/purple/index.css");
				}else{
					$("#style").attr("href","./theme/teal/index-green.css");
            		localStorage.setItem("style","./theme/teal/index-green.css");
				}
			}
		}
	}
</script>
<style>

/* @import './styles/purple/index.css'; */
</style>
<style>

/* @import './styles/teal/index-green.css'; */
</style>


