<template>
  <div class="table salespersonPage">
    <div class="container">
      <el-form :inline="true" :model="searchForm" ref="searchForm">
        <el-form-item label="人员姓名">
          <el-input
            v-model.trim="searchForm.username"
            size="small"
            placeholder="请输入人员姓名"
          ></el-input>
        </el-form-item>
        <el-form-item label="手机号">
          <el-input
            v-model.trim="searchForm.phone"
            size="small"
            placeholder="请输入手机号"
          ></el-input>
        </el-form-item>

        <el-form-item>
          <el-button
            type="primary"
            plain
            icon="el-icon-search"
            size="small"
            @click="getData()"
            >查询
          </el-button>
          <el-button
            type="warning"
            plain
            icon="el-icon-refresh"
            size="small"
            @click="reset()"
            >重置
          </el-button>
        </el-form-item>
      </el-form>
      <el-table
        v-loading="loading"
        ref="table"
        :height="tableH + 40 + 'px'"
        :data="tableData"
        row-key="id"
        border
        default-expand-all
        size="mini"
        style="width: fit-content;"
        :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
      >
        <!-- <el-table-column prop="id" label="工号" width="100" show-overflow-tooltip></el-table-column> -->
        <el-table-column
          prop="username"
          label="人员姓名"
          width="140"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          prop="sex"
          label="性别"
          width="80"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          prop="phone"
          label="手机号"
          width="140"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          prop="score"
          label="剩余佣金"
          width="240"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          prop="brokera_time"
          label="最近核销日期"
          width="140"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column prop="" label="操作" width="100">
          <template slot-scope="scope">
            <el-button
              size="mini"
              type="warning"
              plain
              icon="el-icon-document"
              @click="getAlertData(scope.row)"
              >核销
            </el-button>
          </template>
        </el-table-column>
      </el-table>

      <!--弹出列表弹框-->
      <el-dialog
        :close-on-click-modal="false"
        :title="titleName"
        v-if="showAlertDialog"
        :visible.sync="showAlertDialog"
        width="800px"
        top="10vh"
        :append-to-body="true"
        @close="noClick"
      >
        <div style="margin:20px 0;margin-left:200px;">
          <span style="width:250px;display:inline-block;"
            >人员姓名： {{ row.username }}</span
          >
          <span style="width:250px;display:inline-block;"
            >性别： {{ row.sex }}</span
          >
        </div>
        <div style="margin:20px 0;margin-left:200px;">
          <span style="width:250px;display:inline-block;"
            >联系电话： {{ row.phone }}</span
          >
          <span style="width:250px;display:inline-block;"
            >当前佣金： {{ score }}</span
          >
        </div>
        <div style="text-align:right;margin-bottom:20px;margin-right: 10px;">
          <el-button
            type="success"
            plain
            size="small"
            icon="el-icon-plus"
            @click="addHxjl"
            >新增
          </el-button>
        </div>
        <el-table
          v-loading="alertLoading"
          ref="table"
          height="375px"
          :data="alertTable"
          row-key="id"
          border
          size="mini"
          style="width: fit-content;"
        >
          <el-table-column
            type="index"
            label="序号"
            width="80"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            prop="create_time"
            label="核销日期"
            width="220"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            prop="score"
            label="核销佣金"
            width="220"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            prop="create_user"
            label="核销人"
            width="230"
            show-overflow-tooltip
          ></el-table-column>
        </el-table>
        <!--TODO 分页-->
        <div
          class="pagination table-width-b"
          v-if="alertSearchForm.totalPage != 0"
          :style="'max-width:100%;'"
        >
          <el-pagination
            @size-change="handleSizeChange"
            :page-sizes="[10, 20, 50]"
            :page-size="alertSearchForm.limit"
            :current-page.sync="alertSearchForm.currentPage"
            @current-change="handleCurrentChange"
            layout="jumper, prev, pager, next, sizes, total"
            :total="alertSearchForm.total"
          >
          </el-pagination>
        </div>
      </el-dialog>
      <!--新增核销弹窗-->
      <el-dialog
        :close-on-click-modal="false"
        title="新增核销"
        v-if="addVisible"
        :visible.sync="addVisible"
        width="600px"
        top="20vh"
        :append-to-body="true"
      >
        <el-form
          ref="addForm"
          :model="addForm"
          label-width="100px"
          :rules="addFormRules"
        >
          <el-form-item label="当前佣金">
            {{ score }}
          </el-form-item>
          <el-form-item label="核销金额" prop="scoreLocal">
            <el-input
              v-model.trim="addForm.scoreLocal"
              type="number"
              @input="changeInput"
              size="small"
              min="0"
              placeholder="请输入核销金额"
            ></el-input>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button size="small" @click="addVisible = false"
            >取&nbsp;消</el-button
          >
          <el-button
            type="primary"
            size="small"
            @click="addBrokeraInfo('addForm')"
            :disabled="isLoading"
          >
            <span v-if="isLoading == false">确&nbsp;定</span>
            <span v-else>加载中<i class="el-icon-loading"></i></span>
          </el-button>
        </span>
      </el-dialog>
    </div>
  </div>
</template>
<script>
import BaseUrl from "@/utils/baseUrl";

export default {
  data() {
    var checkScore = (rule, value, callback) => {
      if (value - 0 > this.score - 0) {
        callback(new Error("核销金额应小于当前佣金"));
      } else {
        callback();
      }
    };
    return {
      footWidth: "0px",
      loading: true, //列表loading
      isLoading: false,
      tableData: [],
      searchForm: {
        // limit:limit,
        // currentPage:1
      },

      titleName: "",
      addVisible: false,
      addForm: {},
      addFormRules: {
        scoreLocal: [
          { required: true, message: "请输入核销金额", trigger: "blur" },
          { required: true, trigger: "blur", validator: checkScore }
        ]
      },
      row: null,
      alertSearchForm: {
        limit: 10,
        currentPage: 1,
        total: 0,
        totalPage: 0
      },
      alertLoading: false,
      alertTable: [],
      showAlertDialog: false,
      score: "" //当前佣金
    };
  },
  created() {
    this.getData();
  },
  mounted() {
    var that = this;

    that.footWidth = that.$refs.table.bodyWidth;
  },
  methods: {
    //列表数据
    getData() {
      this.loading = true;
      this.$http.post(BaseUrl.getBrokeraPageList, this.searchForm).then(res => {
        //,this.searchForm
        if (res.data.code == 0) {
          this.loading = false;
          if (res.data.data) {
            this.tableData = this.handleTree(
              res.data.data,
              "id",
              null,
              null,
              0
            );
          }
        } else {
          this.$message({
            message: res.data.msg,
            type: "warning"
          });
        }
      });
    },

    //searchForm重置
    reset() {
      this.resetSearchForm(this.searchForm);
      // this.getData();
    },
    //新增核销方法
    addBrokeraInfo() {
      this.$refs.addForm.validate(valid => {
        if (valid) {
          this.addForm.sales_id = this.row.id;
          this.addForm.ancestors = this.row.ancestors;
          let form = {
            sales_id: this.row.id,
            ancestors: this.row.ancestors,
            score: -this.addForm.scoreLocal + ""
          };
          this.isLoading = true;
          this.$http
            .post(BaseUrl.addBrokeraInfo, form)
            .then(res => {
              if (res.data.code == 0) {
                this.getSalesRecordPageList();
                this.getBrokeraById();
                this.addVisible = false;
                this.addForm = {};
                this.$message({
                  message: res.data.msg,
                  type: "success"
                });
              } else {
                this.$message({
                  message: res.data.msg,
                  type: "warning"
                });
              }
              this.isLoading = false;
            })
            .catch(() => {
              this.isLoading = false;
            });
        }
      });
    },
    //查询剩余核销金额
    getBrokeraById() {
      this.$http.post(BaseUrl.getBrokeraById, { id: this.row.id }).then(res => {
        if (res.data.code == 0) {
          this.score = res.data.data.score;
        } else {
          this.$message({
            message: res.data.msg,
            type: "warning"
          });
        }
      });
    },
    //关闭弹框
    noClick() {
      this.getData();
    },
    //
    getAlertData(row) {
      this.row = row;
      this.score = row.score;
      this.alertTable = [];
      this.alertSearchForm.currentPage = 1;
      this.alertSearchForm.id = row.id;
      this.showAlertDialog = true;
      this.titleName = "核销记录";
      this.getSalesRecordPageList();
    },
    //每页展示条数变化
    handleSizeChange(val) {
      this.alertSearchForm.limit = val;
      this.getSalesRecordPageList();
    },
    //翻页
    handleCurrentChange(val) {
      this.alertSearchForm.currentPage = val;
      this.getSalesRecordPageList();
    },
    //核销记录
    getSalesRecordPageList() {
      this.alertLoading = true;
      this.$http
        .post(BaseUrl.getSalesRecordPageList, this.alertSearchForm)
        .then(res => {
          //,this.searchForm
          if (res.data.code == 0) {
            this.alertLoading = false;
            if (res.data.data) {
              this.alertTable = res.data.data.data;
              this.alertSearchForm.total = res.data.data.total;
              this.alertSearchForm.totalPage = res.data.data.totalPage;
            }
          } else {
            this.$message({
              message: res.data.msg,
              type: "warning"
            });
          }
        });
    },
    changeInput(e) {
      if (e < 0) {
        this.$message({
          message: "核销金额大于零",
          type: "warning"
        });
        this.addForm.scoreLocal = e * -1;
      }
    },
    //打开核销记录
    addHxjl(){
      this.resetSearchForm(this.addForm);
      this.addVisible = true;

    }
  }
};
</script>
<style>
/* .columnPage .el-table__row.expanded .el-table_1_column_1  {
    font-weight: bold;
    color: #333;;
} */
.hideUpload .el-upload--picture-card {
  display: none;
}
</style>
<style scoped>
.selectWdith {
  width: 200px;
}
</style>
