import { login, logout, getInfo } from '@/api/login'
import { getToken , getUid ,setToken,setUid ,setName,removeName,removeToken,removeUid} from "@/utils/auth"; 
import { Message } from "element-ui";
import router from '@/router'
// import getToken from '@/utils/auth'

const user = {
  state: {
    token: getToken(),
    account: '',
    userInfo: {},
    real_name:'',
    uid:getUid(),
  },
  mutations: {
    SET_TOKEN: (state, token) => {
      state.token = token
    },
    SET_NAME: (state, real_name) => {
      state.real_name = real_name
    },
    SET_USERINFO: (state, userInfo) => {
      state.userInfo = userInfo
    },
    SET_UID: (state, uid) => {
      state.uid = uid
    }
  },
  actions: {
    // 登录
    Login: ({ commit }, userInfo) => {
      const account = userInfo.account.trim();
      return new Promise((resolve, reject) => {
        login(account, userInfo.password,userInfo.captchaVerification).then(response => {//userInfo.code,
          if(response){
            if(response.data.code == 0){
              const data = response.data.data
              commit('SET_TOKEN', data.token);
              commit('SET_UID', data.uid);
              commit('SET_NAME', data.real_name);
              setToken(data.token);
              setUid(data.uid);
              setName(data.real_name);

            }else{
              Message({
                    message:response.data.msg,
                    type:'error'
                })
            }
            resolve(response);
          }
        }).catch(err => {
          reject(err)
        })
      })
    },
    // 退出
    LogOut: ({ commit, state }) => {
      return new Promise((resolve, reject) => {
        logout(state.uid,state.token).then(response => {
          commit('SET_NAME', '');
          commit('SET_TOKEN', '');
          commit('SET_UID', '');
          removeToken();
          removeUid();
          removeName();
          //删除之前登录的菜单标签
          sessionStorage.removeItem('tagsList');
          sessionStorage.removeItem('tagStatus');
          router.push('/login');
          resolve()
        }).catch(err => {
        	commit('SET_NAME', '');
          commit('SET_TOKEN', '');
          commit('SET_UID', '');
          removeToken();
          removeUid();
          //删除之前登录的菜单标签
          sessionStorage.removeItem('tagsList');
					sessionStorage.removeItem('tagStatus');
        	
          reject(err)
        })
      })
    },
    // 前端退出
    FedLogOut: ({ commit }) => {
      return new Promise((resolve, reject) => {
        commit('SET_TOKEN', '');
        commit('SET_UID', '');
         removeToken();
        removeUid();
        
        //删除之前登录的菜单标签
        sessionStorage.removeItem('tagsList');
				sessionStorage.removeItem('tagStatus');
        
        router.push('/login');
        resolve()
       
      })
    }
  }
};

export default user
