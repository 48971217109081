<template>
    <div class="table">
        <div class="container" >
        	<el-form :inline="true" :model="searchForm" ref="searchForm">
            	<!--<el-form-item  label="医院名称">
                    <el-input v-model.trim="searchForm.hospital_name" size="small" placeholder="请输入医院名称"></el-input>
                </el-form-item>-->
                <el-form-item  label="科室名称">
                    <el-input v-model.trim="searchForm.dept_name" size="small" placeholder="请输入科室名称"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" plain icon="el-icon-search" size="small" @click="getData()">查询</el-button>
                    <el-button type="warning" plain icon="el-icon-refresh" size="small" @click="reset()">重置</el-button>
					<el-button type="success" plain size="small" icon="el-icon-plus" @click="showAddVisible('add')" >新增</el-button>
                </el-form-item>
            </el-form>
            <el-table v-loading="loading" ref="table" highlight-current-row @current-change="handleCurrentChangeT"   :data="tableData" border stripe size="mini" style="width: fit-content;">
                <!--<el-table-column prop="hospital_name" label="医院名称" width="300" show-overflow-tooltip></el-table-column>-->
				<el-table-column prop="dept_code" label="科室编码" width="300" show-overflow-tooltip></el-table-column>
                <el-table-column prop="dept_name" label="科室名称" width="300" show-overflow-tooltip></el-table-column>
                
                <!--<el-table-column prop="introduct" label="科室简介" width="900" show-overflow-tooltip></el-table-column>-->
                <el-table-column prop="" label="操作" width="200">
                    <template slot-scope="scope">
                        <el-button size="mini" type="primary" icon="el-icon-edit"  plain @click="showAddVisible('edit',scope.row)" :loading="scope.row.editLoading" >编辑</el-button>
						<el-button size="mini" type="danger" plain icon="el-icon-delete" @click="deleteStation(scope.row)">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <!--TODO 分页-->
            <div class="pagination table-width-b" v-if="totalPage!=0" :style="'width:'+footWidth+';max-width:100%;'">
                <el-pagination 
                    @size-change="handleSizeChange" 
                    :page-sizes="[10,20,50]"
                    :page-size="searchForm.limit"
                    :current-page.sync="searchForm.currentPage"
                    @current-change="handleCurrentChange"
                    layout="jumper, prev, pager, next, sizes, total"
                    :total="total">
                </el-pagination>
            </div>
            <!--新增/修改弹框-->
            <el-dialog :close-on-click-modal="false" :title='titleName' v-if="addVisible" :visible.sync="addVisible" width="600px" top="20vh" :append-to-body="true" :before-close="noClick">
			    <el-form  ref="addForm" :model="addForm" label-width="100px" :rules="addFormRules">
                    <!--<el-form-item label="所在地区" prop="province">
                        <el-cascader
                        	placeholder="请输入所在地区"
                        	class="selectWdith"
                        	size="small"
					        :options="options"
					        v-model="addForm.addrArry"
					        @change="handleChangeAddr"
					        :separator="' '"
					        :props="{ expandTrigger: 'hover' }"
					    >
					    </el-cascader>
                    </el-form-item>
                    <el-form-item prop="hospital_id" label="医院名称">
                        <el-select v-model="addForm.hospital_id" size="small" clearable filterable placeholder="请选择" class="selectWdith">
                            <el-option
                            v-for="item in hospitalOptions"
                            :key="item.hospital_id"
                            :label="item.hospital_name"
                            :value="item.hospital_id">
                            </el-option>
                        </el-select>
			        </el-form-item>-->
                    <el-form-item  label="科室名称" prop="dept_name">
                        <el-input v-model.trim="addForm.dept_name" size="small" placeholder="请输入科室名称"></el-input>
                    </el-form-item>
                    <el-form-item  label="科室编码" prop="dept_code">
                        <el-input v-model.trim="addForm.dept_code" size="small" placeholder="请输入科室编码"></el-input>
                    </el-form-item>
                    <el-form-item  label="科室简介">
                        <el-input v-model.trim="addForm.introduct" type="textarea" :rows="5" maxlength="500" size="small" placeholder="请输入科室简介，限制500字符以内"></el-input>
                    </el-form-item>
                </el-form>
			    <span slot="footer" class="dialog-footer">
                    <el-button size="small" @click="noClick()">取&nbsp;消</el-button>
			        <el-button type="primary" size="small" @click="saveEdit('addForm',type)">保&nbsp;存</el-button>
			    </span>
			</el-dialog>
        </div>
    </div>
</template>
<script>
import BaseUrl from '@/utils/baseUrl';
import regulars from '@/utils/regulars.js';
import options from '@/utils/country-data.js';
    export default {
        components: {
        },
        created(){
            this.getData()
        },
        data() {
        	//接种中心校验
	        var centerVerify = (rule, value, callback) => {
	        	if(value == '' || value == null){
	        		callback(new Error('请输入医院名称'));
	        	}else if(this.addForm.hospital_id == '' || this.addForm.hospital_id == null){
	                callback(new Error('未找到医院，请前往医院管理中新增!'));
	        	}else {
	        		callback();
	        	}
	        };
            return {
                footWidth:'0px',
            	selectHasId:false,//是否已经选择了
                loading:true,//列表loading
                tableData:[],
                searchForm:{
                	hospital_name:'',
                    dept_name:'',
                    limit:limit,
                    currentPage:1
                },
                total:0,
                totalPage:0,
                titleName:'',
                addVisible:false,
                addForm:{
                	hospital_id:'',
                	hospital_name:'',
                	dept_name:'',
                	dept_code:'',
                	introduct:'',
                },
                addFormRules:{
                    /*hospital_id: [
			          { required: true,  trigger: 'blur', validator:centerVerify },
			        ],*/
			        dept_name: [
	                    { required: true, message: '请输入科室名称', trigger: 'blur' }
	                ]
                },
                type:'',//add,edit
                row:null,
                deptOptions:[],//组织机构树
                deptInfo:{
                    id:'',
                    name:''
                },
                hospitalOptions:[],
                options:options,
            }
        },
        mounted(){
            //this.getTableProp();
            var that=this;
            document.getElementsByClassName('content')[0].onclick=function(){
                if(that.currentChange){
                    that.currentChange=false;
                }else{
                    that.setCurrent();
                }
            }
            that.footWidth=that.$refs.table.bodyWidth;
        },
        methods:{
            //更改所属区域
            handleChangeAddr(value){
		        this.addForm.addrArry = value;
                this.addForm.hospital_id='';
                this.hospitalOptions=[];
                this.getHospitalOptions('',this.addForm.addrArry);
            },
            getHospitalOptions(hospital_name,addrArry){
                this.$http.post(BaseUrl.getHospitalSelectByArea,{hospital_name:hospital_name,province:addrArry[0], city:addrArry[1], county:addrArry[2]}).then(res=>{
					if(res.data.code==0){
                        this.hospitalOptions = res.data.data;
                    }else{
                        this.$message({
                            message: res.data.msg,
                            type: 'warning'
                        })
                    }
		        });
            },
        	//校验更改值
	    	jiaoyanChangeVal(){
	    		if(this.selectHasId){
	    			this.addForm.hospital_id='';
	    			this.selectHasId=false;
	    		}
	    	},
	   		handleSelect(item) {
		      	this.selectHasId=true;
				//根据下拉框绑定接种中心id
		          this.addForm.hospital_id = item.hospital_id;
		          console.log("###"+item.hospital_id);
		          this.$refs.addForm.validateField("hospital_name", errMsg => {
		                if (errMsg) {
		                    console.log("校验未通过");
		                }
		            });
		      },
            setCurrent(row) {//取消单选
                //this.$refs.table.setCurrentRow(row);
            },
            handleCurrentChangeT(row,index){
                this.currentChange=true;
                this.$refs.table.setCurrentRow(row);
            },
            getData(){
                this.loading=true;//列表loading
                this.$http.post(BaseUrl.getDeptPageList,this.searchForm).then(res=>{
                    if(res.data.code==0){
                        this.tableData = res.data.data.data;
                        this.totalPage = res.data.data.totalPage;
                        this.total = res.data.data.total;
                        this.loading=false;//列表loading
                    }else{
                        this.$message({
                            message: res.data.msg,
                            type: 'warning'
                        })
                    }
                    
                })
            },
            handleSizeChange(val){
                this.searchForm.limit = val;
                this.getData();
            },
            handleCurrentChange(val){
                this.searchForm.currentPage = val;
                this.getData();
            },
            reset(){
                this.resetSearchForm(this.searchForm,[{k:'limit',v:this.searchForm.limit},{k:'currentPage',v:this.searchForm.currentPage}]);
                // this.getData();
            },
            showAddVisible(type,row){
                this.addForm={
                	hospital_id:'',
                	hospital_name:'',
                	dept_name:'',
                	introduct:'',
                };
                this.deptInfo={
                    id:'',
                    name:''//row.dept_name
                }
                this.type=type;
                this.hospitalOptions=[];
                this.addForm.addrArry=[];
                if(type=='add'){
                    this.titleName="新增科室信息";
                    this.addVisible=true;
                }else{
                    this.titleName="修改科室信息";
                    this.row=row;
                    this.$set(row,'editLoading',true);
                    this.$http.post(BaseUrl.getDeptById,{id:row.id}).then(res=>{
                        if(res.data.code==0){
                            this.addForm=res.data.data;
                            this.addForm.addrArry=[res.data.data.province,res.data.data.city,res.data.data.county];
                            if(res.data.data.province){
                                this.getHospitalOptions('',this.addForm.addrArry);
                            }
                            
                            this.addVisible=true;
                            this.$set(row,'editLoading',false);
                        }else{
                            this.$message({
                                message: res.data.msg,
                                type: 'warning'
                            })
                        }
                        
                    })
                    
                }
                
            },
            //保存新增/修改
            saveEdit(form,type){
                this.$refs[form].validate((valid)=>{
                    if(valid){
                        if(type=='add'){
                            this.$http.post(BaseUrl.addDeptInfo,this[form]).then(res=>{
                                if(res.data.code==0){
                                    this.titleName='';
                                    this.getData();
                                    this.addVisible=false;
                                    this.$message({
                                        message: res.data.msg,
                                        type: 'success'
                                    })
                                }else{
                                    this.$message({
                                        message: res.data.msg,
                                        type: 'warning'
                                    })
                                }
                            })
                        }else{
                            this[form].id=this.row.id;
                            this.$http.post(BaseUrl.saveDeptById,this[form]).then(res=>{
                                if(res.data.code==0){
                                    this.titleName='';
                                    this.getData();
                                    this.addVisible=false;
                                    this.$message({
                                        message: res.data.msg,
                                        type: 'success'
                                    })
                                }else{
                                    this.$message({
                                        message: res.data.msg,
                                        type: 'warning'
                                    })
                                }
                            })
                        }
                        
                        
                    }
                })

            },
            deleteStation(row){
                var that=this;
                this.$confirm('是否确认删除科室"' + row.dept_name + '"的数据项?', "警告", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning"
                }).then(function() {
                    that.$http.post(BaseUrl.delDept,{id:row.id}).then(res=>{
                        if(res.data.code==0){
                            that.getData();
                            that.$message({
                                message:'删除成功',
                                type:'success'
                            })
                        }else{
                            that.$message({
                                message: res.data.msg,
                                type: 'warning'
                            })
                        }
                        
                    })
                    
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消删除'
                    });          
                });
            },
            noClick(){
                this.getData();
                this.addVisible=false;
            },
            
            getFatherAncestors(val){}
            
        }
        
    }
</script>
<style scoped>
.selectWdith {
    width : 460px;
}
</style>