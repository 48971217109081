import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./plugins/element.js";
import "./plugins/axios";
import "./plugins/element.js";
import CommonJs from './utils'
import VDistpicker from 'v-distpicker';
import $ from 'jquery';
import BaiduMap from 'vue-baidu-map'

import Print from 'vue-print-nb'
Vue.use(Print)
//按钮权限
import permission from './utils/permission'
import "@/permission.js"; // permission control
Vue.component('v-distpicker', VDistpicker)


// 富文本组件 
import Editor from "./components/Editor/index"
Vue.component('Editor', Editor)


// 下面的依赖可以按照需求选择性加载
// import VCharts from 'v-charts'
// import 'echarts/lib/component/markLine';
// import 'echarts/lib/component/markPoint';
// import 'echarts/lib/component/markArea';
// import 'echarts/lib/component/visualMap';
// import 'echarts/lib/component/dataZoom';
// import 'echarts/lib/component/toolbox';
// import 'echarts/lib/component/title';
// import 'zrender/lib/svg/svg';
// import 'v-charts/lib/style.css';

//引入echarts
// import echarts from 'echarts'
// Vue.prototype.$echarts = echarts

Vue.config.productionTip = false;

Vue.use(BaiduMap, {
  // ak 是在百度地图开发者平台申请的密钥 详见 http://lbsyun.baidu.com/apiconsole/key */
  ak: '0GZapQKDDSPUwlDm0Fev35g4ATo9T1u9'
})
Vue.use(permission);//权限
Vue.use(CommonJs);
// Vue.use(VCharts);
var vue = new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");

export default vue