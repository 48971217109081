<template>
    <div class="table">
        <div class="container"  >
            <!--搜索框-->
        	<el-form :inline="true" :model="searchForm" ref="searchForm">
                <el-form-item prop="recipe_source" label="订单来源">
                    	<el-select v-model="searchForm.recipe_source" placeholder="请选择" size="small">
                            <el-option label="选择全部" value></el-option>
		                    <el-option
		                    v-for="item in companyOptions"
		                    :key="item.id"
		                    :label="item.plat_name"
		                    :value="item.id">
		                    </el-option>
		                </el-select>
			        </el-form-item>
               
                <el-form-item prop="send_state" label="下发状态">
                    <el-select v-model="searchForm.send_state" placeholder="请选择" size="small" style="width:160px">
                        <el-option label="选择全部" value></el-option>
                        <el-option
                        v-for="item in stateOptions"
                        :key="item.typeValue"
                        :label="item.typeLabel"
                        :value="item.typeValue">
                        </el-option>
                    </el-select>
			    </el-form-item>
                <el-form-item  label="订单编号">
                    <el-input v-model.trim="searchForm.recipe_id" style="width:150px" size="small" placeholder="请输入订单编号"></el-input>
                </el-form-item>
                <el-form-item  label="患者姓名">
                    <el-input v-model.trim="searchForm.pati_name" style="width:150px" size="small" placeholder="请输入患者姓名"></el-input>
                </el-form-item>
                <el-form-item prop="recipe_time" label="采集时间">
                    <dateChoose v-model="searchForm.time" :time="searchForm.time"></dateChoose>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" plain icon="el-icon-search" size="small" @click="getData()">查询</el-button>
                    <el-button type="warning" plain icon="el-icon-refresh" size="small" @click="reset()">重置</el-button>
                    
                </el-form-item>
            </el-form>
            
            <el-table v-loading="loading" ref="table" highlight-current-row @current-change="handleCurrentChangeT"   :data="tableData" border stripe size="mini" style="width: fit-content;">
                <el-table-column prop="recipe_source" label="订单来源" width="100" show-overflow-tooltip></el-table-column>
                <el-table-column prop="recipe_id" label="订单编号" width="180" show-overflow-tooltip></el-table-column>
                <el-table-column prop="recipe_time" label="采集时间" width="180" show-overflow-tooltip></el-table-column>
				<el-table-column prop="merge_num" label="商户订单号" width="200" show-overflow-tooltip ></el-table-column>
                <el-table-column prop="pati_name" label="患者姓名" width="80" show-overflow-tooltip></el-table-column>
                <el-table-column prop="age" label="年龄" width="80" show-overflow-tooltip></el-table-column>
                <el-table-column prop="pati_sex" label="性别" width="80" show-overflow-tooltip></el-table-column>
				<el-table-column prop="person" label="收货人" width="80" show-overflow-tooltip></el-table-column>
				<el-table-column prop="phone" label="收货电话" width="130" show-overflow-tooltip></el-table-column>
				<el-table-column prop="address" label="收货地址" width="250" show-overflow-tooltip></el-table-column>
                <el-table-column prop="send_state" label="下发状态" width="100" show-overflow-tooltip></el-table-column>

                <el-table-column prop="" label="操作" :width="tableBtnWidth">
                    <template slot-scope="scope">
                    	<el-button size="mini" type="warning" icon="el-icon-view"  plain @click="detailVisible(scope.row)" >订单详情</el-button>
                        <el-button size="mini" type="primary" icon="el-icon-edit"  plain @click="showAddVisible(scope.row)" :loading="scope.row.editLoading">下发</el-button>
                        
                    </template>
                </el-table-column>
            </el-table>
            <!--TODO 分页-->
            <div class="pagination table-width-b" v-if="totalPage!=0" :style="'width:'+footWidth+';max-width:100%;'">
                <el-pagination 
                    @size-change="handleSizeChange" 
                    :page-sizes="[10,20,50]"
                    :page-size="searchForm.limit"
                    :current-page.sync="searchForm.currentPage"
                    @current-change="handleCurrentChange"
                    layout="jumper, prev, pager, next, sizes, total"
                    :total="total">
                </el-pagination>
            </div>
			<!-- 订单明细弹框 -->
            <detail-info v-if="detVisible" ref="detailInfo" :titleName="titleName" 
                :seeInfo="seeInfo" :openRecipe="openRecipe" :orders="orders" :showOrder="showOrder">
            </detail-info>
            <!--打印-->
            <print v-if="showPrint" :tableData="printData" @reload="reload" :type="printType" v-model="showPrint"></print>
        </div>
    </div>
</template>
<script>
import BaseUrl from '@/utils/baseUrl';
import detailInfo from "@/components/order-info";
import print from "@/components/print"
import dateChoose from "@/components/date-choose"
import moment from 'moment'
    export default {
        components: {
            detailInfo,print,dateChoose
        },
        created(){
            
            
            
        },
        data() {
            return {
                footWidth:'0px',
                loading:true,//列表loading
                tableData:[],
                searchForm:{
                    plat_id:'',
                    order_num:'',
                    order_state:'',
                    print_state:'',
                    limit:limit,
                    currentPage:1,
                    start_time:'',
                    end_time:'',
                    see_name:''
                },
                total:0,
                totalPage:0,
                titleName:'',
                addVisible:false,
                detVisible:false,
                deviceSpotList:[],//抽检结果列表
                companyOptions:[],
                stateOptions:[
                    {typeLabel:'未下发',typeValue:'0'},
                    {typeLabel:'已下发',typeValue:'1'}
                ],
                addForm:{
                	phy_num:'',
                	phy_company:'',
                	deliver_time:'',
                	id:'',
                },
                addFormRules:{
                    phy_num:[
                        { required: true, message: "发货单号不能为空", trigger: "blur" }
                    ],
                    phy_company:[
                        { required: true, message: "物流公司不能为空", trigger: "blur" }
                    ]
                },
                type:'',//add,edit
                row:null,
                isHisPage:'',
                deptOptions:[],//组织机构树
                deptInfo:{
                    id:'',
                    name:''
                },
                seeInfo:{},
                openRecipe:{},
                showOrder:true,
                orders:[],
                showPrint:false,
                printData:null,
                tableBtnWidth:'250',//页面链接最后一个字段
                getDataUrl:'',//请求地址
                timer:null,//定时器
                waitOlderTotal:0,//未处理订单数
                openVideo:false,//是否开启语音播报
                speech: null,
                showVideoBox:true,
                printOptions:[
                    {label:'已打印',value:'1'},
                    {label:'未打印',value:'0'},
                ]
            }
        },
        mounted(){
            //this.getTableProp();
            var that=this;
            that.orderDictSelect();
            that.footWidth=that.$refs.table.bodyWidth;
            this.searchForm.start_time=this.searchForm.time[0];
            this.searchForm.end_time=this.searchForm.time[1];
            this.getData();
            
            if(this.$route.name=='wait'){//待支付页面每五分钟刷新一下未处理总数
                this.getOrderListCountOfWait();
                clearInterval(this.timer);
                this.timer=setInterval(()=>{
                    this.getOrderListCountOfWait();
                },5*60*1000)//5*60*1000

                //语音播报初始化
                this.speech = window.speechSynthesis;
            }
            
        },
        beforeDestroy(){
        },
        watch:{
            'searchForm.time':{
                handler(newName,oldName){
                    this.searchForm.start_time=newName[0];
                    this.searchForm.end_time=newName[1];
                    //执行代码
                },
                deep:true 
            }
        },
        methods:{
            // //获取未处理总数
            getOrderListCountOfWait(){
                this.$http.post(BaseUrl.getOrderListCountOfWait, this.searchForm).then(res=>{//,this.searchForm
                    if(res.data.code==0){
                        this.waitOlderTotal = res.data.data;
                        this.speakFn();
                    }else{
                        this.$message({
                            message: res.data.msg,
                            type: 'warning'
                        })
                    }
                    
                })
            },
            orderDictSelect(){
                this.$http.post(BaseUrl.orderDictSelect).then(res=>{
					if(res.data.code==0){
                        this.companyOptions = res.data.data;
                    }else{
                        this.$message({
                            message: res.data.msg,
                            type: 'warning'
                        })
                    }
		        });
            },
            handleCurrentChangeT(row,index){
                this.currentChange=true;
                this.$refs.table.setCurrentRow(row);
            },
            getData(){
                this.loading=true;//列表loading
                this.$http.post(BaseUrl.getOrderPageListSend,this.searchForm).then(res=>{
                    if(res.data.code==0){
                        this.tableData = res.data.data.data;
                        this.totalPage = res.data.data.totalPage;
                        this.total = res.data.data.total;
                        this.loading=false;//列表loading
                    }else{
                        this.$message({
                            message: res.data.msg,
                            type: 'warning'
                        })
                    }
                    
                })
            },
            handleSizeChange(val){
                this.searchForm.limit = val;
                this.getData();
            },
            handleCurrentChange(val){
                this.searchForm.currentPage = val;
                this.getData();
            },
            reset(){
                this.resetSearchForm(this.searchForm,[
                    {k:'limit',v:this.searchForm.limit},
                    {k:'currentPage',v:this.searchForm.currentPage},
                    {k:'start_time',v:moment().subtract(7, 'days').format('YYYY-MM-DD')},
                    {k:'end_time',v:moment().format('YYYY-MM-DD')},
                    {k:'time',v:[moment().subtract(7, 'days').format('YYYY-MM-DD'),moment().format('YYYY-MM-DD')]}
                ]);
                // this.getData();
            },
            detailVisible(row){
                var that = this;
                that.titleName="订单明细";
                //药品详情
                that.$http.post(BaseUrl.getOrderGetherById, {id:row.id}).then(res=>{
                    if(res.data.code==0){
                        //that.seeInfo=res.data.data.data;
                        that.openRecipe=res.data.data;
                        that.detVisible = true;
                        that.$nextTick(() => {
                            that.$refs.detailInfo.init();
                        });
                    }else{
                        that.$message({
                            message: res.data.msg,
                            type: 'error'
                        });
                    }
                })
            },
            showAddVisible(row){
                this.$http.post(BaseUrl.sendPrintOrder, {id:row.id}).then(res=>{
                    if(res.data.code==0){
                        this.$message({
                                        message: res.data.msg,
                                        type: 'success'
                                    })
                    }else{
                        this.$message({
                            message: res.data.msg,
                            type: 'error'
                        });
                    }
                })
            },
            //保存新增/修改
            saveEdit(form,type){ 
                this.$refs[form].validate((valid)=>{
                    if(valid){
                        delete this[form].dept_name;
                        this.$http.post(BaseUrl.saveOrderById,this[form]).then(res=>{
                                if(res.data.code==0){
                                    this.titleName='';
                                    this.searchData();
                                    this.addVisible=false;
                                    this.$message({
                                        message: res.data.msg,
                                        type: 'success'
                                    })
                                }else{
                                    this.$message({
                                        message: res.data.msg,
                                        type: 'warning'
                                    })
                                }
                            })
                    }
                })

            },
            noClick(){
                this.searchData();
                this.addVisible=false;
            },
            noClick1(){
                this.getData();
                this.detVisible=false;
            },
            //打印
            showPrintFn(row,type){
                this.printType=type;
                
                this.$http.post(BaseUrl.printOrderInfo,{seeId:row.see_id,openId:row.openId}).then(res=>{
                    if(res.data.code==0){
                        this.printData = res.data.data;
                        this.printData.rowId=row.id;
                        this.showPrint=true;
                        // this.showPrint=false;
                    }else{
                        this.$message({
                            message: res.data.msg,
                            type: 'warning'
                        })
                    }
                })

            },
            reload(val){
                console.log('reload',val)
                if(val){
                    this.searchData();
                }
            },
            getPrintOrderInfo(){
                console.log(this.row)
                
            },
        }
        
    }
</script>
<style lang="scss" scoped>
.tipsCardBox{
    position: fixed;
    top:120px;
    right:20px;
    width: 200px;
    z-index:9;
    .tit{
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

}
</style>