<template>
    <div class="table">
        <div class="container"  >
            <!--搜索框-->
        	<el-form :inline="true" :model="searchForm" ref="searchForm">
                <el-form-item  label="操作人">
                    <el-input v-model.trim="searchForm.username" style="width:150px;" size="small" placeholder="请输入操作人"></el-input>
                </el-form-item>
                <el-form-item  label="操作内容">
                    <el-input v-model.trim="searchForm.operation" style="width:150px;" size="small" placeholder="请输入操作内容"></el-input>
                </el-form-item>
                <el-form-item label="操作时间">
                   <el-date-picker 
                        style="width:150px;"
                        v-model="searchForm.start_time" 
                        type="date" size="small" 
                        placeholder="开始时间" 
                        format="yyyy-MM-dd"
                        value-format="yyyy-MM-dd"
                        :picker-options="pickerOptionsStart"
                        >
                    </el-date-picker>
                    <span>-</span>
                    <el-date-picker 
                        style="width:150px;"
                        v-model="searchForm.end_time" 
                        type="date" size="small" 
                        placeholder="结束时间" 
                        format="yyyy-MM-dd"
                        value-format="yyyy-MM-dd"
                        :picker-options="pickerOptionsEnd"
                        >
                    </el-date-picker>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" plain icon="el-icon-search" size="small" @click="getData()">查询</el-button>
                    <el-button type="warning" plain icon="el-icon-refresh" size="small" @click="reset()">重置</el-button>
                </el-form-item>
            </el-form>
            <el-table v-loading="loading" ref="table" highlight-current-row @current-change="handleCurrentChangeT"   :data="tableData" border stripe size="mini" style="width: fit-content;"><!--style="width: fit-content;"-->
                <el-table-column type="index" label="序号" width="50" align="center"></el-table-column>
                <el-table-column prop="username" label="操作人" width="150" show-overflow-tooltip></el-table-column>
                <el-table-column prop="operation" label="操作内容" width="300" show-overflow-tooltip></el-table-column>
                <el-table-column prop="time" label="执行时长(ms)" width="110" show-overflow-tooltip></el-table-column>
                <el-table-column prop="ip" label="请求IP" width="200" show-overflow-tooltip></el-table-column>
                <el-table-column prop="create_time" label="操作时间" width="200" show-overflow-tooltip></el-table-column>
            </el-table>
            <!--TODO 分页-->
            <div class="pagination table-width-b" v-if="totalPage!=0" style="width:1025px;max-width:100%;">
                <el-pagination 
                    @size-change="handleSizeChange" 
                    :page-sizes="[10,20,50]"
                    :page-size="searchForm.limit"
                    :current-page.sync="searchForm.currentPage"
                    @current-change="handleCurrentChange"
                    layout="jumper, prev, pager, next, sizes, total"
                    :total="total">
                </el-pagination>
            </div>
        </div>
    </div>
</template>
<script>
import BaseUrl from '@/utils/baseUrl';
import moment from "moment"
import regulars from '@/utils/regulars.js';

    export default {
        components: {
            
        },
        
        data() {
            
            return {
                pickerOptionsStart: {
                    disabledDate: time => {
                        let endDateVal = this.searchForm.end_time;
                        if (endDateVal) {
                            return time.getTime() > new Date(endDateVal).getTime();
                        }
                    }
                },
                pickerOptionsEnd: {
                    disabledDate: time => {
                        let beginDateVal = this.searchForm.start_time;
                        if (beginDateVal) {
                        return (time.getTime()<new Date(beginDateVal).getTime()-1*24*60*60*1000);
                        }
                    }
                },
                loading:true,
                tableData:[],
                searchForm:{
                    username:'',
                    operation:'',
                    start_time:'',//moment().startOf('day').format('YYYY-MM-DD'),
                    end_time:'',//moment().startOf('day').format('YYYY-MM-DD'),
                    limit:limit,
                    currentPage:1
                },
                total:0,
                totalPage:0,
            }
        },
        created(){
            this.getData()
        },
        mounted(){
            //this.getTableProp();
            var that=this;
            document.getElementsByClassName('content')[0].onclick=function(){
                if(that.currentChange){
                    that.currentChange=false;
                }else{
                    that.setCurrent();
                }
                
            }
        },
        methods:{
            setCurrent(row) {//取消单选
                this.$refs.table.setCurrentRow(row);
            },
            handleCurrentChangeT(row,index){
                this.currentChange=true;
                this.$refs.table.setCurrentRow(row);
            },
            getData(){
                this.loading=true;//列表loading
                this.$http.post(BaseUrl.getLogListPage,this.searchForm).then(res=>{
                    if(res.data.code==0){
                        for(let item of res.data.data.data){
                            item.create_time=moment(item.createDate).format('YYYY-MM-DD HH:mm:ss')
                        }
                        this.tableData = res.data.data.data;
                        this.totalPage = res.data.data.totalPage;
                        this.total = res.data.data.total;
                        this.loading=false;//列表loading
                    }else{
                        this.$message({
                            message: res.data.msg,
                            type: 'warning'
                        })
                    }
                    
                })
            },
            handleSizeChange(val){
                this.searchForm.limit = val;
                this.getData();
            },
            handleCurrentChange(val){
                this.searchForm.currentPage = val;
                this.getData();
            },
            reset(){
                
                this.resetSearchForm(this.searchForm,[{k:'limit',v:this.searchForm.limit},{k:'currentPage',v:this.searchForm.currentPage}]);
            },
            
        }
        
    }
</script>