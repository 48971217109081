<template>
  <div>
    <div id="myBody" class="content">
      <div class="left2" v-show="!isCollapse">
        <i class="jiantou el-icon-caret-right" v-show="!isCollapse" @click="isCollapse=!isCollapse"></i>
      </div>
      <div class="left" v-show="isCollapse">
        <i class="jiantou el-icon-caret-left" v-show="isCollapse" @click="isCollapse=!isCollapse"></i>
      </div>
      <el-menu default-active="1" background-color="#ffffff" text-color="#000" active-text-color="#46a0fc" v-show="isCollapse" class="el-menu-vertical-demo">
        <el-menu-item v-for="item in options" :key="item.index" :index="item.index" @click="toPage(item.url)">
          <i class="el-icon-guide"></i> <span slot="title">{{ item.name }}</span>
        </el-menu-item>
      </el-menu>
      <iframe :src="url" allowTransparency="true" :class="{ 'iframeR': isCollapse == true, 'iframeR2': isCollapse == false }"></iframe>
    </div>
  </div>
</template>

<script>
export default {
  name: "Work",
  data() {
    return {
      options: [
        {
          index: '1',
          name: '按医生统计',
          url: '/aystj'
        },
        {
          index: '2',
          name: '按药房统计',
          url: '/ayftj'
        },
        {
          index: '3',
          name: '按剂型统计',
          url: '/ajxtj'
        },
        {
          index: '4',
          name: '按业务员统计',
          url: '/aywtj'
        },
      ],
      isCollapse: true,
      url: '/aystj',
      pathUrl: ''
    };
  },
  created() {
    this.pathUrl = window.location.href.split('#')[0] + '#';
    this.url = this.pathUrl + this.url;
  },
  methods: {
    toPage(url) {
      this.url = this.pathUrl + url;
    },
  }
};
</script>

<style scoped>
.el-menu-vertical-demo:not(.el-menu--collapse) {
  width: 220px;
  height: calc(100vh - 85px);
}

.el-menu-vertical-demo {
  float: left;
  width: 220px;
  height: calc(100vh - 85px);
}

.content {
  width: 100%;
  padding: 0px;
  display: flex;
  height: calc(100vh - 85px);
  float: left;

}

.iframeR {
  width: calc(100% - 220px);
  float: left;
  height: 100%;
  border: 1px solid transparent;
}

.iframeR2 {
  width: calc(100% - 15px);
  margin-left: 15px ;
  float: left;
  height: 100%;
  border: 1px solid transparent;
}

/deep/ .el-menu-item.is-active {
  border-right: 2px solid #1890ff;
}

.jiantou {
  color: #1890ff;
  font-size: 15px;
  height: 100%;
  text-align: center;
  line-height: 884px;
  position: relative;
}

.left,
.left2 {
  height: 100%;
  width: 15px;
  background-color: rgb(232, 244, 255);
  position: absolute;
  z-index: 99999999;
  cursor: pointer;
}

.left {
  margin-left: 208px;
}

/deep/ .el-menu-item:hover {
  background-color: #e8f4ff !important;
}
</style>
