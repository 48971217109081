<template>
    <div class="table">
        <div class="container"  >
        	<el-form :inline="true" :model="searchForm" ref="searchForm">
        		<el-form-item label="所属地区" prop="province">
                        <el-cascader
                        	placeholder="请输入所属地区"
                        	style= "width: 200px;"
                        	size="small"
					        :options="options"
					        v-model="searchForm.addrArry"
					        @change="handleChange"
					        :separator="' '"
					        :props="{ expandTrigger: 'hover',checkStrictly:'true' }"
					    >
					    </el-cascader>
                    </el-form-item>
            	<el-form-item  label="医院名称">
                    <el-input v-model.trim="searchForm.hospitalName" size="small" placeholder="请输入医院名称"></el-input>
               </el-form-item>
               <el-form-item label="医院类型" prop="type">
					<el-select v-model.trim="searchForm.type" placeholder="请选择医院类型" size="small" style= "width: 190px;">
						<el-option
							v-for="(item,index) in types"
							:key="index"
							:label="item.name"
							:value="item.id">
						</el-option>
					</el-select>
			    </el-form-item>
                <el-form-item>
                    <el-button type="primary" plain icon="el-icon-search" size="small" @click="getData()">查询</el-button>
                    <el-button type="warning" plain icon="el-icon-refresh" size="small" @click="reset()">重置</el-button>
					<el-button type="success" plain size="small" icon="el-icon-plus" @click="showAddVisible('add')" >新增</el-button>
                </el-form-item>
            </el-form>
            <el-table v-loading="loading" ref="table" highlight-current-row @current-change="handleCurrentChangeT"   :data="tableData" border stripe size="mini" style="width: fit-content;">
                <el-table-column prop="province" label="所属区域" width="200" show-overflow-tooltip></el-table-column>
                <el-table-column prop="hospitalName" label="医院名称" width="240" show-overflow-tooltip></el-table-column>
                <el-table-column prop="type" label="医院类型" width="240" show-overflow-tooltip></el-table-column>
                <el-table-column prop="level" label="医院等级" width="240" show-overflow-tooltip></el-table-column>
                <el-table-column prop="address" label="医院地点" width="300" show-overflow-tooltip></el-table-column>
                <el-table-column prop="linkman" label="联系人" width="100" show-overflow-tooltip></el-table-column>
                <el-table-column prop="phone" label="联系电话" width="100" show-overflow-tooltip></el-table-column>
                <el-table-column prop="" label="操作" width="200">
                    <template slot-scope="scope">
                        <el-button size="mini" type="primary" icon="el-icon-edit"  plain @click="showAddVisible('edit',scope.row)" :loading="scope.row.editLoading" >编辑</el-button>
						<el-button size="mini" type="danger" plain icon="el-icon-delete" @click="deleteStation(scope.row)">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <!--TODO 分页-->
            <div class="pagination table-width-b" v-if="totalPage!=0" :style="'width:'+footWidth+';max-width:100%;'">
                <el-pagination 
                    @size-change="handleSizeChange" 
                    :page-sizes="[10,20,50]"
                    :page-size="searchForm.limit"
                    :current-page.sync="searchForm.currentPage"
                    @current-change="handleCurrentChange"
                    layout="jumper, prev, pager, next, sizes, total"
                    :total="total">
                </el-pagination>
            </div>
            <!--新增/修改弹框-->
            <el-dialog :close-on-click-modal="false" :title='titleName' v-if="addVisible" :visible.sync="addVisible" width="700px" top="20vh" :append-to-body="true" :before-close="noClick">
			    <el-form :inline="true"  ref="addForm" :model="addForm" label-width="100px" :rules="addFormRules">
                    <el-form-item  label="医院名称" prop="hospitalName">
                        <el-input v-model.trim="addForm.hospitalName" size="small" placeholder="请输入医院名称"></el-input>
                    </el-form-item>
                    <el-form-item  label="医院编码" prop="hospitalCode">
                        <el-input v-model.trim="addForm.hospitalCode" size="small" placeholder="请输入医院编码"></el-input>
                    </el-form-item>
                    <el-form-item label="所在地区" prop="province">
                        <el-cascader
                        	placeholder="请输入所在地区"
                        	style= "width: 200px;"
                        	size="small"
					        :options="options"
					        v-model="addForm.addrArry"
					        @change="handleChange"
					        :separator="' '"
					        :props="{ expandTrigger: 'hover' }"
					    >
					    </el-cascader>
                    </el-form-item>
                    <el-form-item  label="详细地址" prop="address">
                        <el-input v-model.trim="addForm.address" size="small" placeholder="请输入详细地址"></el-input>
                    </el-form-item>
                    <!--<el-form-item label="详细地址" prop="address">
                        <el-autocomplete
                            @focus="provinceVerify()"
                            size="small"
                            style= "width: 200px;"
                            class="inline-input"
                            v-model="addForm.address"
                            :fetch-suggestions="querySearch"
                            placeholder="请输入详细地址"
                            :trigger-on-focus="false"
                            @select="handleSelect"
                        ></el-autocomplete>
                    </el-form-item>-->
                    <el-form-item  label="联系人" prop="linkman">
                        <el-input v-model.trim="addForm.linkman" size="small" placeholder="请输入联系人"></el-input>
                    </el-form-item>
                    <el-form-item  label="联系电话" prop="phone">
                        <el-input v-model.trim="addForm.phone" size="small" placeholder="请输入联系电话"></el-input>
                    </el-form-item>
                    <el-form-item label="医院类型" prop="type">
						<el-select v-model.trim="addForm.type" placeholder="请选择医院类型" size="small" style= "width: 200px;">
							<el-option
								v-for="(item,index) in types"
								:key="index"
								:label="item.name"
								:value="item.id">
							</el-option>
						</el-select>
				    </el-form-item>
				    <el-form-item prop="level" label="医院等级">
                    	<el-select v-model="addForm.level" placeholder="请选择医院等级" size="small" filterable style= "width: 200px;">
		                    <el-option
		                    v-for="item in levels"
		                    :key="item.typeValue"
		                    :label="item.typeLabel"
		                    :value="item.typeValue">
		                    </el-option>
		                </el-select>
			        </el-form-item>
                    <!--<el-form-item  label="医院等级">
                        <el-input v-model.trim="addForm.level" size="small" placeholder="请输入医院等级"></el-input>
                    </el-form-item>-->
                    <el-form-item  label="医院简介">
                        <el-input v-model.trim="addForm.introduction" type="textarea" :rows="5" maxlength="500" size="small" placeholder="请输入医院简介，限制500字符以内" style= "width: 510px;"></el-input>
                    </el-form-item>
                </el-form>
			    <span slot="footer" class="dialog-footer">
                    <el-button size="small" @click="noClick()">取&nbsp;消</el-button>
			        <el-button type="primary" size="small" @click="saveEdit('addForm',type)">保&nbsp;存</el-button>
			    </span>
			</el-dialog>
        </div>
    </div>
</template>
<script>
import BaseUrl from '@/utils/baseUrl';
import options from '@/utils/country-data.js';
import regulars from '@/utils/regulars.js';

    export default {
        components: {
        },
        created(){
            this.getData()
        },
        data() {
        	////校验手机号，号段主要有(不包括上网卡)：130~139、150~153，155~159，180~189、170~171、    176~178。14号段为上网卡专属号段
			const telReg = /^((0\d{2,3}-\d{7,8})|(((13[0-9])|(14[0-9])|(15[0-9])|(16[0-9])|(17[0-9])|(18[0-9])|(19[0-9]))\d{8}))|400[0-9]{7}$/;
	    	var telValue = regulars.checkRegular(telReg,'请输入联系电话','联系方式输入不合法!');
        	//联系人校验
	        const personReg = /^[a-z0-9A-Z_\u4e00-\u9fa5]+$/;
	        var personValue = regulars.checkRegular(personReg,'请输入联系人','允许输入汉字、字母、数字、下划线');
	        //详细地址校验
	        var addressVerify = (rule, value, callback) => {
	        	if(value == ''){
	        		callback(new Error('请输入详细地址'));
	        	}else if(this.lng == '' || this.lat == ''){
	                callback(new Error('请从下拉框中选择详细地址!'));
	        	}else {
	        		callback();
	        	}
	        };
            return {
                footWidth:'0px',
            	options:options,   //存放城市数据
                loading:true,//列表loading
                tableData:[],
                searchForm:{
                	hospital_name:'',
                    doctorName:'',
                    type:'',
                    addrArry:[],
                    limit:limit,
                    currentPage:1
                },
                total:0,
                totalPage:0,
                titleName:'',
                types:[
	            	{name:'公立',id:'公立'},
	            	{name:'私立',id:'私立'},
				],
				levels:[//医院等级
                    {
                        typeValue:'',
                        typeLabel:''
                    }
                ],
                addVisible:false,
                addForm:{
                	hospitalName:'',
                	hospitalCode:'',
                	province:'',
	                city:'',
	                county:'',
	                addrArry:[],
	                address:'',
	                level:'',
	                linkman:'',
	                phone:'',
	                introduction:''
                },
                addFormRules:{
                	hospitalName: [
	                    { required: true, message: '请输入医院名称', trigger: 'blur' }
	                ],
                	hospitalCode: [
	                    { required: true, message: '请输入医院编码', trigger: 'blur' }
	                ],
	                province: [
	                    { required: true, message: '请输入所在地区', trigger: 'blur' }
	                ],
	                address: [
	                    { required: true, trigger: 'blur' ,validator:addressVerify}
	                ],
                    linkman: [
	                    { required: true, trigger: 'blur', validator:personValue}
	                ],
	                phone: [
	                    { required: true,  trigger: 'blur',validator:telValue }
	                ],
                },
                type:'',//add,edit
                row:null,
                deptOptions:[],//组织机构树
                
            }
        },
        mounted(){
            //this.getTableProp();
            var that=this;
            document.getElementsByClassName('content')[0].onclick=function(){
                if(that.currentChange){
                    that.currentChange=false;
                }else{
                    that.setCurrent();
                }
                
            }
            that.footWidth=that.$refs.table.bodyWidth;
        },
        methods:{
        	//详细地址获取焦点时 所在地区不能为空
	        provinceVerify() {
	        	this.$refs['addForm'].validateField('province', (error) => {
	                if(error){
	                    console.log('没选择所在地区')
	                }
	            })
	        },
            setCurrent(row) {//取消单选
                //this.$refs.table.setCurrentRow(row);
            },
            handleCurrentChangeT(row,index){
                this.currentChange=true;
                this.$refs.table.setCurrentRow(row);
            },
            getData(){
                this.loading=true;//列表loading
                if(this.searchForm.addrArry.length != 0){
	       			this.searchForm.province = this.searchForm.addrArry[0];
	       			this.searchForm.city = this.searchForm.addrArry[1];
	       			this.searchForm.county = this.searchForm.addrArry[2];
	            }
                this.$http.post(BaseUrl.getHospitalPageList,this.searchForm).then(res=>{
                    if(res.data.code==0){
                        this.tableData = res.data.data.data;
                        this.totalPage = res.data.data.totalPage;
                        this.total = res.data.data.total;
                        this.loading=false;//列表loading
                    }else{
                        this.$message({
                            message: res.data.msg,
                            type: 'warning'
                        })
                    }
                    
                })
            },
            handleSizeChange(val){
                this.searchForm.limit = val;
                this.getData();
            },
            handleCurrentChange(val){
                this.searchForm.currentPage = val;
                this.getData();
            },
            reset(){
                this.resetSearchForm(this.searchForm,[{k:'limit',v:this.searchForm.limit},{k:'currentPage',v:this.searchForm.currentPage}]);
                // this.getData();
            },
            showAddVisible(type,row){
                this.addForm={
                	hospitalName:'',
                	hospitalCode:'',
                	province:'',
	                city:'',
	                county:'',
	                addrArry:[],
	                address:'',
	                level:'',
	                linkman:'',
	                phone:'',
	                introduction:''
                };
                //获取医院等级下拉列表
				this.$http.post(BaseUrl.getLevelSelect).then(res => {
				  this.levels = res.data.data;
				});
                this.type=type;
                if(type=='add'){
                    this.titleName="新增医院信息";
                    this.addVisible=true;
                    /*this.listDept().then(res=>{
                        this.deptOptions = this.handleTree(res.data.data,"dept_id");
                        
                    });*/
                }else{
                    this.titleName="修改医院信息";
                    this.row=row;
                    this.$set(row,'editLoading',true);
                    this.$http.post(BaseUrl.getHospitalById,{id:row.id}).then(res=>{
                        if(res.data.code==0){
                            this.deptInfo={
                                id:res.data.data.dept_id,
                                name:row.dept_name//row.dept_name
                            }
                            this.addForm=res.data.data;
                            if(res.data.data.province != null || res.data.data.province != ''){
		                		this.addForm.addrArry = [res.data.data.province,res.data.data.city,res.data.data.county];
		                	}
                            this.addVisible=true;
                                this.$set(row,'editLoading',false);
                            /*this.listDept().then(res=>{
                                this.deptOptions = this.handleTree(res.data.data,"dept_id");
                                
                            });*/
                        }else{
                            this.$message({
                                message: res.data.msg,
                                type: 'warning'
                            })
                        }
                        
                    })
                    
                }
                
            },
            handleChange(value) {
		        this.addForm.addrArry = value;
		        this.addForm.province = this.addForm.addrArry[0];
		        this.addForm.city = this.addForm.addrArry[1];
		    },
            //保存新增/修改
            saveEdit(form,type){
            	if(this.addForm.addrArry.length != 0){
	       			this.addForm.province = this.addForm.addrArry[0];
	       			this.addForm.city = this.addForm.addrArry[1];
	       			this.addForm.county = this.addForm.addrArry[2];
	            }
                this.$refs[form].validate((valid)=>{
                    if(valid){
                        delete this[form].dept_name;
                        if(type=='add'){
                            this.$http.post(BaseUrl.addHospitalInfo,this[form]).then(res=>{
                                if(res.data.code==0){
                                    this.titleName='';
                                    this.getData();
                                    this.addVisible=false;
                                    this.$message({
                                        message: res.data.msg,
                                        type: 'success'
                                    })
                                }else{
                                    this.$message({
                                        message: res.data.msg,
                                        type: 'warning'
                                    })
                                }
                            })
                        }else{
                            this[form].id=this.row.id;
                            this.$http.post(BaseUrl.saveHospitalById,this[form]).then(res=>{
                                if(res.data.code==0){
                                    this.titleName='';
                                    this.getData();
                                    this.addVisible=false;
                                    this.$message({
                                        message: res.data.msg,
                                        type: 'success'
                                    })
                                }else{
                                    this.$message({
                                        message: res.data.msg,
                                        type: 'warning'
                                    })
                                }
                            })
                        }
                        
                        
                    }
                })

            },
            deleteStation(row){
                var that=this;
                this.$confirm('是否确认删除医院"' + row.hospitalName + '"的数据项?', "警告", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning"
                }).then(function() {
                    that.$http.post(BaseUrl.delHospital,{id:row.id}).then(res=>{
                        if(res.data.code==0){
                            that.getData();
                            that.$message({
                                message:'删除成功',
                                type:'success'
                            })
                        }else{
                            that.$message({
                                message: res.data.msg,
                                type: 'warning'
                            })
                        }
                        
                    })
                    
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消删除'
                    });          
                });
            },
            querySearch(queryString, cb) {
	            this.lng = '';
	            this.lat = '';
	            this.getAddressList().then(res=>{
	                this.restaurants=res;
	                var restaurants = this.restaurants;
	                for(let i=0;i<restaurants.length;i++){
	                    restaurants[i].value=restaurants[i].name;
	                }
	                // var newRestaurants=restaurants.splice(1,restaurants.length );
	                var newRestaurants=restaurants;
	                //var results = queryString ? restaurants.filter(this.createFilter(queryString)) : restaurants;
	                // 调用 callback 返回建议列表的数据
	                // cb(results);
	                cb(newRestaurants);
	            });
	            
	        },
	        handleSelect(item) {
	        	this.lng = item.location.lng;
	            this.lat = item.location.lat;
	            this.$refs.addForm.validateField('address',(validMessage)=>{
	                console.log('###',validMessage);
	            })
	        },
	        //百度地图联想输入地址
	        getAddressList(){
	        	var city = (this.addForm.city == '市辖区')?this.addForm.province:this.addForm.city;
	        	console.log('地图联想城市:'+city);
	            var that=this;
	            var url='http://api.map.baidu.com/place/v2/suggestion'
	            var param={
	                query:that.addForm.address,
	                region:city,
	                city_limit:true,
	                output:'json',
	                ak:'hSfAbjnt10T97Ujc1fumoe4vMbfpYgRk'
	            }
	            return new Promise((resolve, reject)=>{
	                this.$http.jsonp(url, param).then(res => {
	                    console.log(res.result);
	                    res.result.forEach((item,index) => {
	                        if(item.uid==''){
	                            res.result.splice(index,1);
	                        }
	                    });
	                    resolve(res.result);
	                }).catch(err=>{
	                    reject();
	                })
	            })
	        },
            noClick(){
                this.getData();
                this.addVisible=false;
            },
            
            getFatherAncestors(val){}
            
        }
        
    }
</script>