<template>
	<div class="login-wrap" :class="'zj-login-wrap'">
		<el-row class="login-container">
			<el-col :span="12">
				<div class="login-title">
					<img :src="`${publicPath}logo/zy-logo-left.png`">
				</div>
			</el-col>
			<el-col :span="12">
				<div class="login-form-container">
					<div class="login-form">
						<div class="login-form-title">
							<span>子曰上医管理平台</span>
							</br>
							<span>（三终端）</span>
						</div>
						<el-form :model="ruleForm" :rules="rules" ref="ruleForm">
							<el-form-item prop="account" class="radius50">
								<el-input placeholder="请输入用户名" v-model="ruleForm.account"
									@keyup.enter.native="submitLogin('ruleForm')" autocomplete="off">
									<template slot="prepend" style="width:80px">
										<i class="el-icon-user-solid" style="font-size:20px;color:#006D33;"></i>
									</template>
								</el-input>
							</el-form-item>
							<el-form-item prop="password" class="radius50">
								<el-input ref='pwdInput' onfocus="this.type='password'" type="text" placeholder="请输入密码"
									v-model="ruleForm.password" @keyup.enter.native="submitLogin('ruleForm')"
									autocomplete="off">
									<template slot="prepend" style="width:100px">
										<i class="el-icon-lock" style="font-size:20px;color:#006D33;"></i>
									</template>
								</el-input>
							</el-form-item>
							<!-- <el-form-item prop="code" class="validate-code">
                            <el-input v-model.trim="ruleForm.code" style="width:120px;display:inline-block" placeholder="输入验证码" @keyup.enter.native="submitLogin('ruleForm')" autocomplete="off"></el-input>
                            <span style="display:inline-block"><img id="codeImg" :src="codeImg" alt="加载中" @click="changeValideCode()"></span>
                            <a @click="changeValideCode()" style="cursor:pointer;text-decoration: underline;color: blue;">换一张</a>
                        </el-form-item> -->
							<div class="pwd-operation" style="height:30px">
								<el-checkbox v-model="rememberPwd" class="remember-pwd">记住用户名</el-checkbox>
							</div>
							<div class="login-btn" style="height:50px">
								<el-button type="button" @click.prevent="submitLogin('ruleForm')" :loading="loading">
									登&nbsp;&nbsp;录</el-button>
							</div>
							<div class="login-btn">
								<!-- <a style="font-size:12px;">忘记密码请致电客服：400-001-8070</a>  -->
							</div>
						</el-form>
					</div>
				</div>
			</el-col>
		</el-row>
		<Verify @success="success" @close="closeVerify" mode='pop' captchaType='blockPuzzle'
			:imgSize="{ width: '330px', height: '155px' }" ref="verify"></Verify>
	</div>
</template>
<script>
	import BaseUrl from '@/utils/baseUrl';
	import {
		mapState,
		mapMutations,
		mapActions
	} from "vuex";
	import Cookies from 'js-cookie'
	import Verify from "../../components/verifition/Verify"
	export default {
		components: {
			Verify
		},
		data() {
			return {
				rememberPwd: '',
				codeImg: '',
				ruleForm: {
					account: localStorage.getItem('localAccount'),
					password: ''
					// code: ''
				},
				rules: {
					account: [{
						required: true,
						message: '请输入用户名',
						trigger: 'blur'
					}],
					password: [{
						required: true,
						message: '请输入密码',
						trigger: 'blur'
					}, {
						min: 6,
						message: '密码长度最少为6位',
						trigger: 'blur'
					}],
					code: [{
						required: true,
						message: '请输入验证码',
						trigger: 'blur'
					}],
				},
				loading: false,
				verifySuccess: false
			}
		},
		created() {
			//this.changeValideCode();
			this.ifRememberPwd();
			//console.log('process.env.BASE_URL',process.env.BASE_URL)

		},
		methods: {
			...mapActions(['Login', 'Logout', 'FedLogOut']),
			submitLogin(ruleForm) {
				this.$refs[ruleForm].validate((valid) => {
					if (valid) {
						this.loading = true;
						this.$refs.verify.show();
					} else {
						console.log('error submit!!');
						//this.changeValideCode(); //出现登录错误后，更新验证码
						return false;
					}
				})
			},
			doLogin(params) {
				this.loading = true;
				this.ruleForm.captchaVerification = params.captchaVerification;
				this.Login(this.ruleForm).then(res => {
					//console.log(res);
					if (res.data.code == 0) {
						sessionStorage.setItem('real_name', res.data.data.real_name); //存储当前登录人
						let params = {
							account: res.data.data.account
						};
						//console.log(BaseUrl.verifyUsers);
						this.loading = false;
						this.$router.push('/home');
						if (this.rememberPwd == true) {
							localStorage.setItem('localAccount', this.ruleForm.account);
						} else {
							localStorage.removeItem('localAccount');
						};
					} else {
						/*this.$message({
						    message:res.data.msg,
						    type:'error'
						})*/
						//this.changeValideCode(); //出现登录错误后，更新验证码
						this.loading = false;
					}
				})
			},
			success(params) {
				//console.log(params);
				this.verifySuccess = true;
				this.doLogin(params);
			},
			//关闭验证码弹窗
			closeVerify(show) {
				//console.log('closeVerify',show);
				if (!show) {
					this.loading = false
				}

			},
			changeValideCode() {
				this.codeImg = serverUrl + "/images/kaptcha.jpg?n=" + Math.random();
			},
			ifRememberPwd() {
				let localAccount = localStorage.getItem('localAccount');
				if (localAccount != null) {
					this.rememberPwd = true;
				}
			}
		},
	}
</script>
<style lang="scss">
	.zj-login-wrap {
		.login-form {
			color: #fff;

			.login-form-title,
			.el-input-group__prepend {
				color: #006D33 !important;
				border-color: #666 !important;
			}

		}

		.login-form>.el-form .el-input-group__prepend {
			color: #666 !important;
			border-color: #666 !important;
		}

		.login-form>.el-form .el-input .el-input__inner {
			border-color: #666 !important;
		}

		.login-btn button {
			background-color: #006D33 !important;
			border-radius: 5px !important;
		}
	}
</style>
<style scoped>
	body,
	html {
		overflow-x: auto !important;
	}

	/*  */
	.login-wrap {
		position: relative;
		width: 100%;
		min-width: 1280px;
		height: 100%;
		background-size: 100% 100%;
		background-repeat: no-repeat;
		background-color: #FFFFFF;
	}

	img#codeImg {
		width: 107px;
		height: 42px;
		line-height: 42px;
		vertical-align: bottom;
	}

	.login-container {
		width: 1280px;
		position: absolute;
		height: 500px;
		left: 50%;
		top: 50%;
		margin-left: -640px;
		margin-top: -250px;
	}

	.login-container>div {
		float: left;
		box-sizing: border-box;
	}

	.login-title {
		width: 640px;
		height: 100%;
		text-align: center;
		position: relative;
		/* margin-left: 100px; */
	}

	.login-title img {
		width: 280px;
		height: 500px;
		border-top-left-radius: 15px;
		border-bottom-left-radius: 15px;
		float: right;
		border: 1px solid #D5D5D5;
		border-right: none;
	}

	.login-title div {
		margin: 20px;
		text-align: center;
		color: #666;
	}

	.login-form-container {
		width: 380px;
		height: 500px;
		border: 1px solid #D5D5D5;
		background-color: #fff;
		border-left: none;
		position: relative;
		border-top-right-radius: 15px;
		border-bottom-right-radius: 15px;
		float: left;
	}

	.login-form {
		width: 100%;
		height: 260px;
		padding: 40px 40px 80px 40px;
		box-sizing: border-box;
		top: 50%;
		margin-top: 30px;
	}

	.login-form-title {
		text-align: center;
		color: #ccc;
		font-family: "微软雅黑";
		font-weight: bold;
		font-size: 27px;
		margin-bottom: 50px;
		cursor: default;
	}

	.pwd-operation {
		margin-top: 50px;
		padding: 0 5px;
	}

	.pwd-operation button {
		background-color: transparent;
		color: #2C79C7;
		border: none;
		outline: none;
		text-decoration: underline;
		cursor: pointer;
	}

	.pwd-operation button:hover {
		color: #004EA1;
	}

	.forget-pwd {
		float: right;
	}

	.login-btn {
		text-align: center;
	}

	.login-btn button {
		width: 100%;
		height: 40px;
		color: #fff;
		font-size: 14px;
		background-color: #3392f3;
		border: none;
		outline: none;
		border-radius: 80px;
		cursor: pointer;
		transition: background-color 0.1s;
	}

	.login-btn button:hover {
		background-color: #2674c4;
	}

	.login-form-input div {
		border-color: #409eff !important;
		border-radius: 80px !important;
	}

	.el-dialog__header {
		text-align: center;
	}

	.checkbox-list {
		padding-left: 40px;
	}

	.width-textarea {
		width: 500px;
	}

	.handle-input-btn {
		width: 172px !important;
	}

	.confirm-btn-width {
		width: 133px !important;
	}

	.login-loading {
		width: 120px;
		height: 120px;
		padding-top: 5px;
		color: #CCCCCC;
		position: absolute;
		top: -20px;
		left: 0;
		bottom: 0;
		right: 0;
		margin: auto;
		border-radius: 10px;
		background-color: rgba(0, 0, 0, 0.8);
		text-align: center;
	}

	.login-loading i {
		display: block;
		width: 100%;
		height: 80px;
		text-align: center;
		line-height: 80px;
		font-size: 56px;
	}
</style>
<style>
	.radius50 {
		padding-top: 15px;
	}

	.radius50 .el-input-group__prepend {
		border-top-left-radius: 5px;
		border-bottom-left-radius: 5px;
		color: #2674c4;
	}

	.radius50 .el-input-group--prepend .el-input__inner {
		border-top-right-radius: 5px;
		border-bottom-right-radius: 5px;
	}

	.radius50 .el-input-group--prepend .el-input__inner::-webkit-input-placeholder {
		color: #999;
	}
</style>