<!--订单打印功能迁移到药房端系统，该功能不用-->
<template>
    <div style="background:#fff;">
        <button v-print="printObj" style="visibility: hidden;" ref='btn'>Print local range</button><!-- v-print="'#printMe'"-->
        <div  style="width: 700px;margin:auto;font-size: 14px;padding-top:40px;height:100%" id="printMe"> 
            <div style="display:flex;justify-content: space-between;width:680px;line-height: 2em;align-items: flex-end;">
                <div>
                    <div style="display:flex;align-items:center;">
                        <img :src="`${publicPath}logo/zj-logo.jpg`" width="80" height="80" />
                        <span style=" font-size:28px; line-height:80px;margin-left:30px;font-weight: bold;">{{type=='factory'?'工厂联':'客户联'}}</span>
                    </div>
                    <p>服药前请核对姓名、电话、医生等信息以及服法、医嘱等要点</p>
                </div>
                
                <div>
                    <div>编号：{{tableData.orderNum}}</div>
                    <div>日期：{{tableData.createTime}}</div>
                </div>
            </div>
            
            <table width="680" height="233" border="0" cellspacing="0">
                <tr>
                    <td width="140" style="padding:0  10px;border-top: 1px solid #333;border-left: 1px solid #333;">姓名：{{tableData.patiName}}</td>
                    <td width="140"  style="padding:0  10px;border-top: 1px solid #333;border-left: 1px solid #333;">性别：{{tableData.sex}}</td>
                    <td width="140" style="padding:0  10px;border-top: 1px solid #333;border-left: 1px solid #333;">年龄：{{tableData.age}}</td>
                    <td colspan="2" style="padding:0  10px;border: 1px solid #333;border-bottom: 0;">联系方式:{{tableData.phone}}</td>
                </tr>
                <tr>
                    <td style="padding:0  10px;border-top: 1px solid #333;border-left: 1px solid #333;">收货人及电话：</td>
                    <td colspan="4" style="padding:0  10px;border: 1px solid #333;border-bottom: 0;">{{tableData.person}} {{tableData.sh_phone}}</td>
                </tr>
                <tr>
                    <td style="padding:0  10px;border-top: 1px solid #333;border-left: 1px solid #333;">收货地址：</td>
                    <td colspan="4" style="padding:0  10px;border: 1px solid #333;border-bottom: 0;">{{tableData.address}}</td>
                </tr>
                <tr>
                    <td style="padding:0  10px;border-top: 1px solid #333;border-left: 1px solid #333;">中医症型：</td>
                    <td colspan="4" style="padding:0  10px;border: 1px solid #333;border-bottom: 0;">{{tableData.result}}</td>
                </tr>
                <tr>
                    <td style="padding:0  10px;border: 1px solid #333;border-right: 0px;">症状描述：</td>
                    <td colspan="4" style="padding:0  10px;border: 1px solid #333;">{{tableData.result_detail}}</td>
                </tr>
            </table>
            <div style="margin-top:20px;">
                <span style="font-weight: bold;font-size:14px;">处方明细：</span>
            </div>

            <div style="display:flex;">
                <div style="flex:1;padding:0  10px;padding-top:20px;font-weight: bold;">药名</div>
                <div v-if="type=='factory'" style="flex:1;padding:0  10px;padding-top:20px;font-weight: bold;">用量</div>
                <div style="flex:1;padding:0  10px;padding-top:20px;font-weight: bold;">药名</div>
                <div v-if="type=='factory'" style="flex:1;padding:0  10px;padding-top:20px;font-weight: bold;">用量</div>
            </div>
            <div style="min-height:230px">
                <div>
                    <div style="display:inline-block;" v-for="(drugItem,drugIndex) in tableData.openRecipe.recipseDetail" :key="drugIndex">
                        <div style="padding:3px 10px;display:inline-block;" v-show="drugItem.price_type==0" :style="{'width':type=='factory'?'155px':'330px'}">{{drugItem.drugName}}</div>
                        <div v-if="type=='factory'"  v-show="drugItem.price_type==0" style="padding:3px  10px;width:155px;display:inline-block;">{{drugItem.quantity}}克</div>
                    </div>
                </div>
            </div>
            <div style="margin-top:20px;">
                <span style="font-weight: bold;">医嘱：</span>
                <span>{{tableData.openRecipe.lookOut?tableData.openRecipe.lookOut:''}}</span>
            </div>
            <div style="margin:20px 0;">
                <span style="font-weight: bold;">备注：</span>
                <span>共 {{tableData.openRecipe.drugNum}} 味药</span>
            </div>
			<div style="margin:20px 0px 10px 0px;">
			    <span style="font-weight: bold;">代煎费：</span>
			    <span>{{tableData.proce_sum}}元</span>
			</div>
            <table  width="680" height="102" border="0" cellspacing="0">    
                <tr>
                    <td style="padding:0  10px;height: 100px;border: 1px solid #333;line-height: 2em;">
                        医师：{{tableData.doctorName}}<br />
                        <img v-if="tableData.signImgUrl" :src="tableData.signImgUrl" style="object-fit: cover;" width="100" height="50" />
                    </td><!--(签名或签章)-->
                    <td colspan="24" style="padding:0  10px;border: 1px solid #333;border-left: 0px;">
                    <div style="display: flex;">
                        <div style="flex:5">类型：{{tableData.openRecipe.drugType}}</div>
                        <div style="flex:9">处方金额：{{tableData.drug_sum}} 元</div>
						
                        <div style="flex:4">剂数：{{tableData.openRecipe.dosage}} 剂</div>
                        <div style="flex:6">合计：{{tableData.openRecipe.orderSum}} 元</div>
                    </div>
                    </td>
                </tr>
            </table>
            <p style="line-height: 3em;"><span style="width:180px;display:inline-block;">客服电话：{{tableData.kf_phone}}</span> <span style="margin-left:30px;display:inline-block;">工作时间：{{tableData.workTime}}</span></p>
            <p style="line-height: 3em;">注：药材名称和价格可能因地区药库差异有所不同</p>
            <div style="display:flex;justify-content:flex-end;">
                <div style="width:150px;">药剂师：</div>
                <div style="width:150px;">日期：</div>
            </div>
        </div>
    </div>
</template>

<script>
import BaseUrl from '@/utils/baseUrl';
export default {
  props: {
    //标题
    tableData:{
        type: Object,
        default () {
            return {}
        }
    },
    type:{
       type: String,
       default:''
    },
	daiJianFei:{
		type: String,
		default:''
	}
  },
  data() {
    return {
      //tableData:null
        printObj: {
            id: "printMe",
            preview: false,
            extraHead: '<meta http-equiv="Content-Language"content="zh-cn"/>',
            beforeOpenCallback (vue) {
                if(vue.type=='factory'){//打印工厂栏
                    vue.updOrderPrintState();
                    vue.$emit('reload',true)
                }else{
                    vue.$emit('reload',false)
                }
            }
        }
    }
  },
  mounted() {
      this.$refs.btn.click();
      setTimeout(()=>{
          this.$emit('input',false)
      },200) 
    
  },
  methods: {
    //修改打印状态
    updOrderPrintState(row){
        
        this.$http.post(BaseUrl.updOrderPrintState,{id:this.tableData.rowId}).then(res=>{
            if(res.data.code==0){
                console.log('修改打印状态')
            }else{
                this.$message({
                    message: res.data.msg,
                    type: 'warning'
                })
            }
        })
    }
  },
  beforeDestroy() {
    
  }
}
</script>

<style lang="scss">

/*去除页眉页脚*/
  @page{
    size:  auto;   /* auto is the initial value */
    margin: 3mm;  /* this affects the margin in the printer settings */
    
  }
  @media print {
    html, body {
        height: inherit;
    }
  }
 
  html{
    background-color: #FFFFFF;
    margin: 0;  /* this affects the margin on the html before sending to printer */
  }
 
  body{
    border: 0 ;
    margin: 0; /* margin you want for the content */
    
    
  }
</style>