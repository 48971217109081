<template>
	<div class="table">
		<div class="container" :style="'height:' + (tableH + 135) + 'px;'">
			<!--搜索框-->
			<el-form :inline="true" :model="searchForm" ref="searchForm">
				<el-form-item label="医生姓名" prop="doctor_name">
					<el-input v-model.trim="searchForm.doctor_name" style="width:150px" size="small"
						placeholder="请输入医生姓名"></el-input>
				</el-form-item>
				<el-form-item label="商品名称" prop="product_name">
					<el-input v-model.trim="searchForm.product_name" style="width:150px" size="small"
						placeholder="请输入商品名称"></el-input>
				</el-form-item>
				<el-form-item label="订单编号" prop="order_num">
					<el-input v-model.trim="searchForm.order_num" style="width:150px" size="small"
						placeholder="请输入订单编号"></el-input>
				</el-form-item></br>
				<el-form-item prop="time" label="订单时间">
					<dateChoose v-model="searchForm.time" :time="searchForm.time"></dateChoose>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" plain icon="el-icon-search" size="small" @click="getData()">查询</el-button>
					<el-button type="warning" plain icon="el-icon-refresh" size="small" @click="reset()">重置</el-button>

				</el-form-item>
			</el-form>

			<el-table v-loading="loading" ref="table" highlight-current-row   :data="tableData" border
				stripe size="mini" style="width: fit-content;">
				<el-table-column  label="序号" type="index" width="50" show-overflow-tooltip></el-table-column>
				<el-table-column prop="doctor_name" label="医生姓名" width="120" show-overflow-tooltip></el-table-column>
				<el-table-column prop="doctor_phone" label="手机号" width="180" show-overflow-tooltip></el-table-column>
				<el-table-column prop="order_num" label="订单编号" width="120" show-overflow-tooltip></el-table-column>
				<el-table-column prop="product_name" label="商品名称" width="180" show-overflow-tooltip></el-table-column>
				<el-table-column prop="brand" label="品牌" width="180" show-overflow-tooltip></el-table-column>
				<el-table-column prop="score" label="积分" width="80" show-overflow-tooltip></el-table-column>
				<el-table-column prop="order_state" label="订单状态" width="80" show-overflow-tooltip></el-table-column>
				<el-table-column prop="create_time" label="订单时间" width="180" show-overflow-tooltip></el-table-column>
				<el-table-column prop="phy_company" label="物流名称" width="120" show-overflow-tooltip></el-table-column>
				<el-table-column prop="phy_num" label="物流单号" width="120" show-overflow-tooltip></el-table-column>
				<el-table-column prop="deliver_time" label="发货时间" width="180" show-overflow-tooltip></el-table-column>
				<el-table-column prop="sige_time" label="签收时间" width="180" show-overflow-tooltip></el-table-column>
				<el-table-column prop="" label="操作人" width="120" show-overflow-tooltip></el-table-column>
				<el-table-column prop="" label="操作时间" width="180" show-overflow-tooltip></el-table-column>
				<el-table-column prop="" label="操作" :width="tableBtnWidth">
					<template slot-scope="scope">
						<el-button size="mini" type="primary" icon="el-icon-edit" plain
							@click="deliverGoods(scope.row)">发货</el-button>
					</template>
				</el-table-column>
			</el-table>
			<!--TODO 分页-->
			<div class="pagination table-width-b" v-if="totalPage != 0"
				:style="'width:' + footWidth + ';max-width:100%;'">
				<el-pagination @size-change="handleSizeChange" :page-sizes="[10, 20, 50]" :page-size="searchForm.limit"
					:current-page.sync="searchForm.currentPage" @current-change="handleCurrentChange"
					layout="jumper, prev, pager, next, sizes, total" :total="total">
				</el-pagination>
			</div>
			<el-dialog title="发货" :visible.sync="dialogVisible" width="800px" :before-close="cancel">
				<el-form :inline="true" :model="form" ref="form">
					<el-form-item label="物流名称" prop="phy_company">
						<el-input v-model.trim="form.phy_company" style="width:180px" size="small"
							placeholder="请输入物流名称"></el-input>
					</el-form-item>
					<el-form-item label="物流单号" prop="phy_num">
						<el-input v-model.trim="form.phy_num" style="width:180px" size="small" placeholder="请输入物流单号">
						</el-input>
					</el-form-item>
				</el-form>
				<span slot="footer" class="dialog-footer">
					<el-button @click="cancel()">取 消</el-button>
					<el-button type="primary" @click="submitBtn()">确 定</el-button>
				</span>
			</el-dialog>

		</div>
	</div>
</template>
<script>
	// getProductOrderPageList//查询商品订单信息分页列表信息
	// updProductOrderById//商品订单发货
	import BaseUrl from '@/utils/baseUrl';
	import dateChoose from "@/components/date-choose" //时间段选择
	import moment from 'moment'
	export default {
		components: {
			dateChoose
		},
		data() {
			return {
				footWidth: '0px',
				loading: true, //列表loading
				tableData: [],
				searchForm: {
					doctor_name: '',
					product_name: '',
					order_num: '',
					limit: limit,
					currentPage: 1,
					startTime: '',
					endTime: '',
				},
				form: {},
				total: 0,
				totalPage: 0,
				type: '', //add,edit
				row: null,
				tableBtnWidth: '100', //页面链接最后一个字段
				dialogVisible: false,
				rule: []
			}
		},
		mounted() {
			//this.getTableProp();
			var that = this;
			that.footWidth = that.$refs.table.bodyWidth;
			this.searchForm.startTime = this.searchForm.time[0];
			this.searchForm.endTime = this.searchForm.time[1];
			this.getData();
		},
		watch: {
			searchForm: {
				handler(newName, oldName) {
					this.searchForm.startTime = newName[0];
					this.searchForm.endTime = newName[1];
					//执行代码
				},
				deep: true
			}
		},
		methods: {

			getData() {
				this.loading = true; //列表loading
				this.searchForm.startTime = this.searchForm.time[0]
				this.searchForm.endTime = this.searchForm.time[1]
				this.$http.post(BaseUrl.getProductOrderPageList, this.searchForm).then(res => {
					if (res.data.code == 0) {
						this.tableData = res.data.data.data;
						this.totalPage = res.data.data.totalPage;
						this.total = res.data.data.total;
						this.loading = false; //列表loading
					} else {
						this.$message({
							message: res.data.msg,
							type: 'warning'
						})
					}

				})
			},
			handleSizeChange(val) {
				this.searchForm.limit = val;
				this.getData();
			},
			handleCurrentChange(val) {
				this.searchForm.currentPage = val;
				this.getData();
			},
			reset() {
				this.resetSearchForm(this.searchForm, [{
						k: 'limit',
						v: this.searchForm.limit
					},
					{
						k: 'currentPage',
						v: this.searchForm.currentPage
					},
					{
						k: 'startTime',
						v: moment().subtract(7, 'days').format('YYYY-MM-DD')
					},
					{
						k: 'endTime',
						v: moment().format('YYYY-MM-DD')
					},
					{
						k: 'time',
						v: [moment().subtract(7, 'days').format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')]
					}
				]);
				this.getData();
			},
			deliverGoods(row) {
				// this.form.phy_company = null
				// this.form.phy_num = null
				this.form.id = row.id
				this.dialogVisible = true
			},
			//取消
			cancel() {
				this.form.phy_company = null
				this.form.phy_num = null
				this.dialogVisible = false
			},
			// 发货提交
			submitBtn() {
				this.loading = true
				this.$http.post(BaseUrl.updProductOrderById, this.form).then(res => {
					if (res.data.code == 0) {
						this.$message({
							message: res.data.msg,
							type: 'success'
						})
						this.dialogVisible = false
						this.form.phy_company = null
						this.form.phy_num = null
						this.loading = false; //列表loading
						this.getData();
					} else {
						this.$message({
							message: res.data.msg,
							type: 'warning'
						})
						this.loading = false; //列表loading
					}
				
				}).catch((error)=>{
					this.$message({
						message: error,
						type: 'warning'
					})
					this.loading = false; //列表loading
				})
			},
		}

	}
</script>
<style lang="scss" scoped>
	.tipsCardBox {
		position: fixed;
		top: 120px;
		right: 20px;
		width: 200px;
		z-index: 9;

		.tit {
			display: flex;
			justify-content: space-between;
			align-items: center;
		}

	}
</style>
