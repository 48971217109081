<template>
  <div class="sidebar">
    <el-menu
        ref="menus"
        class="sidebar-el-menu"
        :default-active="menuActiveName || 'home'"
        :collapse="collapse"
        
        @open="handelOpen"
        unique-opened
        router>
        <!--background-color="#0a163e"
        text-color="#bfcbd9"
        active-text-color="#20a0ff"-->
        <el-menu-item class="home-submenu" index="home" @click="toHome">
          <i class="el-icon-s-home"></i>
          <span slot="title">首页</span>
        </el-menu-item>
        <sub-menu
          v-for="menu in menuList"
          :key="menu.id"
          :menu="menu"
          :dynamicMenuRoutes="dynamicMenuRoutes">
        </sub-menu>
    </el-menu>
  </div>
</template>
<script>
import bus from "utils/bus";
import BaseUrl from '@/utils/baseUrl'
import SubMenu from './main-sidebar-sub-menu'
export default {
  components: {
      SubMenu
  },
  data() {
    return {
      collapse: false,
      menuList: [],
      menuActiveName:'',
      openKey:null
    };
  },
  computed: {
  },
  created() {
    this.menuList = JSON.parse(sessionStorage.getItem('menuList') || '[]')
    this.dynamicMenuRoutes = JSON.parse(sessionStorage.getItem('dynamicMenuRoutes') || '[]')
    this.routeHandle(this.$route)
    // 通过 Event Bus 进行组件间通信，来折叠侧边栏
    bus.$on("collapse", msg => {
      this.collapse = msg;
    });
    //加载菜单
    this.getMenus();
    //获取权限
    this.getPerms();
  },
  watch: {
      $route: 'routeHandle'
  },
  methods: {
    toHome(){
      if(this.openKey){
        this.$refs.menus.close(this.openKey);
      }
      
      this.$router.push({ name: 'home' })
    },
    handelOpen(index,indexPath){
      this.openKey=index;
    },
  	getMenus(){
      this.$http.post(BaseUrl.initMenu).then(res=>{//, '','json'
        this.menuList=res.data.data;
			})
    },
    getPerms(){
      this.$http.post(BaseUrl.perms).then(res=>{
        sessionStorage.setItem('permissions', res.data.data); 
		  })
    },
    // 路由操作
    routeHandle (route) {
      this.menuActiveName=route.name;
    }
  }
};
</script>

<style scoped>
.sidebar {
  height: 100%;
  display: block;
  position: absolute;
  left: 0;
  top: 70px;
  bottom: 0;
  overflow-y: scroll;
}
.sidebar::-webkit-scrollbar {
  width: 0;
}
.sidebar-el-menu:not(.el-menu--collapse) {
  width: 200px;
  padding-right: 20px;
  box-sizing: border-box;
  /* width: 180px; */
}
.sidebar > ul {
  height: 100%;
}
</style>
