<template>
  <div class="wrapper">
    <v-head></v-head>
    <v-sidebar></v-sidebar>
    <div class="content-box" :class="{ 'content-collapse': collapse,'home':isHome }">
      <v-tags></v-tags>
      <div class="content">
        <transition name="move" mode="out-in">
          <keep-alive ><!--:include="tagsList"-->
            <router-view :key="key"></router-view>
          </keep-alive>
        </transition>
      </div>
    </div>
    <div>
    </div>
  </div>
</template>

<script>
import vHead from "./Header.vue";
import vSidebar from "./Sidebar.vue";
import vTags from "./Tags.vue";
import bus from "utils/bus";
export default {
  name: "home",
  data() {
    return {
      tagsList: [],
      collapse: false,
    };
  },
  components: {
    vHead,
    vSidebar,
    vTags
  },
  computed: {
    key() {
      return this.$route.fullPath;
    },
    isHome:{//是否是首页
      get(){
        //console.log(this.$route.path);
        return (this.$route.path=='/home');
      },
      set(val){
        //console.log(this.$route.path);
        return (this.$route.path=='/home');
      }
      
    }
  },
  watch:{
    $route(to,from){
      //console.log(to.path);
      //判断是否是home页面
      //console.log(to.path);
      if(to.path=='/home'){
        this.isHome=true;
      }else{
        this.isHome=false;
      }
    }
  },
  mounted() {
    // 关闭标签触发
    bus.$on('removeCache', (name, view) => {
      this.removeCache(name, view);
    });
  },
  created() {
    bus.$on("collapse", msg => {
      this.collapse = msg;
    });
    

    // 只有在标签页列表里的页面才使用keep-alive，即关闭标签之后就不保存到内存中了。
    bus.$on("tags", msg => {
      let arr = [];
      for (let i = 0, len = msg.length; i < len; i++) {
        msg[i].name && arr.push(msg[i].name);
      }
      this.tagsList = arr;
    });
  },
  methods:{
    // 获取有keep-alive子节点的Vnode
			getVnode() {
				// 判断子集非空
				if (this.$children.length == 0) return false;
				let vnode;
				for (let item of this.$children) {
					// 如果data中有key则代表找到了keep-alive下面的子集，这个key就是router-view上的key
					if (item.$vnode.data.key) {
						vnode = item.$vnode;
						break;
					}
				}
				return vnode ? vnode : false;
			},
			// 移除keep-alive缓存
			removeCache(name, view = {}) {
				let vnode = this.getVnode();
				if (!vnode) return false;
				let componentInstance = vnode.parent.componentInstance;
				// 这个key是用来获取前缀用来后面正则匹配用的
				let keyStart = vnode.key.split('/')[0];
				let thisKey = `${keyStart}${view.fullPath}`;
				let regKey = `${keyStart}${view.path}`;

				this[name]({ componentInstance, thisKey, regKey });
			},
			// 移除其他
			closeOthersTags({ componentInstance, thisKey }) {
				Object.keys(componentInstance.cache).forEach((key, index) => {
					if (key != thisKey) {
						// 1 销毁实例(这里存在多个key指向一个缓存的情况可能前面一个已经清除掉了所有要加判断)
						if (componentInstance.cache[key]) {
							componentInstance.cache[key].componentInstance.$destroy();
						}
						// 2 删除缓存
						delete componentInstance.cache[key];
						// 3 移除key中对应的key
						componentInstance.keys.splice(index, 1);
					}
				});
			},
			// 移除所有缓存
			closeAllTags({ componentInstance }) {
				// 1 销毁实例
				Object.keys(componentInstance.cache).forEach(key => {
					if (componentInstance.cache[key]) {
						componentInstance.cache[key].componentInstance.$destroy();
					}
				});
				// 2 删除缓存
				componentInstance.cache = {};
				// 3 移除key中对应的key
				componentInstance.keys = [];
			},
			// 移除单个缓存
			closeSelectedTag({ componentInstance, regKey }) {
				let reg = new RegExp(`^${regKey}`);
				Object.keys(componentInstance.cache).forEach((key, i) => {
					if (reg.test(key)) {
						// 1 销毁实例
						if (componentInstance.cache[key]) {
							componentInstance.cache[key].componentInstance.$destroy();
						}
						// 2 删除缓存
						delete componentInstance.cache[key];
						// 3 移除key中对应的key
						componentInstance.keys.splice(i, 1);
					}
				});
			}
  }
};
</script>

