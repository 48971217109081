<template>
	<div class="table">
		<div class="container">
			<!--搜索框-->
			<el-form :inline="true" :model="searchForm" ref="searchForm">
				<!--<el-form-item  label="药企名称">
                    <el-input v-model.trim="searchForm.company_name" size="small" placeholder="请输入药企名称"></el-input>
                </el-form-item>-->
				<el-form-item prop="searchType" label="">
					<el-radio-group v-model="searchForm.searchType" @change="changeSearchType" size="small">
						<el-radio-button label="2">按月查询</el-radio-button>
						<el-radio-button label="1">按年查询</el-radio-button>

					</el-radio-group>
				</el-form-item>
				<el-form-item prop="years" label="" v-if="searchForm.searchType==1">
					<el-select v-model="searchForm.years" placeholder="请选择年份" size="small" style="width:220px">
						<el-option v-for="item in yearOptions" :key="item.company_id" :label="item.years"
							:value="item.years">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item prop="years" label="" v-if="searchForm.searchType==2">
					<el-date-picker size="small" value-format="yyyy-MM" v-model="searchForm.years" type="month"
						:clearable="false" placeholder="请选择月">
					</el-date-picker>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" plain icon="el-icon-search" size="small" @click="getData()">查询</el-button>
					<el-button type="warning" plain icon="el-icon-refresh" size="small" @click="reset()">重置</el-button>
				</el-form-item>
			</el-form>
			<el-table v-loading="loading" ref="table" highlight-current-row :data="tableData" border stripe size="mini"
				style="width: fit-content;">
				<el-table-column prop="years" v-if="searchForm.searchType==2" label="日期" header-align="center"
					width="275" show-overflow-tooltip></el-table-column>
				<el-table-column prop="years" v-if="searchForm.searchType==1" label="月份" header-align="center"
					width="275" show-overflow-tooltip></el-table-column>
				<el-table-column prop="money" label="交易总额(元)" header-align="center" width="275" show-overflow-tooltip>
					<template slot-scope="scope">
						<div style="text-align:right;">{{scope.row.money}}</div>
					</template>
				</el-table-column>
			</el-table>
			<div style="width:550px;text-align:right;font-size:14px;margin-top:10px;font-weight:bold;">合计：{{totalNum}}元
			</div>

		</div>
	</div>
</template>
<script>
	import BaseUrl from '@/utils/baseUrl';
	import detailInfo from "@/components/detail-info";
	import moment from 'moment'
	export default {
		components: {
			detailInfo
		},
		created() {

			this.getYearsSelect()
		},
		data() {
			return {
				searchForm: {
					searchType: '2',
					years: moment().format('YYYY-MM')
				},
				footWidth: '0px',
				loading: false, //列表loading
				tableData: [],
				totalNum: 0, //
				yearOptions: null,
				url: 'getDayStatisList'
			}
		},
		mounted() {
			//this.getTableProp();
			var that = this;
			that.footWidth = that.$refs.table.bodyWidth;
			this.getData();
		},
		methods: {
			changeSearchType(val) {
				this.searchForm.years = '';
				if (val == 1) {
					this.url = 'getYearsStatisList'
					this.searchForm.years = (new Date().getFullYear()).toString()
				} else {
					console.log('###', val);
					this.url = 'getDayStatisList'
					this.searchForm.years = moment().format('YYYY-MM')
				}
				this.searchForm.searchType = val;
				this.tableData = [];
				this.totalNum = 0;
				this.getData();
			},
			getData() {
				this.loading = true; //列表loading
				this.$http.post(BaseUrl[this.url], this.searchForm).then(res => {
					if (res.data.code == 0) {
						if (this.url == 'getYearsStatisList') {
							this.tableData = res.data.data.yearsStatisList;
						} else {
							this.tableData = res.data.data.dayStatisList;
						}
						this.totalNum = res.data.data.total;
						this.loading = false; //列表loading
					} else {
						this.$message({
							message: res.data.msg,
							type: 'warning'
						})
					}

				})
			},
			handleSizeChange(val) {
				this.searchForm.limit = val;
				this.getData();
			},
			handleCurrentChange(val) {
				this.searchForm.currentPage = val;
				this.getData();
			},
			reset() {
				this.resetSearchForm(this.searchForm, [{
					k: 'limit',
					v: this.searchForm.limit
				}, {
					k: 'currentPage',
					v: this.searchForm.currentPage
				}, {
					k: 'searchType',
					v: '2'
				}, {
					k: 'years',
					v: 	moment().format('YYYY-MM')	
				}]);
				this.url = 'getDayStatisList';
				this.getData();
			},
			//年份下拉列表
			getYearsSelect() {
				this.$http.post(BaseUrl.getYearsSelect).then(res => {
					if (res.data.code == 0) {
						this.yearOptions = res.data.data;
					} else {
						this.$message({
							message: res.data.msg,
							type: 'warning'
						})
					}

				})

			}
		}

	}
</script>