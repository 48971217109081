<template>
	<div class="table">
		<div class="container">
			<el-form :inline="true" :model="searchForm" ref="searchForm">
				<el-form-item prop="company_name" label="所属药企">
					<el-select v-model="searchForm.company_name" placeholder="请选择" size="small" filterable
						class="selectWdith">
						<el-option v-for="item in companys" :key="item.company_id" :label="item.company_name"
							:value="item.company_id">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" plain icon="el-icon-search" size="small" @click="getData()">查询</el-button>
					<el-button type="warning" plain icon="el-icon-refresh" size="small" @click="reset()">重置</el-button>
					<el-button type="success" plain size="small" icon="el-icon-plus" @click="showAddVisible('add')">新增
					</el-button>
				</el-form-item>
			</el-form>
			<el-table v-loading="loading" ref="table" highlight-current-row @current-change="handleCurrentChangeT"
				:data="tableData" border stripe size="mini" style="width: fit-content;">
				<el-table-column prop="item_code" label="品项编码" width="200" show-overflow-tooltip></el-table-column>
				<el-table-column prop="item_name" label="服务项目名称" width="200" show-overflow-tooltip></el-table-column>
				<!-- <el-table-column prop="item_state" label="项目类型" width="240" show-overflow-tooltip></el-table-column> -->
				<el-table-column prop="sale" label="销售金额(元)" width="140" show-overflow-tooltip></el-table-column>
				<el-table-column prop="cost_price" label="成本价(元)" width="140" show-overflow-tooltip></el-table-column>
				<el-table-column prop="item_desc" label="项目描述" width="300" show-overflow-tooltip></el-table-column>
				<!-- <el-table-column prop="use_flag" label="是否禁用" width="300" show-overflow-tooltip></el-table-column> -->
				<el-table-column prop="company_name" label="药企名称" width="200" show-overflow-tooltip></el-table-column>
				<el-table-column prop="" label="操作" width="200">
					<template slot-scope="scope">
						<el-button size="mini" type="primary" icon="el-icon-edit" plain
							@click="showAddVisible('edit',scope.row)" :loading="scope.row.editLoading">编辑</el-button>
						<el-button size="mini" type="danger" plain icon="el-icon-delete"
							@click="deleteStation(scope.row)">删除</el-button>
					</template>
				</el-table-column>
			</el-table>
			<!--TODO 分页-->
			<div class="pagination table-width-b" v-if="totalPage!=0" :style="'width:'+footWidth+';max-width:100%;'">
				<el-pagination @size-change="handleSizeChange" :page-sizes="[10,20,50]" :page-size="searchForm.limit"
					:current-page.sync="searchForm.currentPage" @current-change="handleCurrentChange"
					layout="jumper, prev, pager, next, sizes, total" :total="total">
				</el-pagination>
			</div>
			<!--新增/修改弹框-->
			<el-dialog :close-on-click-modal="false" :title='titleName' v-if="addVisible" :visible.sync="addVisible"
				width="700px" top="20vh" :append-to-body="true" :before-close="noClick">
				<el-form :inline="true" ref="addForm" :model="addForm" label-width="120px" :rules="addFormRules">

					<!--<el-form-item  label="项目类型" prop="item_state">
                        <el-input v-model.trim="addForm.item_state" size="small" placeholder="请输入项目类型"></el-input>
                    </el-form-item>-->
					<el-form-item label="项目服务名称" prop="item_name">
						<el-input v-model.trim="addForm.item_name" size="small" placeholder="请输入项目服务名称"></el-input>
					</el-form-item>
					<el-form-item label="项目编码" prop="item_code">
						<el-input v-model.trim="addForm.item_code" size="small" placeholder="请输入项目编码"></el-input>
					</el-form-item>
					<el-form-item label="销售金额(元)" prop="sale">
						<el-input v-model.trim="addForm.sale" size="small" placeholder="请输入销售金额"></el-input>
					</el-form-item>
					<el-form-item label="成本价(元)" prop="cost_price">
						<el-input v-model.trim="addForm.cost_price" size="small" placeholder="请输入成本价"></el-input>
					</el-form-item>
					<el-form-item prop="company_name" label="所属药企">
						<el-select v-model="addForm.company_id" placeholder="请选择" size="small" filterable
							class="selectWdith">
							<el-option v-for="item in companys" :key="item.company_id" :label="item.company_name"
								:value="item.company_id">
							</el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="项目描述">
						<el-input v-model.trim="addForm.item_desc" type="textarea" :rows="5" maxlength="500"
							size="small" placeholder="请输入项目描述，限制500字符以内" style="width: 530px;"></el-input>
					</el-form-item>
				</el-form>
				<span slot="footer" class="dialog-footer">
					<el-button size="small" @click="noClick()">取&nbsp;消</el-button>
					<el-button type="primary" size="small" @click="saveEdit('addForm',type)">保&nbsp;存</el-button>
				</span>
			</el-dialog>
		</div>
	</div>
</template>
<script>
	import BaseUrl from '@/utils/baseUrl';
	import options from '@/utils/country-data.js';
	import regulars from '@/utils/regulars.js';

	export default {
		components: {},
		created() {
			this.getData()
		},
		data() {
			//数字校验
			var checkValue = (rule, value, callback) => {
				if (!value) {
					return callback(new Error('请输入销售金额'));
				} else {
					const reg = /^\d+$|^\d+[.]?\d+$/;
					if (reg.test(value)) {
						callback();
					} else {
						return callback(new Error('仅允许输入数字'));
					}
				}
			};
			//数字校验
			var checkValue2 = (rule, value, callback) => {
				if (!value) {
					return callback(new Error('请输入成本价'));
				} else {
					const reg = /^\d+$|^\d+[.]?\d+$/;
					if (reg.test(value)) {
						callback();
					} else {
						return callback(new Error('仅允许输入数字'));
					}
				}
			};
			////校验手机号，号段主要有(不包括上网卡)：130~139、150~153，155~159，180~189、170~171、    176~178。14号段为上网卡专属号段
			const telReg =
				/^((0\d{2,3}-\d{7,8})|(((13[0-9])|(14[0-9])|(15[0-9])|(16[0-9])|(17[0-9])|(18[0-9])|(19[0-9]))\d{8}))|400[0-9]{7}$/;
			var telValue = regulars.checkRegular(telReg, '请输入联系电话', '联系方式输入不合法!');
			//联系人校验
			const personReg = /^[a-z0-9A-Z_\u4e00-\u9fa5]+$/;
			var personValue = regulars.checkRegular(personReg, '请输入联系人', '允许输入汉字、字母、数字、下划线');
			//详细地址校验
			var addressVerify = (rule, value, callback) => {
				if (value == '') {
					callback(new Error('请输入详细地址'));
				} else if (this.lng == '' || this.lat == '') {
					callback(new Error('请从下拉框中选择详细地址!'));
				} else {
					callback();
				}
			};
			return {
				footWidth: '0px',
				loading: true, //列表loading
				tableData: [],
				searchForm: {
					hospital_name: '',
					limit: limit,
					currentPage: 1
				},
				total: 0,
				totalPage: 0,
				titleName: '',
				addVisible: false,
				addForm: {
					item_name: null,
					item_code: null,
					sale: null,
					cost_price: null,
					company_id: null,
					company_name: null,
					item_desc: null
				},
				companys: [ //生产厂家
					{
						company_id: '',
						company_name: ''
					}
				],
				addFormRules: {
					item_name: [{
						required: true,
						message: '请输入项目服务名称',
						trigger: 'blur'
					}],
					item_code: [{
						required: true,
						message: '请输入项目编码',
						trigger: 'blur'
					}],
					sale: [{
						required: true,
						trigger: 'blur',
						validator: checkValue
					}],
					cost_price: [{
						required: true,
						trigger: 'blur',
						validator: checkValue2
					}],

				},
				type: '', //add,edit
				row: null,
				deptOptions: [], //组织机构树

			}
		},
		mounted() {
			var that = this;
			document.getElementsByClassName('content')[0].onclick = function() {
				if (that.currentChange) {
					that.currentChange = false;
				} else {
					that.setCurrent();
				}

			}
			that.footWidth = that.$refs.table.bodyWidth;
			//获取生产厂家下拉列表
			this.$http.post(BaseUrl.getCompanySelect).then(res => {
				this.companys = res.data.data;
			});
		},
		methods: {
			setCurrent(row) { //取消单选
				//this.$refs.table.setCurrentRow(row);
			},
			handleCurrentChangeT(row, index) {
				this.currentChange = true;
				this.$refs.table.setCurrentRow(row);
			},
			getData() {
				this.loading = true; //列表loading
				this.$http.post(BaseUrl.getItemPageList, this.searchForm).then(res => {
					if (res.data.code == 0) {
						this.tableData = res.data.data.data;
						this.totalPage = res.data.data.totalPage;
						this.total = res.data.data.total;
						this.loading = false; //列表loading
					} else {
						this.$message({
							message: res.data.msg,
							type: 'warning'
						})
					}

				})
			},
			handleSizeChange(val) {
				this.searchForm.limit = val;
				this.getData();
			},
			handleCurrentChange(val) {
				this.searchForm.currentPage = val;
				this.getData();
			},
			reset() {
				this.resetSearchForm(this.searchForm, [{
					k: 'limit',
					v: this.searchForm.limit
				}, {
					k: 'currentPage',
					v: this.searchForm.currentPage
				}]);
				// this.getData();
			},
			showAddVisible(type, row) {
				this.type = type;
				//获取生产厂家下拉列表
				this.$http.post(BaseUrl.getCompanySelect).then(res => {
					this.companys = res.data.data;
				});
				if (type == 'add') {
					this.titleName = "新增服务费信息";
					this.addVisible = true;
					this.addForm = {}
					/*this.listDept().then(res=>{
					    this.deptOptions = this.handleTree(res.data.data,"dept_id");
					    
					});*/
				} else {
					this.titleName = "修改服务费信息";
					this.row = row;
					this.$set(row, 'editLoading', true);
					this.$http.post(BaseUrl.getItemById, {
						id: row.id
					}).then(res => {
						if (res.data.code == 0) {
							this.deptInfo = {
								id: res.data.data.dept_id,
								name: row.dept_name //row.dept_name
							}
							this.addForm = res.data.data;
							this.addVisible = true;
							this.$set(row, 'editLoading', false);
							/*this.listDept().then(res=>{
							    this.deptOptions = this.handleTree(res.data.data,"dept_id");
							    
							});*/
						} else {
							this.$message({
								message: res.data.msg,
								type: 'warning'
							})
						}

					})

				}

			},
			//保存新增/修改
			saveEdit(form, type) {
				this.$refs[form].validate((valid) => {
					if (valid) {
						if (type == 'add') {
							this.$http.post(BaseUrl.addItemInfo, this[form]).then(res => {
								if (res.data.code == 0) {
									this.titleName = '';
									this.getData();
									this.addVisible = false;
									this.$message({
										message: res.data.msg,
										type: 'success'
									})
								} else {
									this.$message({
										message: res.data.msg,
										type: 'warning'
									})
								}
							})
						} else {
							this[form].id = this.row.id;
							this.$http.post(BaseUrl.saveItemById, this[form]).then(res => {
								if (res.data.code == 0) {
									this.titleName = '';
									this.getData();
									this.addVisible = false;
									this.$message({
										message: res.data.msg,
										type: 'success'
									})
								} else {
									this.$message({
										message: res.data.msg,
										type: 'warning'
									})
								}
							})
						}


					}
				})

			},
			deleteStation(row) {
				var that = this;
				this.$confirm('是否确认删除服务费"' + row.item_name + '"的数据项?', "警告", {
					confirmButtonText: "确定",
					cancelButtonText: "取消",
					type: "warning"
				}).then(function() {
					that.$http.post(BaseUrl.delItem, {
						id: row.id
					}).then(res => {
						if (res.data.code == 0) {
							that.getData();
							that.$message({
								message: '删除成功',
								type: 'success'
							})
						} else {
							that.$message({
								message: res.data.msg,
								type: 'warning'
							})
						}

					})

				}).catch(() => {
					this.$message({
						type: 'info',
						message: '已取消删除'
					});
				});
			},

			noClick() {
				this.getData();
				this.addVisible = false;
			},

			getFatherAncestors(val) {}

		}

	}
</script>
