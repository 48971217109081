var serverUrl='/witmedServer3';//说明：  打包后，需要在nginx中做配置，解决跨域问题。前端打包需要配合nginx使用
var title="子曰上医（三终端）";//项目名称 医酷智慧医疗管理平台 仲景学苑
var limit=20;//每页显示条数设置




//生成title
var x = document.createElement("TITLE");
var t = document.createTextNode(title);
x.appendChild(t);
document.head.appendChild(x);
//生成页面ico
var l = document.createElement("LINK");
l.rel="shortcut icon";
l.type="image/x-icon";
l.href=`zj-favicon.ico`
document.head.appendChild(l);