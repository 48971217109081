export function checkRegular1(rule, value, callback,reg,isNullPrompt,errorPrompt) {
	if(!value) {
		return callback(new Error(isNullPrompt));
	} else {
		if(reg.test(value)) {
			callback();
		} else {
			return callback(new Error(errorPrompt));
		}
	}
}

/**普通正则表达式非空和不合法校验
 * reg 正则表达式内容
 * isNullPrompt 非空提示
 * errorPrompt  错误提示
 * @auther zhangkaixuan
 * @data 2020/04/29
 */
export function checkRegular(reg,isNullPrompt,errorPrompt){
	var value = (rule, value, callback) => {
			if(!value) {
				return callback(new Error(isNullPrompt));
			} else {
				if(reg.test(value)) {
					callback();
				} else {
					return callback(new Error(errorPrompt));
				}
			}
	};
	return value;
}
/**普通正则表达式不合法校验
 * reg 正则表达式内容
 * errorPrompt  错误提示
 * @auther zhangkaixuan
 * @data 2020/05/19
 */
export function checkRegular2(reg,errorPrompt){
	var value = (rule, value, callback) => {
		if(!value) {
				callback();
			} else {
				if(reg.test(value)) {
					callback();
				} else {
					return callback(new Error(errorPrompt));
				}
			}
	};
	return value;
}
export default{
	checkRegular,
	checkRegular1,
	checkRegular2
}
