<template>
	<div class="table">
		<div class="container" :style="'height:' + (tableH + 135) + 'px;'">
			<!--搜索框-->
			<el-form :inline="true" :model="searchForm" ref="searchForm">
				<el-form-item prop="time" label="订单时间">
					<dateChoose v-model="searchForm.time" :time="searchForm.time"></dateChoose>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" plain icon="el-icon-search" size="small" @click="getData()">查询</el-button>
					<el-button type="warning" plain icon="el-icon-refresh" size="small" @click="reset()">重置</el-button>

				</el-form-item>
			</el-form>
			<div>
				<div style="float: left;font-size: 14px;width: 100%;">
					统计时间：{{ searchForm.time[0] + '--' + searchForm.time[1] }}</div></br>
				<el-table v-loading="loading" ref="table" :data="tableData" :summary-method="getSummaries" border stripe
					size="mini" style="width: fit-content;" show-summary>
					<el-table-column label="序号" width="60" type="index" show-overflow-tooltip></el-table-column>
					<el-table-column prop="product_name" label="商品名称" width="120" show-overflow-tooltip>
					</el-table-column>
					<el-table-column prop="brand" label="品牌" width="180" show-overflow-tooltip></el-table-column>
					<el-table-column prop="order_num" label="销售数量" width="120" show-overflow-tooltip></el-table-column>
					<el-table-column prop="score" label="积分" width="180" show-overflow-tooltip></el-table-column>
				</el-table>
			</div>

			<!--TODO 分页-->
			<div class="pagination table-width-b" v-if="totalPage != 0"
				:style="'width:' + footWidth + ';max-width:100%;'">
				<el-pagination @size-change="handleSizeChange" :page-sizes="[10, 20, 50]" :page-size="searchForm.limit"
					:current-page.sync="searchForm.currentPage" @current-change="handleCurrentChange"
					layout="jumper, prev, pager, next, sizes, total" :total="total">
				</el-pagination>
			</div>
		</div>
	</div>
</template>
<script>
	// getProductOrderPageList//查询商品订单信息分页列表信息
	// updProductOrderById//商品订单发货
	import BaseUrl from '@/utils/baseUrl';
	import dateChoose from "@/components/date-choose" //时间段选择
	import moment from 'moment'
	export default {
		components: {
			dateChoose
		},
		data() {
			return {
				footWidth: '0px',
				loading: true, //列表loading
				tableData: [],
				searchForm: {
					doctor_name: '',
					product_name: '',
					order_num: '',
					limit: limit,
					currentPage: 1,
					startTime: '',
					endTime: '',
					time: []
				},
				form: {},
				total: 0,
				totalPage: 0,
				type: '', //add,edit
				row: null,
				tableBtnWidth: '100', //页面链接最后一个字段
				dialogVisible: false,
				rule: []
			}
		},
		mounted() {
			//this.getTableProp();
			var that = this;
			that.footWidth = that.$refs.table.bodyWidth;
			this.searchForm.startTime = this.searchForm.time[0];
			this.searchForm.endTime = this.searchForm.time[1];
			this.getData();
		},
		watch: {
			searchForm: {
				handler(newName, oldName) {
					this.searchForm.startTime = newName[0];
					this.searchForm.endTime = newName[1];
					//执行代码
				},
				deep: true
			}
		},
		methods: {

			getData() {
				this.loading = true; //列表loading
				this.searchForm.startTime = this.searchForm.time[0]
				this.searchForm.endTime = this.searchForm.time[1]
				this.$http.post(BaseUrl.getProductOrderPageList, this.searchForm).then(res => {
					if (res.data.code == 0) {
						this.tableData = res.data.data.data;
						
						this.totalPage = res.data.data.totalPage;
						this.total = res.data.data.total;
						this.loading = false; //列表loading
					} else {
						this.$message({
							message: res.data.msg,
							type: 'warning'
						})
					}

				})
			},
			handleSizeChange(val) {
				this.searchForm.limit = val;
				this.getData();
			},
			handleCurrentChange(val) {
				this.searchForm.currentPage = val;
				this.getData();
			},
			reset() {
				this.resetSearchForm(this.searchForm, [{
						k: 'limit',
						v: this.searchForm.limit
					},
					{
						k: 'currentPage',
						v: this.searchForm.currentPage
					},
					{
						k: 'startTime',
						v: moment().subtract(7, 'days').format('YYYY-MM-DD')
					},
					{
						k: 'endTime',
						v: moment().format('YYYY-MM-DD')
					},
					{
						k: 'time',
						v: [moment().subtract(7, 'days').format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')]
					}
				]);
				this.getData();
			},
			showAddVisible(row) {
				this.$http.post(BaseUrl.sendPrintOrder, {
					id: row.id
				}).then(res => {
					if (res.data.code == 0) {
						this.$message({
							message: res.data.msg,
							type: 'success'
						})
					} else {
						this.$message({
							message: res.data.msg,
							type: 'error'
						});
					}
				})
			},
			 getSummaries(param) {
			        const { columns, data } = param;
			        const sums = [];
			        columns.forEach((column, index) => {
			          if (index === 0) {
			            sums[index] = '总价';
			            return;
			          }
			          const values = data.map(item => Number(item[column.property]));
			          if (!values.every(value => isNaN(value))) {
			            sums[index] = values.reduce((prev, curr) => {
			              const value = Number(curr);
			              if (!isNaN(value)) {
			                return prev + curr;
			              } else {
			                return prev;
			              }
			            }, 0);
			          } else {
			            sums[index] = '';
			          }
					  sums[1] = ''
			        });
			
			        return sums;
			      }

		}

	}
</script>
<style lang="scss" scoped>
	.tipsCardBox {
		position: fixed;
		top: 120px;
		right: 20px;
		width: 200px;
		z-index: 9;

		.tit {
			display: flex;
			justify-content: space-between;
			align-items: center;
		}

	}
</style>
