<template>
	<div class="table salespersonPage">
		<div class="container" :style="'height:' + (tableH + 135) + 'px;'">
			<el-form :inline="true" :model="searchForm" ref="searchForm">
				<el-form-item label="商品名称">
					<el-input v-model.trim="searchForm.product_name" size="small" placeholder="请输入商品名称"
						style="width: 250px;"></el-input>
				</el-form-item>
				<el-form-item label="上架状态">
					<el-select v-model.trim="searchForm.state" size="small" placeholder="全部" style="width: 150px;">
						<el-option v-for="item in stateOptions" :key="item.value" :label="item.label"
							:value="item.value">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" plain icon="el-icon-search" size="small" @click="getData()">查询</el-button>
					<el-button type="warning" plain icon="el-icon-refresh" size="small" @click="reset()">重置</el-button>
					<el-button type="success" plain size="small" icon="el-icon-plus" @click="showAddVisible('add')">新增
					</el-button>
				</el-form-item>
			</el-form>
			<el-table v-loading="loading" ref="table" :height="(tableH + 40) + 'px'" :data="tableData" border
				default-expand-all size="mini" style="width: fit-content;">
				<el-table-column  label="序号" type="index" width="100" show-overflow-tooltip></el-table-column>
				<el-table-column prop="product_code" label="商品编码" width="150" show-overflow-tooltip></el-table-column>
				<el-table-column prop="product_name" label="商品名称" width="180" show-overflow-tooltip></el-table-column>
				<el-table-column prop="brand" label="品牌" width="150" show-overflow-tooltip></el-table-column>
				<el-table-column prop="score" label="积分" width="120" show-overflow-tooltip></el-table-column>
				<el-table-column prop="stock_num" label="库存数" width="100" show-overflow-tooltip></el-table-column>
				<el-table-column prop="order_by" label="排序" width="80" show-overflow-tooltip></el-table-column>
				<el-table-column prop="state" label="上架状态" width="100" show-overflow-tooltip>
					<template slot-scope="scope">{{ scope.row.state == '1' ? '上架' : '下架' }}</template>
				</el-table-column>
				
				<el-table-column prop="product_url" label="商品图片" width="120" align="center">
					<template slot-scope="scope">
						<el-popover placement="right" title="" trigger="hover">
						    <img :src="scope.row.product_url" width="160" height="160"/>
						    <img slot="reference" :src="scope.row.product_url" :alt="scope.row.product_url" width="40" height="40"/>
						</el-popover>
					</template>
				</el-table-column>
				
				<el-table-column prop="create_time" label="创建时间" width="180" show-overflow-tooltip>
					<template slot-scope="scope">{{ scope.row.create_time }}</template>
				</el-table-column>
				<el-table-column prop="create_user" label="创建人" width="80" show-overflow-tooltip></el-table-column>
				<el-table-column prop="" label="操作" width="280">
					<template slot-scope="scope">
						<el-button size="mini" type="warning" icon="el-icon-check" plain
							@click="shelfState('upShelf', scope.row)" v-if="scope.row.state == '0'">上架</el-button>
						<el-button size="mini" type="warning" icon="el-icon-close" plain
							@click="shelfState('downShelf', scope.row)" v-if="scope.row.state == '1'">下架</el-button>
						<el-button size="mini" type="primary" icon="el-icon-edit" plain
							@click="showAddVisible('edit', scope.row)" :loading="scope.row.editLoading">编辑</el-button>
						<el-button size="mini" type="danger" plain icon="el-icon-delete" @click="deleteItem(scope.row)">
							删除</el-button>
					</template>
				</el-table-column>
			</el-table>
			<!--新增/修改弹框-->
			<el-dialog :close-on-click-modal="false" :title='titleName' v-if="addVisible" :visible.sync="addVisible"
				 top="20vh" width="700px" :append-to-body="true">
				<el-form :inline="true" ref="addForm" :model="addForm" label-width="100px" :rules="addFormRules">
					<el-form-item label="商品编码" prop="product_code">
						<el-input v-model.trim="addForm.product_code" size="small" placeholder="请输入商品编码"></el-input>
					</el-form-item>
					<el-form-item label="商品名称" prop="product_name">
						<el-input v-model.trim="addForm.product_name" size="small" placeholder="请输入商品名称"></el-input>
					</el-form-item>
					<el-form-item label="品牌" prop="brand">
						<el-input v-model.trim="addForm.brand" size="small" placeholder="请输入品牌"></el-input>
					</el-form-item>
					<el-form-item label="积分" prop="score">
						<el-input v-model.trim="addForm.score"  type="number"  size="small" placeholder="请输入积分"></el-input>
					</el-form-item>
					<el-form-item label="库存数" prop="stock_num">
						<el-input v-model.trim="addForm.stock_num" size="small" placeholder="请输入库存数"></el-input>
					</el-form-item>
					<el-form-item label="排序" prop="order_by">
						<el-input-number v-model.trim="addForm.order_by" style="width: 200px;"  size="small" placeholder="请输入排序号"  :min="1" ></el-input-number>
					</el-form-item>
					<el-form-item label="商品图片" prop="product_url"
						:rules="{ required: true, message: '商品图片不能为空', trigger: 'blur' }">
						<!---->
						<div style="width:510px">
							<el-upload action="#" :class="{ 'hideUpload': hideUpload }" :on-change="uploadImgChange"
								list-type="picture-card" :file-list="addForm.fileList" :on-remove="uploadImgRemove"
								:auto-upload="false">
								<i slot="default" class="el-icon-plus"></i>
							</el-upload>
						</div>
					</el-form-item>
					<el-form-item label="商品介绍" prop="product_rec">
						<div style="width: 500px;">
							<editor v-model.trim="addForm.product_rec"  :min-height="255"  :height="200" :fileSize="2"></editor>
						</div>
							
					</el-form-item>
				</el-form>
				<span slot="footer" class="dialog-footer">
					<el-button size="small" @click="addVisible = false" v-if="type == 'add'">取&nbsp;消</el-button>
					<el-button type="primary" size="small" @click="saveEdit('addForm', type)">保&nbsp;存</el-button>
				</span>
			</el-dialog>
		</div>
	</div>
</template>
<script>
	import BaseUrl from '@/utils/baseUrl';
	export default {
		components: {
		},
		data() {
			return {
				footWidth: '0px',
				loading: true, //列表loading
				tableData: [],
				searchForm: {
					limit: 10,
					currentPage: 1,
					product_name: null,
					state: ''
				},

				stateOptions: [{
						label: '全部',
						value: ''
					},
					{
						label: '上架',
						value: '1'
					},
					{
						label: '下架',
						value: '0'
					},

				],
				titleName: '',
				addVisible: false,
				addForm: {},
				addFormRules: {
					product_name: [{
						required: true,
						message: "请输入商品名称",
						trigger: "blur"
					}],
					product_code: [{
						required: true,
						message: "请输入商品编码",
						trigger: "blur"
					}],
					brand: [{
						required: true,
						message: "请输入品牌",
						trigger: "blur"
					}],
					score: [{
						required: true,
						message: "请输入积分",
						trigger: "blur"
					}],
				},

				type: '', //add,edit,shelf
				row: null,
				hideUpload: false,
				fullscreenLoading: null,
				delFileName:''//删除图片的名称
			}
		},
		created() {
			this.getData()
		},
		mounted() {
			var that = this;
			that.footWidth = that.$refs.table.bodyWidth;
		},
		methods: {
			//列表数据
			getData() {
				this.loading = true;
				this.$http.post(BaseUrl.getProductPageList, this.searchForm).then(res => { //,this.searchForm
					if (res.data.code == 0) {
						this.loading = false;
						if (res.total !== 0) {
							this.tableData = res.data.data.data;
						}

					} else {
						this.$message({
							message: res.data.msg,
							type: 'warning'
						})
					}
				})

			},

			//searchForm重置
			reset() {
				this.resetSearchForm(this.searchForm);
				this.searchForm = {
						limit: 10,
						currentPage: 1,
						product_name: null,
						state: ''
					},
					this.getData();
			},
			//显示新增/编辑弹框
			showAddVisible(type, row) {
				let that = this;
				this.hideUpload = false;
				this.addForm = {
					product_url: '',
					fileList: []
				};
				this.type = type;
				this.row = row;

				if (type == 'add') {
					this.titleName = "新增商品";
					this.hideUpload = false;
					this.addVisible = true;
				} else {
					this.titleName = "修改商品";
					this.$set(row, 'editLoading', true);
					this.openScreenLoading()
					this.$http.post(BaseUrl.getProductById, {
						id: row.id
					}).then(res => {
						if (res.data.code == 0) {
							that.addForm = res.data.data;
							that.addForm.fileList = [];
							if (that.addForm.product_url) {
								var name = that.addForm.product_url.split("witmed/")[1];
								that.addForm.fileList.push({
									name: name,
									url: that.addForm.product_url
								})
								this.delFileName = that.addForm.product_url.split("product/")[1]//给删除的图片命名
								that.hideUpload = true;
							}
							that.addVisible = true;
							that.$set(row, 'editLoading', false);
							this.closeScreenLoading()
						} else {
							this.$message({
								message: res.data.msg,
								type: 'warning'
							})
							this.closeScreenLoading()
						}
					}).catch(error => {
						this.closeScreenLoading()
					})

				}

			},

			//保存新增/修改
			saveEdit(form, type) {
				this.$refs[form].validate((valid) => {
					if (valid) {
						if (type == 'add') {
							this[form].state = '1'
							this.openScreenLoading()
							this.$http.post(BaseUrl.addProductInfo, this[form]).then(res => {
								if (res.data.code == 0) {
									this.titleName = '';
									this.getData();
									this.addVisible = false;
									this.hideUpload = false;
									this.$message({
										message: res.data.msg,
										type: 'success'
									})
									this.closeScreenLoading()
								} else {
									this.$message({
										message: res.data.msg,
										type: 'warning'
									})
									this.closeScreenLoading()
								}
							}).catch(error => {
								this.closeScreenLoading()
							})
						} else {
							this.addForm.id = this.row.id;
							this.openScreenLoading()
							let form = JSON.parse(JSON.stringify(this.addForm));
							this.$http.post(BaseUrl.updProductById, form).then(res => {
								if (res.data.code == 0) {
									this.titleName = '';
									this.getData();
									this.addVisible = false;
									this.hideUpload = false;
									this.$message({
										message: res.data.msg,
										type: 'success'
									})
									this.closeScreenLoading()
								} else {
									this.$message({
										message: res.data.msg,
										type: 'warning'
									})
									this.closeScreenLoading()
								}
							}).catch(error => {
								this.closeScreenLoading()
							})
						}
					}
				})

			},
			//删除商品
			deleteItem(row) {
				var that = this;
				this.$confirm('是否确认删除"' + row.order_by + '"的数据项?', "警告", {
					confirmButtonText: "确定",
					cancelButtonText: "取消",
					type: "warning"
				}).then(function() {
					that.openScreenLoading()
					that.$http.post(BaseUrl.delProduct, {
						id: row.id
					}).then(res => {
						if (res.data.code == 0) {
							that.getData();
							that.$message({
								message: '删除成功',
								type: 'success'
							})
							that.closeScreenLoading()
						} else {
							that.$message({
								message: res.data.msg,
								type: 'warning'
							})
							that.closeScreenLoading()
						}

					})

				}).catch(() => {
					this.$message({
						type: 'info',
						message: '已取消删除'
					});
					that.closeScreenLoading()
				});
			},


			//关闭弹框
			noClick() {
				this.getData();
				this.hideUpload = false;
				this.addVisible = false;
			},
			//上传图片
			uploadImgChange(file, fileList) {
				let that = this;
				console.log(file);
				console.log(fileList)
				const isJPG = file.raw.type === 'image/jpeg' || file.raw.type === 'image/png';
				if (!isJPG) {
					this.$message.error('上传图片只能是JPG或PNG 格式!');
					fileList.splice(fileList.length - 1, 1)
					return false;
				}
				let fileName = file.name.lastIndexOf("."); //取到文件名开始到最后一个点的长度
				let fileNameLength = file.name.length; //取到文件名长度
				let fileFormat = file.name.substring(fileName + 1, fileNameLength); //截
				file.name = new Date().getTime() + '.' + fileFormat;
				this.delFileName = file.name
				var newfile = new File([file.raw], file.name, {
					type: file.raw.type
				});
				this.hideUpload = fileList.length >= 1;
				this.saveImg(newfile, 'img');
			},
			//上传图片
			saveImg(file, type) {
				console.log(type);
				let that = this;
				var fd = new FormData();
				var f = file;
				fd.append('file', f);
				// this.addForm.fileList=[];
				this.$axios.post(BaseUrl.productUploadImg, fd, {
					headers: {
						'Content-Type': 'multipart/form-data'
					}
				}).then(res => {
					if (res.data.code == 0) {
						that.addForm.product_url = res.data.data;
						this.$refs['addForm'].clearValidate(['product_url']);
					} else {
						return false;
					}
				});
			},
			//删除图片
			uploadImgRemove(file) {
				let that = this;
				// file.name = file.url.split("product/")[1];
				// console.log('file.name: ', file.name)
				this.$http.post(BaseUrl.productDelImg, {
					fileName: this.delFileName
				}).then(res => {
					console.log('res: ', res)
					if (res.data.code == 0) {
						that.addForm.fileList = []
						that.hideUpload = false;
						that.addForm.product_url = '';
					} else {
						this.$message({
							message: res.data.msg,
							type: 'warning'
						})
					}
				})
			},
			//上架
			shelfState(state, row) {
				console.log('row: ', row)
				//  downShelf

				if (state == 'downShelf') {
					this.openScreenLoading()
					this.$http.post(BaseUrl.updStateById, {
						id: row.id,
						state: '0'
					}).then(res => {
						if (res.data.code == 0) {
							this.$message({
								message: res.data.msg,
								type: 'success'
							})
							this.closeScreenLoading()
							this.getData()
						} else {
							this.$message({
								message: res.data.msg,
								type: 'warning'
							})
							this.closeScreenLoading()
						}

					}).catch((error) => {
						this.closeScreenLoading()
					})
				} else {
					this.openScreenLoading()
					this.$http.post(BaseUrl.updStateById, {
						id: row.id,
						state: '1'
					}).then(res => {
						if (res.data.code == 0) {
							this.$message({
								message: res.data.msg,
								type: 'success'
							})
							this.closeScreenLoading()
							this.getData()
						} else {
							this.$message({
								message: res.data.msg,
								type: 'warning'
							})
							this.closeScreenLoading()
						}

					}).catch((error) => {
						this.closeScreenLoading()
					})
				}

			},
			//loading open
			openScreenLoading() {
				this.fullscreenLoading = this.$loading({
					lock: true,
					text: 'Loading',
					spinner: 'el-icon-loading',
					background: 'rgba(0, 0, 0, 0.7)'
				});
			},

			//loading close
			closeScreenLoading() {
				this.fullscreenLoading.close();
			},

		}

	}
</script>

<style>

	/* .columnPage .el-table__row.expanded .el-table_1_column_1  {
    font-weight: bold;
    color: #333;;
} */
	.hideUpload .el-upload--picture-card {
		display: none;
	}
</style>
<style scoped>
	.selectWdith {
		width: 200px;
	}
</style>
