"use strict";

import Vue from "vue";
import axios from "axios";
import store from "@/store";
import router from '@/router'
import qs from 'qs'
import { Message } from "element-ui";
import { getToken , getUid ,setToken,setUid } from "@/utils/auth"; 
import Cookies from 'js-cookie'

// Full config:  https://github.com/axios/axios#request-config
// axios.defaults.baseURL = process.env.baseURL || process.env.apiUrl || '';
// axios.defaults.headers.common['Authorization'] = AUTH_TOKEN;
// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
var baseURL = serverUrl;
let config = {
  baseURL:baseURL,
  timeout: 60 * 1000, //timeout
  headers: {
    "Content-Type": "",
    // "Access-Control-Allow-Credentials":true,
    "token":"",
    "uid":"",
    "state":''//1005 token失效
  },
  withCredentials:true,//是否允许携带cookie
};

const configHeaders = {
  json: "application/json;charset=UTF-8",
  form: "application/x-www-form-urlencoded;charset=UTF-8"
};
let exportType = "";
//获取后端名称导出文件的文件名称
let exportTypeFileName = "";
const message = (msg, code) => {
  const messageText = code ? `${msg},错误码${code}` : `${msg}`;
  return Message({
    message: messageText,
    type: "error",
    duration: 5 * 1000
  });
};
const _axios = axios.create(config);
//请求头增加Token信息
let uid='',token='',state='0'
_axios.interceptors.request.use(
  function (option) {
    // Do something before request is sent
    if (store.getters.token&&store.getters.uid) {
      option.headers['token']=store.getters.token;
      option.headers['uid']=store.getters.uid;
      option.headers['state']=state;
    }
    option.headers["Content-Type"] = config.headers["Content-Type"];
    return option;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error)
  }
)
//请求返回拦截信息
// Add a response interceptor
_axios.interceptors.response.use(
  function(response) {
    
    if(response.request.responseURL.indexOf('login')>-1){//登录
      if(response.data.code==0){
        token=response.data.data.token;
        uid=response.data.data.uid;
      }
    }else{
      token=response.headers["token"]
      uid=response.headers["uid"]
      state=response.headers["state"]
    }
    if(response.headers["state"] == 1005){
      Message({
        message:'登录超时',
        type:'error'
      });
      
      router.push('/login');
    }
    //每个接口请求之后获取接口headers中带的token和uid信息
    if(response.data.code == 0){
      store.commit('SET_TOKEN', token);
      store.commit('SET_UID', uid);
      setToken(token);
      setUid(uid);
      Cookies.set('state',state)
    }else if(response.data.code == 1005){
      Message({
        message:response.data.msg,
        type:'error'
      });
      
      router.push('/login');
    }
	return response;
  },
  function(error) {
  if(error.response == undefined){
     console.log('error.response','undefined')
  }
    // location.href = baseURL + '#/login';
    console.log('error');
    console.log(error);
    console.log(JSON.stringify(error));

    return Promise.reject(error)
  }
);
const _http = {
  get: "",
  post: "",
  delete: "",
  put: ""
};
/*
 * @param {String} url  请求地址
 * @param {Object} param 请求参数必须为{},只能有一层
 * @param {String} type  请求头类型为json/form
 */
_http.get = (url, param, type) => {
  
  token=getToken();
  uid=getUid();

  config.headers["Content-Type"] = "application/x-www-form-urlencoded;charset=UTF-8";
  return new Promise((resolve, reject) => {
    _axios({
      url: url,
      method: "get",
      // headers:{'token':token,'uid':uid},
      params: qs.stringify(param)
    })
      .then(res => {
        resolve(res);
      })
      .catch(err => {
        reject(err);
      });
  });
};
/*
 * @param {String} url  请求地址
 * @param {Object} param 请求参数必须为{},只能有一层
 * @param {String} type  请求头类型为json/form
 */
_http.delete = (url, param, type) => {
  config.headers["Content-Type"] = configHeaders[type];
  return new Promise((resolve, reject) => {
    _axios({
      url: url,
      method: "delete",
      data: param
    })
      .then(res => {
        resolve(res);
      })
      .catch(err => {
        reject(err);
      });
  });
};
/*
 * @param {String} url  请求地址
 * @param {Object} param 请求参数必须为{},只能有一层
 * @param {String} type  请求头类型为json/form
 */
_http.post = (url, param, type) => {
  var token=Cookies.get('token');
  var uid=Cookies.get('uid');
  config.headers["Content-Type"] = "application/x-www-form-urlencoded;charset=UTF-8";
  var canshu={
    url: url,
    // headers:{'token':token,'uid':uid},
    method: "post",
    data: qs.stringify(param)
  }
  if(url.indexOf('login')!=-1){
    canshu={
      url: url,
      method: "post",
      data: qs.stringify(param)
    }
  }
  return new Promise((resolve, reject) => {
    _axios(canshu)
      .then(res => {
        resolve(res);
      })
      .catch(err => {
        reject(err);
      });
  });
};
/*
 * @param {String} url  请求地址
 * @param {Object} param 请求参数必须为{},只能有一层
 * @param {String} type  请求头类型为json/form
 */
_http.postFile = (url, param, type) => {
  var token=Cookies.get('token');
  var uid=Cookies.get('uid');
  config.headers["Content-Type"] = "application/x-www-form-urlencoded;charset=UTF-8";
  var canshu={
    url: url,
    // headers:{'token':token,'uid':uid},
    method: "post",
    data: qs.stringify(param),
    responseType: 'arraybuffer'
  }
  if(url.indexOf('login')!=-1){
    canshu={
      url: url,
      method: "post",
      data: qs.stringify(param)
    }
  }
  return new Promise((resolve, reject) => {
    _axios(canshu)
      .then(res => {
        resolve(res);
      })
      .catch(err => {
        reject(err);
      });
  });
};

/*
 * @param {String} url  请求地址
 * @param {Object} param 请求参数必须为{},只能有一层
 * @param {String} type  请求头类型为json/form
 */
_http.put = (url, param, type) => {
  config.headers["Content-Type"] = configHeaders[type];
  return new Promise((resolve, reject) => {
    _axios({
      url: url,
      method: "put",
      data: param
    })
      .then(res => {
        resolve(res);
      })
      .catch(err => {
        reject(err);
      });
  });
};
/*
 * @param {String} url  请求地址
 * @param {Object} param 请求参数必须为{},只能有一层
 * @param {String} type  请求头类型为export
 */
_http.exportFilePost = (url, param, type) => {
  config.headers["Content-Type"] = "application/x-download;charset=utf-8";
  exportType = type;
  return new Promise((resolve, reject) => {
    _axios({
      url: url,
      method: "post",
      data: param,
      responseType: "blob"
    })
      .then(res => {
        if (!res) {
          return;
        }
        let url = window.URL.createObjectURL(res);
        let link = document.createElement("a");
        link.style.display = "none";
        link.href = url;
        link.setAttribute("download", exportTypeFileName);
        document.body.appendChild(link);
        link.click();
        resolve(res);
      })
      .catch(err => {
        reject(err);
      });
  });
};
_http.jsonp = (url,data)=>{
  if(!url)
      throw new Error('url is necessary')
  const callback = 'CALLBACK' + Math.random().toString().substr(9,18)
  const JSONP = document.createElement('script')
        JSONP.setAttribute('type','text/javascript')

  const headEle = document.getElementsByTagName('head')[0]

  let ret = '';
  if(data){
      if(typeof data === 'string')
          ret = '&' + data;
      else if(typeof data === 'object') {
          for(let key in data)
              ret += '&' + key + '=' + encodeURIComponent(data[key]);
      }
      ret += '&_time=' + Date.now();
  }
  JSONP.src = `${url}?callback=${callback}${ret}`;
  return new Promise( (resolve,reject) => {
      window[callback] = r => {
        resolve(r)
        headEle.removeChild(JSONP)
        delete window[callback]
      }
      headEle.appendChild(JSONP)
  })
  
}
Plugin.install = function(Vue) {
  Vue.axios = _axios;
  window.axios = _axios;
  Object.defineProperties(Vue.prototype, {
    $axios: {
      get() {
        return _axios;
      }
    },
    $http: {
      get() {
        return _http;
      }
    }
  });
};

Vue.use(Plugin);
export default {
  $axios: _axios,
  $http: _http
};
