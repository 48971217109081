<!-- 患者信息 -->
<template>
    <div class="table">
        <div class="container"  >
            <!--搜索框-->
        	<el-form :inline="true" :model="searchForm" ref="searchForm">
                <el-form-item label="患者名称">
                    <el-input v-model.trim="searchForm.pati_name " size="small" placeholder="请输入患者名称"></el-input>
			    </el-form-item>
                <el-form-item  label="手机号">
                    <el-input v-model.trim="searchForm.phone" size="small" placeholder="请输入手机号"></el-input>
                </el-form-item>
                <el-form-item label="注册时间">
                    <dateChoose v-model="searchForm.time" :time="searchForm.time"></dateChoose>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" plain icon="el-icon-search" size="small" @click="getData()">查询</el-button>
                    <el-button type="warning" plain icon="el-icon-refresh" size="small" @click="reset()">重置</el-button>
					<el-button type="primary" plain size="small" icon="el-icon-upload2" @click="exportFile()" >导出</el-button>
                </el-form-item>
            </el-form>
            <el-table v-loading="loading" ref="table" highlight-current-row @current-change="handleCurrentChangeT"   :data="tableData" border stripe size="mini" style="width: fit-content;">
				<el-table-column prop="pati_name" label="患者名称" width="140" show-overflow-tooltip></el-table-column>
                <el-table-column prop="phone" label="手机号" width="280" show-overflow-tooltip></el-table-column>
                <el-table-column prop="sex" label="性别" width="100" align="left" show-overflow-tooltip></el-table-column>
                <el-table-column prop="birthday" label="出生日期" width="280" show-overflow-tooltip>
					<template slot-scope="scope">
						<span>{{ parseTime(scope.row.birthday, '{y}-{m}-{d}') }}</span>
					</template>
				</el-table-column>
                <el-table-column prop="age" label="年龄" width="180" show-overflow-tooltip></el-table-column>
                <el-table-column prop="create_time" label="注册时间" width="180" show-overflow-tooltip>
					<template slot-scope="scope">
						<span>{{ parseTime(scope.row.create_time, '{y}-{m}-{d} {h}:{i}:{s}') }}</span>
					</template>
				</el-table-column>
            </el-table>
            <!--TODO 分页-->
            <div class="pagination table-width-b" v-if="totalPage!=0" :style="'width:'+footWidth+';max-width:100%;'">
                <el-pagination 
                    @size-change="handleSizeChange" 
                    :page-sizes="[10,20,50]"
                    :page-size="searchForm.limit"
                    :current-page.sync="searchForm.currentPage"
                    @current-change="handleCurrentChange"
                    layout="jumper, prev, pager, next, sizes, total"
                    :total="total">
                </el-pagination>
            </div>
        </div>
    </div>
</template>
<script>
import BaseUrl from '@/utils/baseUrl';
import detailInfo from "@/components/detail-info";
import print from "@/components/print"
import moment from 'moment'
import dateChoose from "@/components/date-choose"
    export default {
        components: {
            detailInfo,print,dateChoose
        },
        created(){
        },
        data() {
            return {
                footWidth:'0px',
                loading:true,//列表loading
                tableData:[],
                searchForm:{
                    startTime:'',
                    endTime:'',
                    phone:'',
                    pati_name :'',
                    limit:limit,
                    currentPage:1
                },
                total:0,
                totalPage:0,
                titleName:'',
                addVisible:false,
                detVisible:false,
                deviceSpotList:[],//抽检结果列表
                companyOptions:[],
                stateOptions:[],
                addForm:{
                	phy_num:'',
                	phy_company:'',
                	deliver_time:'',
                	id:'',
                },
                addFormRules:{
                    phy_num:[
                        { required: true, message: "发货单号不能为空", trigger: "blur" }
                    ],
                    phy_company:[
                        { required: true, message: "物流公司不能为空", trigger: "blur" }
                    ]
                },
                type:'',//add,edit
                row:null,
                isHisPage:'',
                deptOptions:[],//组织机构树
                deptInfo:{
                    id:'',
                    name:''
                },
                seeInfo:{},
                openRecipe:{},
                showOrder:true,
                orders:[],
                showPrint:false,
                printData:null,
               
            }
        },
        mounted(){
            var that=this;
            document.getElementsByClassName('content')[0].onclick=function(){
                if(that.currentChange){
                    that.currentChange=false;
                }else{
                    that.setCurrent();
                }
            }
            that.footWidth=that.$refs.table.bodyWidth;
            this.searchForm.startTime=this.searchForm.time[0];
            this.searchForm.endTime=this.searchForm.time[1];
            this.getData();
        },
        watch:{
            'searchForm.time':{
                handler(newName,oldName){
                    this.searchForm.startTime=newName[0];
                    this.searchForm.endTime=newName[1];
                    //执行代码
                },
                deep:true 
            }
        },
        methods:{
            setCurrent(row) {//取消单选
                //this.$refs.table.setCurrentRow(row);
            },
            getCompanySelect(){
                this.$http.post(BaseUrl.getCompanySelect).then(res=>{
					if(res.data.code==0){
                        this.companyOptions = res.data.data;
                    }else{
                        this.$message({
                            message: res.data.msg,
                            type: 'warning'
                        })
                    }
		        });
            },
            getStateSelect(){
                this.$http.post(BaseUrl.orderTypeList).then(res=>{
					if(res.data.code==0){
                        this.stateOptions = res.data.data;
                    }else{
                        this.$message({
                            message: res.data.msg,
                            type: 'warning'
                        })
                    }
		        });
            },
            handleCurrentChangeT(row,index){
                this.currentChange=true;
                this.$refs.table.setCurrentRow(row);
            },
            getData(){
                this.loading=true;//列表loading
                this.$http.post(BaseUrl.getPatientPageList,this.searchForm).then(res=>{
                    if(res.data.code==0){
                        this.tableData = res.data.data.data;
                        this.totalPage = res.data.data.totalPage;
                        this.total = res.data.data.total;
                        this.loading=false;//列表loading
                    }else{
                        this.$message({
                            message: res.data.msg,
                            type: 'warning'
                        })
                    }
                    
                })
            },
            handleSizeChange(val){
                this.searchForm.limit = val;
                this.getData();
            },
            handleCurrentChange(val){
                this.searchForm.currentPage = val;
                this.getData();
            },
            reset(){
                this.resetSearchForm(this.searchForm,[
                    {k:'limit',v:this.searchForm.limit},
                    {k:'currentPage',v:this.searchForm.currentPage},
                    {k:'startTime',v:moment().subtract(7, 'days').format('YYYY-MM-DD')},
                    {k:'endTime',v:moment().format('YYYY-MM-DD')},
                    {k:'time',v:[moment().subtract(7, 'days').format('YYYY-MM-DD'),moment().format('YYYY-MM-DD')]}
                    ]);
                    
                // this.getData();
            },
            detailVisible(row){
                var _this = this;
                _this.titleName="订单明细";
                //药品详情
                _this.$http.post(BaseUrl.getOrderBySeeId, {seeId:row.see_id, openId: row.openId}).then(res=>{
                    if(res.data.code==0){
                        _this.seeInfo=res.data.data.seeInfo;
                        _this.openRecipe=res.data.data.openRecipe;
                        _this.orders=res.data.data.orders;
                        _this.detVisible = true;
                        _this.$nextTick(() => {
                            _this.$refs.detailInfo.init();
                        });
                    }else{
                        _this.$message({
                            message: res.data.msg,
                            type: 'error'
                        });
                    }
                })
            },
            showAddVisible(row){
                this.addForm={
                	phy_num:'',
                	phy_company:'',
                	deliver_time:'',
                	id:row.id,
                };
                this.titleName="添加发货信息";
                this.addVisible=true;
            },
            //保存新增/修改
            saveEdit(form,type){ 
                this.$refs[form].validate((valid)=>{
                    if(valid){
                        delete this[form].dept_name;
                        this.$http.post(BaseUrl.saveOrderById,this[form]).then(res=>{
                                if(res.data.code==0){
                                    this.titleName='';
                                    this.getData();
                                    this.addVisible=false;
                                    this.$message({
                                        message: res.data.msg,
                                        type: 'success'
                                    })
                                }else{
                                    this.$message({
                                        message: res.data.msg,
                                        type: 'warning'
                                    })
                                }
                            })
                    }
                })

            },
            noClick(){
                this.getData();
                this.addVisible=false;
            },
            noClick1(){
                this.getData();
                this.detVisible=false;
            },
            //打印
            showPrintFn(row,type){
                this.printType=type;
                
                this.$http.post(BaseUrl.printOrderInfo,{seeId:row.see_id,openId:row.openId}).then(res=>{
                    if(res.data.code==0){
                        this.printData = res.data.data;
                        this.showPrint=true;
                        // this.showPrint=false;
                    }else{
                        this.$message({
                            message: res.data.msg,
                            type: 'warning'
                        })
                    }
                })

            },
            /**
             * 导出
             */
            exportFile() {
            	let obj = this.searchForm;
            	this.$http.postFile(BaseUrl.patientInfoExport, obj, {
            		responseType: 'arraybuffer'
            	}).then(res => {
            		var blob = new Blob([res.data], {
            			type: 'application/vnd.ms-excel;charset=utf-8'
            		});
            		var downloadElement = document.createElement('a');
            		var objectUrl = URL.createObjectURL(blob);
            		downloadElement.href = objectUrl;
            		downloadElement.download = '患者信息报表.xls'; //下载后文件名
            		document.body.appendChild(downloadElement);
            		downloadElement.click(); //点击下载
            		document.body.removeChild(downloadElement); //下载完成移除元素
            		window.URL.revokeObjectURL(objectUrl); //释放掉blob对象
            	}).catch(
            		function(res) {}
            	)
            
            },
            
        }
        
    }
</script>