<template>
  <div>
    <el-dialog :close-on-click-modal="false" :title="titleName" v-if="visible" :visible.sync="visible" width="950px"
               top="30vh" :append-to-body="true" :before-close="noClick">
      <el-form :inline="true" :model="detailForm" ref="detailForm">
        <el-form-item>
          <el-button type="success" plain size="small" icon="el-icon-plus" @click="addDrug()">添加</el-button>
        </el-form-item>
        <el-form-item>
          <el-button type="danger" plain size="small" icon="el-icon-delete" @click="delDrug()">删除</el-button>
        </el-form-item>
        <el-form-item>
          <el-button type="warning" plain size="small" icon="el-icon-delete" @click="delAllDrug()">清空</el-button>
        </el-form-item>
      </el-form>
      <el-table ref="tb" v-loading="loading" :data="tableList" :row-class-name="rowClassName"
                @selection-change="handleDetailSelectionChange">
        <el-table-column type="selection" width="30" align="center"/>
        <el-table-column label="序号" align="center" prop="xh" width="50"></el-table-column>
        <el-table-column label="药品名称" width="250" prop="drug_name">
          <template slot-scope="scope">
            <el-select
                filterable
                :filter-method="fileterMethod"
                @change="changeDrugName(scope.row)"
                v-model="tableList[scope.row.xh-1].drug_name"
            >
              <el-option
                  v-for="drug in drugOptions"
                  :key="drug.drug_name"
                  :label="drug.drug_name"
                  :value="drug.drug_name"
                  :disabled="drug.checked"
              />
            </el-select>
          </template>
        </el-table-column>
        <!--                <el-table-column label="药品分类" align="center" prop="drug_type" width="150"></el-table-column>-->
        <el-table-column label="药品规格" align="center" prop="spec" width="150"></el-table-column>
        <el-table-column label="药品单位" align="center" prop="unit" width="150"></el-table-column>
        <el-table-column label="药品数量" width="250" prop="quantity">
          <template slot-scope="scope">
            <el-input v-model.trim="tableList[scope.row.xh-1].quantity" size="small"
                      oninput="value=value.replace(/[^\d]/g,'')" placeholder="请输入数量"></el-input>
          </template>
        </el-table-column>
      </el-table>
      <div style="margin-top:20px; text-align:right">
        <el-button size="small" @click="noClick">取消</el-button>
        <el-button type="primary" size="small" @click="save">保存</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import BaseUrl from '@/utils/baseUrl';

export default {
  props: {
    //标题
    titleName: {
      type: String,
      default: ''
    },
    //处方ID
    recipeId: {
      type: String,
      default: ''
    },

  },
  data() {
    return {
      visible: false,
      detailForm: {},
      loading: false,
      tableList: [],
      drugOptions: [],//药品下拉选
      drugOptionsCopy: [],//防止筛选以后造成数据丢失
      checkedDetail: [],
    }
  },
  created() {
    console.log(this.recipeId)
  },
  mounted() {
  },
  methods: {
    init(data) {
      this.visible = true;
      this.tableList = data;
      this.getDrugSelect();
    },
    //关闭弹窗
    noClick() {
      this.visible = false;
      this.$emit('refreshTable');
    },
    //获取下拉选
    getDrugSelect() {
      this.$http.post(BaseUrl.getDrugSelect, {}).then(res => {
        if (res.data.code == 0) {
          const options = this.tableList.map(item => item['drug_name']);
          this.drugOptionsCopy = res.data.data;
          this.drugOptionsCopy.forEach(item => {
            if (options.includes(item.drug_name)) {
              item.checked = true;//默认true为不可选择状态
            } else {
              item.checked = false;//默认false为可选择状态
            }
          })
          this.drugOptions = JSON.parse(JSON.stringify(this.drugOptionsCopy));//深拷贝
        } else {
          this.$message({
            message: res.data.msg,
            type: 'warning'
          })
        }
      })
    },
    //药品可通过输入药品编码、名称、简拼来筛选选项，并且为可选择状态
    fileterMethod(val) {
      if (val) {
        this.drugOptions = this.drugOptionsCopy.filter(item => {
          if ((!!~item.drug_name.indexOf(val) || !!~item.drug_jp.toUpperCase().indexOf(val.toUpperCase())) && !item.checked) {
            return true
          }
        })
      } else {
        this.drugOptions = JSON.parse(JSON.stringify(this.drugOptionsCopy));//深拷贝
      }
    },
    //增加药品
    addDrug() {
      if (this.tableList == undefined) {
        this.tableList = new Array();
      }
      let obj = {
        drug_name: '',
        drug_jp: '',
        unit: 'g',
        spec: '',
        quantity: '1',
        price: '',
        recipe_id: ''
      };
      this.tableList.push(obj);
    },
    //序号自增
    rowClassName({row, rowIndex}) {
      row.xh = rowIndex + 1;
    },
    //选择table复选框触发
    handleDetailSelectionChange(selection) {
      this.checkedDetail = selection;
    },
    //删除
    delDrug() {
      if (this.checkedDetail.length == 0) {
        this.$alert("请先选择要删除的数据", "提示", {
          confirmButtonText: "确定"
        });
      } else {
        this.checkedDetail.forEach((item1, index) => {
          this.tableList.splice(this.tableList.findIndex(item2 => item1.xh === item2.xh), 1);
          //把删除的药品恢复成可选择
          this.drugOptions = this.drugOptionsCopy.filter(item => {
            if (item.drug_name == item1.drug_name) {
              item.checked = false;
            }
            return true;
          })
        })
      }
    },
    //清空
    delAllDrug() {
      this.tableList = [];
      this.drugOptions = this.drugOptionsCopy.filter(item => {
        item.checked = false;
        return true;
      })
    },
    //选择药品
    changeDrugName(row) {
      console.log(row);
      const option = this.drugOptionsCopy.filter(item => {
        if (item.drug_name== row.drug_name && !item.checked) {
          item.checked = true;
          return true;
        }
      })
      row.drug_name = option[0].drug_name;
      row.drug_jp = option[0].drug_jp;
      row.unit = 'g';
      row.spec = option[0].spec;
      row.price = option[0].price;
      row.recipe_id = this.recipeId;
      //用于在一行里切换药品，把上一个选中的药品恢复为可选择
      this.drugOptions = this.drugOptionsCopy.filter(item => {
        if (item.drug_name != row.drug_name && item.checked) {
          const options = this.tableList.map(item => item['drug_name']);
          let bool = false;
          //判断是否有其他行选择了该药品
          if (item.checked && !options.includes(item.drug_name)) {
            bool = true;
          }
          //如果其他行没有选择该药品，则将其状态恢复成可选择状态
          if (bool) {
            item.checked = false;
          }
        }
        return true;
      })
    },
    //保存提交
    save() {
      var list = JSON.parse(JSON.stringify(this.tableList));
      for (let row of list) {
        //校验tableList中是否有空行，删掉
        if ((row.drug_name == null || row.drug_name == '' || row.drug_name == undefined)
            && (row.quantity == null || row.quantity == '' || row.quantity == undefined)) {
          list.splice(row.xh - 1, 1);
          continue;
        }

        if (row.quantity == null || row.quantity == '' || row.quantity == undefined) {
          this.$alert("第" + row.xh + "行未填写数量，请填写或删除该行药品后，方可保存！", "提示", {
            confirmButtonText: "确定",
          });
          return;
        }
      }
      //保存提交
      this.$http.post(BaseUrl.saveRecipeDetails, {'recipeDetails': JSON.stringify(list)}).then(res => {
        if (res.data.code == 0) {
          this.$message({
            message: res.data.msg,
            type: 'success'
          })
          this.visible = false;
          this.$emit('refreshTable');
        } else {
          this.$message({
            message: res.data.msg,
            type: 'error'
          })
        }
      })
    }
  }
}
</script>
<style scoped>

</style>
