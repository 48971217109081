<template>
    <div class="table"  style="min-width:1340px;" :style="{'background-image':`url(${publicPath}logo/zj-home-bg.jpg)`}">
        <div >
        </div>
    </div>
</template>

<script>
import options from '@/utils/country-data.js';
import regulars from '@/utils/regulars.js';
import BaseUrl from '@/utils/baseUrl';
import moment from 'moment'
moment.locale('zh-cn');
    
    export default {
        components:{
        },
        data() {
        	return {
                c1: false,
            }
        },
        created() {
            var that=this;
        },
        mounted() {
            var that=this;
            
        },
        methods: {
        	
        }
        
    }

</script>


<style scoped lang="scss">
    
    .table{
        width: 100%;
        background-size: cover;
    }
</style>