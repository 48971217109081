<template>
	<div class="table">
		<div class="container">
			<!--搜索框-->
			<el-form :inline="true" :model="searchForm" ref="searchForm">
				<!--				<el-form-item label="医院名称">-->
				<!--					<el-input v-model.trim="searchForm.hospital_name" size="small" placeholder="请输入医院名称"></el-input>-->
				<!--				</el-form-item>-->
				<el-form-item label="医生名称">
					<el-input v-model.trim="searchForm.doctor_name" size="small" placeholder="请输入医生名称" clearable></el-input>
				</el-form-item>
				<el-form-item label="处方分类">
					<el-select v-model.trim="searchForm.recipe_type" placeholder="请选择处方分类" size="small" clearable>
						<el-option v-for="(item,index) in recipeTypes" :key="index" :label="item.name" :value="item.id">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="处方名称">
					<el-input v-model.trim="searchForm.recipe_name" size="small" placeholder="请输入处方名称" clearable></el-input>
				</el-form-item>
				<el-form-item label="科室">
					<el-select v-model.trim="searchForm.dept_id" placeholder="请选择科室" size="small" clearable>
						<el-option v-for="(item,index) in deptOptions" :key="index" :label="item.dept_name"
							:value="item.dept_id">
						</el-option>
					</el-select>

				</el-form-item>
				<el-form-item label="病症">
					<el-input v-model.trim="searchForm.disease" size="small" placeholder="请输入病症" clearable></el-input>
				</el-form-item>

				<!--				<el-form-item label="药品分类">-->
				<!--					<el-select v-model.trim="searchForm.drug_type" placeholder="请选择药品分类" size="small">-->
				<!--						<el-option v-for="(item,index) in drugTypes" :key="index" :label="item.name" :value="item.id">-->
				<!--						</el-option>-->
				<!--					</el-select>-->
				<!--				</el-form-item>-->
				<!--				<el-form-item label="药房名称">-->
				<!--					<el-select v-model="searchForm.company_id" filterable size="small" clearable-->
				<!--						placeholder="请选择药房名称">-->
				<!--						<el-option v-for="item in companyOptions" :key="item.company_id" :label="item.company_name"-->
				<!--							:value="item.company_id">-->
				<!--						</el-option>-->
				<!--					</el-select>-->
				<!--				</el-form-item>-->
				<el-form-item>
					<el-button type="primary" plain icon="el-icon-search" size="small" @click="getData()">查询</el-button>
					<el-button type="warning" plain icon="el-icon-refresh" size="small" @click="reset()">重置</el-button>
					<el-button type="success" plain size="small" icon="el-icon-plus"
						@click="showAddVisible('add')">添加处方</el-button>
				</el-form-item>
			</el-form>
			<el-table v-loading="loading" ref="table" highlight-current-row @current-change="handleCurrentChangeT"
				:data="tableData" border stripe size="mini" style="width: fit-content;">
				<el-table-column prop="recipe_type" label="处方分类" width="130" show-overflow-tooltip
					:formatter="formatRecipeType"></el-table-column>
				<!--				<el-table-column prop="drug_type" label="药品分类" width="130" show-overflow-tooltip></el-table-column>-->
				<el-table-column prop="recipe_name" label="处方名称" width="180" show-overflow-tooltip></el-table-column>
				<el-table-column prop="dept_name" label="科室" width="110" show-overflow-tooltip></el-table-column>
				<el-table-column prop="disease" label="病症" width="130" show-overflow-tooltip></el-table-column>
				<el-table-column prop="take_rule" label="服用方式" width="130" show-overflow-tooltip></el-table-column>
				<el-table-column prop="effect" label="处方功效" width="500" show-overflow-tooltip></el-table-column>
				<el-table-column prop="doctor_name" label="所属医生" width="130" show-overflow-tooltip
					:formatter="formatDoctorName"></el-table-column>
				<el-table-column prop="" label="操作" width="300">
					<template slot-scope="scope">
						<el-button size="mini" type="warning" icon="el-icon-view" plain
							@click="showDetail(scope.row)">处方明细</el-button>
						<el-button size="mini" type="primary" icon="el-icon-edit" plain
							@click="showAddVisible('edit',scope.row)" :loading="scope.row.editLoading">编辑</el-button>
						<el-button size="mini" type="danger" plain icon="el-icon-delete"
							@click="deleteStation(scope.row)">删除</el-button>
					</template>
				</el-table-column>
			</el-table>
			<!--TODO 分页-->
			<div class="pagination table-width-b" v-if="totalPage!=0" :style="'width:'+footWidth+';max-width:100%;'">
				<el-pagination @size-change="handleSizeChange" :page-sizes="[10,20,50]" :page-size="searchForm.limit"
					:current-page.sync="searchForm.currentPage" @current-change="handleCurrentChange"
					layout="jumper, prev, pager, next, sizes, total" :total="total">
				</el-pagination>
			</div>
			<!--新增/修改弹框-->
			<el-dialog :close-on-click-modal="false" :title='titleName' v-if="addVisible" :visible.sync="addVisible"
				width="600px" top="20vh" :append-to-body="true" :before-close="noClick">
				<el-form ref="addForm" :model="addForm" label-width="100px" :rules="addFormRules">
					<el-form-item label="处方名称" prop="recipe_name">
						<el-input v-model.trim="addForm.recipe_name" size="small" placeholder="请输入处方名称"></el-input>
					</el-form-item>
					<el-form-item label="处方分类" prop="recipe_type">
						<el-select v-model.trim="addForm.recipe_type" placeholder="请选择处方分类" size="small"
							style="width:460px" disabled>
							<el-option v-for="(item,index) in recipeTypes" :key="index" :label="item.name"
								:value="item.id">
							</el-option>
						</el-select>
						<!-- <el-input v-model.trim="addForm.recipe_type" size="small" disabled></el-input> -->
					</el-form-item>
					<!--					<el-form-item label="药品分类" prop="drug_type">-->
					<!--						<el-select v-model.trim="addForm.drug_type" @change="changeDrug_type" placeholder="请选择药品分类" size="small"-->
					<!--							style="width:460px">-->
					<!--							<el-option v-for="(item,index) in drugTypes" :key="index" :label="item.name"-->
					<!--								:value="item.id">-->
					<!--							</el-option>-->
					<!--						</el-select>-->
					<!--					</el-form-item>-->

					<!--					<el-form-item prop="company_name" label="所属药房">-->
					<!--						<el-select v-model="addForm.company_name"  :disabled="!addForm.drug_type" placeholder="请先选择药品类别"-->
					<!--							size="small" filterable class="selectWdith">-->
					<!--							<el-option v-for="item in companys"  :key="item.company_id" :label="item.company_name"-->
					<!--								:value="item.company_name">-->
					<!--							</el-option>-->
					<!--						</el-select>-->
					<!--					-->
					<!--					</el-form-item>-->

					<el-form-item label="科室" prop="dept_name">
						<el-select v-model.trim="addForm.dept_id" placeholder="请选择科室" size="small" style="width:460px">
							<el-option v-for="(item,index) in deptOptions" :key="index" :label="item.dept_name"
								:value="item.dept_id">
							</el-option>
						</el-select>
					</el-form-item>

					<el-form-item label="病症" prop="disease">
						<el-input v-model.trim="addForm.disease" size="small" placeholder="请输入病症"></el-input>
					</el-form-item>

					<el-form-item label="服用方式" prop="take_rule">
						<el-input v-model.trim="addForm.take_rule" size="small" placeholder="请输入服务方式"></el-input>
					</el-form-item>
					<el-form-item label="处方功效" prop="effect">
						<el-input v-model.trim="addForm.effect" size="small" placeholder="请输入处方功效"></el-input>
					</el-form-item>
					<el-form-item label="处方描述">
						<el-input v-model.trim="addForm.recipe_remark" type="textarea" :rows="5" maxlength="500"
							size="small" placeholder="请输入处方描述，限制500字符以内"></el-input>
					</el-form-item>
				</el-form>
				<span slot="footer" class="dialog-footer">
					<el-button size="small" @click="noClick()">取&nbsp;消</el-button>
					<el-button type="primary" size="small" @click="saveEdit('addForm',type)">保&nbsp;存</el-button>
				</span>
			</el-dialog>
			<!--处方明细弹框（方案一）-->
			<!-- <el-dialog :close-on-click-modal="false" :title='titleName' v-if="detailVisible" :visible.sync="detailVisible" width="900px" top="20vh" :append-to-body="true" :before-close="noClick1">
			    <el-form :inline="true" :model="detailForm" ref="detailForm">
                <el-form-item>
					<el-button type="success" plain size="small" icon="el-icon-plus" @click="addDrug('add')" >添加药品</el-button>
                </el-form-item>
            </el-form>
            <el-table v-loading="loading" ref="table" highlight-current-row @current-change="handleCurrentChangeT"  :data="DetableData" border stripe size="mini" style="width: fit-content;">
                <el-table-column prop="drug_type" label="药品分类" width="200" show-overflow-tooltip></el-table-column>
                <el-table-column prop="drug_name" label="药品名称" width="200" show-overflow-tooltip></el-table-column>
                <el-table-column prop="spec" label="药品规格" width="200" show-overflow-tooltip></el-table-column>
                <el-table-column prop="quantity" label="药品数量" width="100" show-overflow-tooltip></el-table-column>
                <el-table-column prop="" label="操作" width="100">
                    <template slot-scope="scope">
						<el-button size="mini" type="danger" plain icon="el-icon-delete" @click="deleteDrug(scope.row)">删除</el-button>
                    </template>
                </el-table-column>
            </el-table> -->
			<!--TODO 分页-->
			<!-- <div class="pagination table-width-b" v-if="detailtotalPage!=0" style="width:1246px;max-width:100%;">
                <el-pagination
                    @size-change="handleSizeChange1"
                    :page-sizes="[10,20,50]"
                    :page-size="detailForm.limit"
                    :current-page.sync="detailForm.currentPage"
                    @current-change="handleCurrentChange1"
                    layout="jumper, prev, pager, next, sizes, total"
                    :total="detailtotal">
                </el-pagination>
            </div>
			</el-dialog> -->
			<!--新增药品详情弹框-->
			<!-- <el-dialog :close-on-click-modal="false" :title='titleName' v-if="drugVisible" :visible.sync="drugVisible" width="600px" top="20vh" :append-to-body="true" :before-close="noClick2">
			    <el-form  ref="drugForm" :model="drugForm" label-width="100px" :rules="addDrugFormRules">
                    <el-form-item  label="药品编码" prop="drug_code">
                        <el-input v-model.trim="drugForm.drug_code" size="small" placeholder="请输入药品编码"></el-input>
                    </el-form-item>
                    <el-form-item  label="药品简称" prop="drug_jp">
                        <el-input v-model.trim="drugForm.drug_jp" size="small" placeholder="请输入药品简称"></el-input>
                    </el-form-item>
                    <el-form-item  label="药品分类" prop="drug_type">
                        <el-input v-model.trim="drugForm.drug_type" size="small" placeholder="请输入药品分类"></el-input>
                    </el-form-item>
                    <el-form-item  label="药品名称" prop="drug_name">
                        <el-input v-model.trim="drugForm.drug_name" size="small" placeholder="请输入药品名称"></el-input>
                    </el-form-item>
                    <el-form-item  label="所属药厂名称" prop="company_name">
                        <el-input v-model.trim="drugForm.company_name"  size="small" placeholder="请输入所属药厂名称"></el-input>
                    </el-form-item>
                    <el-form-item  label="药品单位" prop="unit">
                        <el-input v-model.trim="drugForm.unit" size="small" placeholder="请输入药品单位"></el-input>
                    </el-form-item>
                    <el-form-item  label="药品规格" prop="spec">
                        <el-input v-model.trim="drugForm.spec" size="small" placeholder="请输入药品规格"></el-input>
                    </el-form-item>
                    <el-form-item  label="药品数量" prop="quantity">
                        <el-input v-model.trim="drugForm.quantity" size="small" placeholder="请输入药品数量"></el-input>
                    </el-form-item>
                    <el-form-item  label="药品单价" prop="price">
                        <el-input v-model.trim="drugForm.price" size="small" placeholder="请输入药品单价"></el-input>
                    </el-form-item>
                </el-form>
			    <span slot="footer" class="dialog-footer">
                    <el-button size="small" @click="noClick2()">取&nbsp;消</el-button>
			        <el-button type="primary" size="small" @click="saveDrug('drugForm')">保&nbsp;存</el-button>
			    </span>
			</el-dialog> -->

			<!-- 处方明细弹框（方案二） -->
			<recipe-detail v-if="detailVisible" ref="detailInfo" :titleName="titleName" :recipeId="recipeId"
				@refreshTable="getData"></recipe-detail>
		</div>
	</div>
</template>
<script>
	import BaseUrl from '@/utils/baseUrl';
	import recipeDetail from "@/components/recipe-detail";
	export default {
		components: {
			recipeDetail
		},
		created() {
			this.getData()
			// this.getDrugTypeList()
			this.getDeptOptions()
		},
		data() {
			return {
				footWidth: '0px',
				recipeRow: null, //处方row
				loading: true, //列表loading
				tableData: [],
				DetableData: [],
				searchForm: {
					hospital_name: '',
					doctor_name: '',
					recipe_type: '',
					dept_id: '',
					disease: '',
					recipe_name: '',
					limit: limit,
					currentPage: 1
				},
				detailForm: {
					limit: limit,
					currentPage: 1
				},
				detailtotal: 0,
				detailtotalPage: 0,
				total: 0,
				totalPage: 0,
				titleName: '',
				addVisible: false,
				detailVisible: false,
				drugVisible: false,
				addForm: {},
				recipeTypes: [{
						name: '协定处方',
						id: '0'
					},
					{
						name: '自定义处方',
						id: '1'
					},
				],
				drugTypes: [

				],
				drugForm: {
					recipe_id: '',
					drug_code: '',
					drug_jp: '',
					drug_type: '',
					drug_name: '',
					company_name: '',
					unit: '',
					spec: '',
					quantity: '',
					price: '',
				},
				addFormRules: {
					recipe_name: [{
						required: true,
						message: "请输入处方名称",
						trigger: "blur"
					}],
					recipe_type: [{
						required: true,
						message: "请输入处方分类",
						trigger: "blur"
					}],
					// drug_type: [{
					// 	required: true,
					// 	message: "请输入药品分类",
					// 	trigger: "blur"
					// }],
					take_rule: [{
						required: true,
						message: "请输入服用方式",
						trigger: "blur"
					}],
					effect: [{
						required: true,
						message: "请输入处方功效",
						trigger: "blur"
					}],
				},
				addDrugFormRules: {
					drug_code: [{
						required: true,
						message: "请输入药品编码",
						trigger: "blur"
					}],
					drug_name: [{
						required: true,
						message: "请输入药品名称",
						trigger: "blur"
					}],
					drug_type: [{
						required: true,
						message: "请输入药品类型",
						trigger: "blur"
					}],

					unit: [{
						required: true,
						message: "请输入计量单位",
						trigger: "blur"
					}],
					spec: [{
						required: true,
						message: "请输入售价",
						trigger: "blur"
					}],
					quantity: [{
						required: true,
						message: "请输入药品数量",
						trigger: "blur"
					}],
					price: [{
						required: true,
						message: "请输入药品单价",
						trigger: "blur"
					}],
				},
				type: '', //add,edit
				row: null,
				deptOptions: [], //组织机构树
				deptInfo: {
					id: '',
					name: ''
				},
				recipeId: '', //修改处方明细的处方ID
				drugType: '', //处方明细的药品类型
				companyOptions: [],
				companys: [ //生产厂家
					{
						company_id: '',
						company_name: ''
					}
				],
				takeRuleOptions: [{
						lable: '一天一剂',
						value: '一天一剂'
					},
					{
						lable: '一天两剂',
						value: '一天两剂'
					},
					{
						lable: '一天三剂',
						value: '一天三剂'
					},
					{
						lable: '一天一剂 分三次服',
						value: '一天一剂 分三次服'
					},
					{
						lable: '两天一剂 分四次服',
						value: '两天一剂 分四次服'
					},
					{
						lable: '三天两剂 分六次服',
						value: '三天两剂 分六次服'
					},
					{
						lable: '一天一剂 分两次服',
						value: '一天一剂 分两次服'
					},
					/*{lable:'一天三剂',value:'一天三剂'},*/
				],
			}
		},
		mounted() {
			//this.getTableProp();
			var that = this;
			// document.getElementsByClassName('content')[0].onclick = function() {
			// 	if (that.currentChange) {
			// 		that.currentChange = false;
			// 	} else {
			// 		that.setCurrent();
			// 	}
			//
			// }
			// that.getCompanySelect();
			that.footWidth = that.$refs.table.bodyWidth;
		},
		methods: {
			//get药品类型下拉
			getDrugTypeList() {
				this.$http.post(BaseUrl.getDrugTypePageList, {}).then(res => {
					if (res.data.code == 0) {
						this.drugTypes = []
						for (let i = 0; i < res.data.data.length; i++) {
							var obj = {
								name: '',
								id: ''
							} //用于接收被改变的对象
							obj.name = res.data.data[i].type_name
							obj.id = res.data.data[i].type_name
							this.drugTypes.push(obj)
						}
					} else {
						this.$message({
							message: res.data.msg,
							type: 'warning'
						})
					}
				})
			},
			getDeptOptions() {
				this.$http.post(BaseUrl.getDeptSelect).then(res => {
					if (res.data.code == 0) {
						this.deptOptions = res.data.data;
					} else {
						this.$message({
							message: res.data.msg,
							type: 'warning'
						})
					}
				});
			},
			getCompanySelect() {
				this.$http.post(BaseUrl.getCompanySelect).then(res => {
					if (res.data.code == 0) {
						this.companyOptions = res.data.data;
					} else {
						this.$message({
							message: res.data.msg,
							type: 'warning'
						})
					}
				});
			},
			changeDrug_type(value) {
				console.log('更改了类型:' + value)
				console.log('this.addForm.company_name: ', this.addForm.company_name)

				//获取所属药房下拉列表
				this.$http.post(BaseUrl.getCompanySelect, {
					type: value
				}).then(res => {
					if (res.data.code == 0) {
						this.companys = []
						this.$set(this.addForm, 'company_name', null)
						this.companys = res.data.data;

					}
				});

			},
			formatRecipeType(row, column, cellValue, index) {
				if (row.recipe_type == '0') {
					return '协定处方'
				} else {
					return '自定义处方'
				}
			},
			formatDoctorName(row, column, cellValue, index) {
				if (row.recipe_type == '0') {
					return "无";
				} else {
					return row.doctor_name;
				}
			},
			setCurrent(row) { //取消单选
				/*this.$refs.table.setCurrentRow(row);*/
			},
			handleCurrentChangeT(row, index) {
				/*this.currentChange=true;
				this.$refs.table.setCurrentRow(row);*/
			},
			getData() {
				this.loading = true; //列表loading
				this.$http.post(BaseUrl.getRecipePageList, this.searchForm).then(res => {
					if (res.data.code == 0) {
						this.tableData = res.data.data.data;
						this.totalPage = res.data.data.totalPage;
						this.total = res.data.data.total;
						this.loading = false; //列表loading
					} else {
						this.$message({
							message: res.data.msg,
							type: 'warning'
						})
					}

				})
			},
			handleSizeChange1(val) {
				this.detailForm.limit = val;
				this.showDetail(this.recipeRow);
			},
			handleCurrentChange1(val) {
				this.detailForm.currentPage = val;
				this.showDetail(this.recipeRow);
			},
			handleSizeChange(val) {
				this.searchForm.limit = val;
				this.getData();
			},
			handleCurrentChange(val) {
				this.searchForm.currentPage = val;
				this.getData();
			},
			reset() {
				this.resetSearchForm(this.searchForm, [{
					k: 'limit',
					v: this.searchForm.limit
				}, {
					k: 'currentPage',
					v: this.searchForm.currentPage
				}]);
				// this.getData();
			},
			showAddVisible(type, row) {
				this.addForm = {};
				this.deptInfo = {
					id: '',
					name: '' //row.dept_name
				}
				this.type = type;
				if (type == 'add') {
					this.titleName = "添加处方信息";
					this.addVisible = true;
					this.addForm.recipe_type = "0";
					this.getDrugTypeList()
				} else {
					this.titleName = "修改处方信息";
					this.row = row;
					this.$set(row, 'editLoading', true);
					this.$http.post(BaseUrl.getRecipeById, {
						id: row.id
					}).then(res => {
						if (res.data.code == 0) {
							this.deptInfo = {
								id: res.data.data.dept_id,
								name: row.dept_name //row.dept_name
							}
							this.addForm = res.data.data;
							this.addVisible = true;
							this.$set(row, 'editLoading', false);
						} else {
							this.$message({
								message: res.data.msg,
								type: 'warning'
							})
						}

					})

				}

			},
			showDetail(row) {
				this.titleName = "编辑处方明细信息";
				this.recipeRow = row;
				this.recipeId = row.id;
				this.drugType = row.drug_type
				this.$http.post(BaseUrl.getRecipeDetailById, {
					id: row.id
				}).then(res => {
					if (res.data.code == 0) {
						this.detailtotalPage = res.data.data.totalPage;
						this.detailtotal = res.data.data.total;
						this.DetableData = res.data.data;
						this.detailVisible = true;
						this.$nextTick(() => {
							this.$refs.detailInfo.init(this.DetableData);
						});
					} else {
						this.$message({
							message: res.data.msg,
							type: 'warning'
						})
					}

				})
			},
			addDrug(type, row) {
				this.drugForm = {
					recipe_id: this.recipeRow.id,
					drug_code: '',
					drug_jp: '',
					drug_type: '',
					drug_name: '',
					company_name: '',
					unit: '',
					spec: '',
					quantity: '',
					price: '',
				};
				this.titleName = "新增药品";
				this.drugVisible = true;
			},
			saveDrug(form) {
				this.$refs[form].validate((valid) => {
					if (valid) {
						this.$http.post(BaseUrl.addRecipeDetail, this[form]).then(res => {
							if (res.data.code == 0) {
								this.titleName = '';
								this.showDetail(this.recipeRow);
								this.drugVisible = false;
								this.$message({
									message: res.data.msg,
									type: 'success'
								})
							} else {
								this.$message({
									message: res.data.msg,
									type: 'warning'
								})
							}
						})
					}
				})

			},
			//保存新增/修改
			saveEdit(form, type) {
				this.$refs[form].validate((valid) => {
					if (valid) {
						for (var i = 0; i < this.companys.length; i++) {
							if (this.companys[i].company_name == this[form].company_name) {
								this[form].company_id = this.companys[i].company_id
							}
						}
						// delete this[form].dept_name;
						if (type == 'add') {
							this.$http.post(BaseUrl.addRecipeInfo, this[form]).then(res => {
								if (res.data.code == 0) {
									this.titleName = '';
									this.getData();
									this.addVisible = false;
									this.$message({
										message: res.data.msg,
										type: 'success'
									})
								} else {
									this.$message({
										message: res.data.msg,
										type: 'warning'
									})
								}
							})
						} else {
							this[form].id = this.row.id;
							this.$http.post(BaseUrl.saveRecipeById, this[form]).then(res => {
								if (res.data.code == 0) {
									this.titleName = '';
									this.getData();
									this.addVisible = false;
									this.$message({
										message: res.data.msg,
										type: 'success'
									})
								} else {
									this.$message({
										message: res.data.msg,
										type: 'warning'
									})
								}
							})
						}


					}
				})

			},
			deleteDrug(row) {
				var that = this;
				this.$confirm('是否确认删除"' + row.recipe_name + '"的数据项?', "警告", {
					confirmButtonText: "确定",
					cancelButtonText: "取消",
					type: "warning"
				}).then(function() {
					that.$http.post(BaseUrl.delRecipeDetail, {
						id: row.id
					}).then(res => {
						if (res.data.code == 0) {
							that.showDetail(this.recipeRow);
							that.$message({
								message: '删除成功',
								type: 'success'
							})
						} else {
							that.$message({
								message: res.data.msg,
								type: 'warning'
							})
						}

					})

				}).catch(() => {
					this.$message({
						type: 'info',
						message: '已取消删除'
					});
				});
			},
			deleteStation(row) {
				var that = this;
				this.$confirm('是否确认删除"' + row.recipe_name + '"的数据项?', "警告", {
					confirmButtonText: "确定",
					cancelButtonText: "取消",
					type: "warning"
				}).then(function() {
					that.$http.post(BaseUrl.delRecipe, {
						id: row.id
					}).then(res => {
						if (res.data.code == 0) {
							that.getData();
							that.$message({
								message: '删除成功',
								type: 'success'
							})
						} else {
							that.$message({
								message: res.data.msg,
								type: 'warning'
							})
						}

					})

				}).catch(() => {
					this.$message({
						type: 'info',
						message: '已取消删除'
					});
				});
			},
			noClick() {
				this.getData();
				this.addVisible = false;
			},
			noClick1() {
				this.getData();
				this.detailVisible = false;
			},
			noClick2() {
				this.getData();
				this.drugVisible = false;
			},
			getFatherAncestors(val) {}

		}

	}
</script>
<style scoped>
	.selectWdith {
		width: 460px;
	}
</style>