<template>
	
		<div class="container">
			<el-form ref="form" :model="form" :rules="rules" :inline="true" label-width="80px">
				<el-form-item label="药品类别" prop="smallName">
					<el-input v-model="form.type_name" size="small" placeholder="请输入药品类别" />
				</el-form-item>
				<el-form-item>
					<el-button type="primary" plain icon="el-icon-plus" size="mini" @click="addType">添 加</el-button>
				</el-form-item>
				<el-table v-loading="loading" ref="table" :border="true" :data="DetableData" size="mini"
					style="width: 501px;max-width:100%;">
					<el-table-column label="编号" align="center" type="index" width="100" />
					<el-table-column label="类别名称" align="center" prop="type_name" width="300" />
					<el-table-column prop="" label="操作" width="100">
						<template slot-scope="scope">
							<el-button size="mini" type="danger" plain icon="el-icon-delete"
								@click="delType(scope.row)">删除
							</el-button>
						</template>
					</el-table-column>
				</el-table>
			</el-form>
		</div>
</template>

<script>
	// getDrugTypePageList:'/drugType/getDrugTypePageList',   //查询药品类型分页列表信息 没有分页
	// addDrugType:'/drugType/addDrugType',  //药品类型新增
	// delDrugType:'/drugType/delDrugType',  //药品类型删除
	import BaseUrl from '@/utils/baseUrl';
	export default {
		name: "Type",
		data() {
			return {
				// 遮罩层
				loading: true,
				// 选中数组
				ids: [],
				// 非单个禁用
				single: true,
				// 非多个禁用
				multiple: true,
				// 显示搜索条件
				showSearch: true,
				DetableData: [],
				form: {},
				// 表单校验
				rules: {
					type_name: [{
						required: true,
						message: "请输入小类名称",
						trigger: "blur"
					}]
				},
			};
		},
		created() {
			this.getList();
		},
		methods: {
			/** 查询设备大类列表 */
			getList() {
				this.loading = true;
				this.$http.post(BaseUrl.getDrugTypePageList, {}).then(res => {
					if (res.data.code == 0) {
						this.DetableData = res.data.data
						this.loading = false
					}
				})
			},
			//新增
			addType() {
				this.$refs["form"].validate(valid => {
					if (valid) {
						this.loading = true;
						console.log(this.form)
						this.$http.post(BaseUrl.addDrugType, this.form).then(res => {
							if (res.data.code == 0) {
								this.loading = false
								this.$message({
									message: res.data.msg,
									type: 'success'
								});
								this.form.type_name = ''
								this.getList()
							}
						})
					}
				});
			},
			delType(row) {
				const code = row.type_code || this.ids;
				this.$confirm('是否删除编号为"' + code + '"的数据项?', "警告", {
					confirmButtonText: "确定",
					cancelButtonText: "取消",
					type: "warning"
				}).then(() => {
					this.$http.post(BaseUrl.delDrugType, {
						id: code
					}).then(res => {
						if (res.data.code == 0) {
							this.getList();
							this.$message({
								message: '删除成功',
								type: 'success'
							})
						} else {
							this.$message({
								message: res.data.msg,
								type: 'warning'
							})
						}
					})
				}).catch(() => {
					this.$message({
						type: 'info',
						message: '已取消删除'
					});
				});
			}
		}
	};
</script>