<template>
    <div class="flexBox">
        <div>
            <el-date-picker
            :style="'width:'+width"
            v-model="date"
            type="daterange"
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd"
            size="small"
            :clearable="false"
            @change="alertDate"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期">
            </el-date-picker>
        </div>
    </div>
</template>
<script>
    import moment from 'moment'
    
    export default {
        props:{
            dateType:{
                type:String,
                default:'6'
            },
            width:{
                type:String,
                default:'260px'
            },
            time:{
                type:Array,
                default () {
                    return []
                }
            }
        },
        data(){
            return {
                date:[],
                startDate:'',
                endDate:'',
                // showDatePicker:true,
                dateOptions:[
                    {label:'自定义查询',value:'0'},
                    {label:'今天',value:'1'},
                    {label:'过去24小时',value:'2'},
                    {label:'昨天',value:'3'},
                    {label:'本周',value:'4'},
                    {label:'上周',value:'5'},
                    {label:'过去7天',value:'6'},
                    {label:'本月',value:'7'},
                    {label:'上月',value:'8'},
                    {label:'本年',value:'9'},
                    {label:'去年',value:'10'},
                    {label:'近7天',value:'11'}
                ]
            }
        },
        created(){
            this.changeDateType(this.dateType);
        },
        watch:{
            time:function(val){
                this.date=val;
            }
        },
        methods:{
            changeDateType(val){
                this.startDate='';
                this.endDate='';
                if(val==1){//今天
                    this.startDate=moment().startOf('day').format('YYYY-MM-DD');
                    this.endDate=moment().format('YYYY-MM-DD');
                }else if(val==2){//过去24小时
                    this.startDate=moment().subtract(1, 'days').format('YYYY-MM-DD');
                    this.endDate=moment().format('YYYY-MM-DD');
                }else if(val==3){//昨天
                    this.startDate=moment().subtract(1, 'days').startOf('day').format('YYYY-MM-DD');
                    this.endDate=moment().subtract(1, 'days').endOf('day').format('YYYY-MM-DD');
                }else if(val==4){//本周
                    this.startDate=moment().startOf('isoWeek').startOf('day').format('YYYY-MM-DD');
                    this.endDate=moment().format('YYYY-MM-DD');
                }else if(val==5){//上周
                    this.startDate=moment().startOf('isoWeek').subtract(7, 'days').startOf('day').format('YYYY-MM-DD');
                    this.endDate=moment().endOf('isoWeek').subtract(7, 'days').endOf('day').format('YYYY-MM-DD');
                }else if(val==6){//过去7天
                    this.startDate=moment().subtract(7, 'days').format('YYYY-MM-DD');
                    this.endDate=moment().format('YYYY-MM-DD');
                }else if(val==7){//本月
                    this.startDate=moment().startOf('month').startOf('day').format('YYYY-MM-DD');
                    this.endDate=moment().format('YYYY-MM-DD');
                }else if(val==8){//上月
                    this.startDate=moment().month(moment().month()-1).startOf('month').startOf('day').format('YYYY-MM-DD');
                    this.endDate=moment().month(moment().month()-1).endOf('month').format('YYYY-MM-DD');
                }else if(val==9){//本年
                    this.startDate=moment().startOf('year').startOf('day').format('YYYY-MM-DD');
                    this.endDate=moment().format('YYYY-MM-DD');
                }else if(val==10){//去年
                    this.startDate=moment().year(moment().year()-1).startOf('year').startOf('day').format('YYYY-MM-DD');
                    this.endDate=moment().year(moment().year()-1).endOf('year').endOf('day').format('YYYY-MM-DD');
                }else if(val==11){//近7天，00：00：00 到 23：59：59
                    this.startDate=moment().subtract(7, 'days').format('YYYY-MM-DD');
                    this.endDate=moment().format('YYYY-MM-DD');
                }
                this.date=[this.startDate,this.endDate];
                this.$emit('input',[this.startDate,this.endDate]);
            },
            alertDate(){
                this.startDate=this.date[0];
                this.endDate=this.date[1]
                console.log()
                this.$emit('input',[this.startDate,this.endDate]);
            }
        }
    }
</script>
<style scoped>
.flexBox{
    display: flex;
}
</style>
