<template>
    <div class="table columnPage">
        <div class="container"  >
        	<el-form :inline="true" :model="searchForm" ref="searchForm">
            	<el-form-item  label="栏目名称">
                    <el-input v-model.trim="searchForm.column_name" size="small" placeholder="请输入栏目名称"></el-input>
                </el-form-item>
                <el-form-item prop="deptId" label="栏目类型">
                        <el-select v-model="searchForm.column_type" size="small" filterable clearable placeholder="请选择栏目类型" style= "width: 190px;">
                            <el-option label='全部' value=''></el-option>
                            <el-option
                            v-for="(item,index) in types"
                            :key="index"
                            :label="item.column_type"
                            :value="item.column_type">
                            </el-option>
                        </el-select>
			        </el-form-item>
                
                <el-form-item label="栏目级别" prop="level">
					<el-select v-model.trim="searchForm.column_level" placeholder="请选择栏目级别" size="small" style= "width: 190px;">
                        <el-option label='全部' value=''></el-option>
						<el-option
							v-for="(item,index) in levels"
							:key="index"
							:label="item.column_level"
							:value="item.column_val">
						</el-option>
					</el-select>
			    </el-form-item>
			   
                <el-form-item>
                    <el-button type="primary" plain icon="el-icon-search" size="small" @click="getData()">查询</el-button>
                    <el-button type="warning" plain icon="el-icon-refresh" size="small" @click="reset()">重置</el-button>
					<el-button type="success" plain size="small" icon="el-icon-plus" @click="showAddVisible('add')" >新增</el-button>
                </el-form-item>
            </el-form>
            <el-table v-loading="loading" ref="table" :height="(tableH+40)+'px'" :data="tableData" row-key="id" border  default-expand-all size="mini" :tree-props="{children: 'children', hasChildren: 'hasChildren'}"  >
                <el-table-column prop="column_name" label="栏目名称" width="*" show-overflow-tooltip></el-table-column>
                <el-table-column prop="column_type" label="栏目类型" width="240" show-overflow-tooltip></el-table-column>
                <el-table-column prop="column_level" label="栏目级别" width="100" show-overflow-tooltip>
                    <template slot-scope="scope">
                        <span>{{scope.row.column_level==0?'一级':'二级'}}</span>
                    </template>
                </el-table-column>
                <!-- <el-table-column prop="" label="上级栏目" width="*" show-overflow-tooltip></el-table-column> -->
                <!--<el-table-column prop="column_url" label="栏目图标" width="*" show-overflow-tooltip></el-table-column>-->
                <el-table-column prop="model" label="内容模式" width="150" show-overflow-tooltip></el-table-column>
                <el-table-column prop="" label="操作" width="260">
                    <template slot-scope="scope">
                    	<el-button size="mini" :type="scope.row.column_level==1?'info':'success'" icon="el-icon-plus"  plain @click="showAddVisible('add',scope.row)" :disabled="scope.row.column_level==1">新增</el-button>
                        <el-button size="mini" type="primary" icon="el-icon-edit"  plain @click="showAddVisible('edit',scope.row)" :loading="scope.row.editLoading" >编辑</el-button>
						<el-button size="mini" type="danger" plain icon="el-icon-delete" @click="deleteItem(scope.row)">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <!--新增/修改弹框-->
            <el-dialog :close-on-click-modal="false" :title='titleName' v-if="addVisible" :visible.sync="addVisible" width="700px" top="20vh" :append-to-body="true" :before-close="noClick">
			    <el-form :inline="true" ref="addForm" :model="addForm" label-width="100px" :rules="addFormRules">
                    <el-form-item  label="栏目名称" prop="column_name">
                        <el-input v-model.trim="addForm.column_name" size="small" placeholder="请输入栏目名称"></el-input>
                    </el-form-item>
                    <el-form-item prop="column_type" label="栏目类型" v-if="(row&&row.column_level==1)||(row&&type=='add')">
                        <el-select v-model="addForm.column_type" size="small" placeholder="请选择栏目类型" class="selectWdith">
                            <el-option
                            v-for="(item,index) in types"
                            :key="index"
                            :label="item.column_type"
                            :value="item.column_type">
                            </el-option>
                        </el-select>
			        </el-form-item>
                    <el-form-item  label="上级栏目" v-if="(row&&row.column_level==1)||(row&&type=='add')">
                        <el-input v-model.trim="row.column_name" disabled size="small" placeholder="请输入上级栏目"></el-input>
                    </el-form-item>
                    <el-form-item prop="column_level" label="栏目级别">
                        <el-select v-model="addForm.column_level" size="small" disabled placeholder="请选择栏目级别" class="selectWdith">
                            
                            <el-option
                            v-for="(item,index) in levels"
                            :key="index"
                            :label="item.column_level"
                            :value="item.column_val">
                            </el-option>
                        </el-select>
			        </el-form-item>
                    
                    <el-form-item prop="model" label="内容模式">
                        <el-select v-model="addForm.model" size="small" placeholder="请选择内容模式" class="selectWdith">
                            <el-option
                            v-for="(item,index) in models"
                            :key="index"
                            :label="item.model"
                            :value="item.model">
                            </el-option>
                        </el-select>
			        </el-form-item>
                    <el-form-item  label="栏目图标" prop="column_url" :rules="{ required: row?true:false, message: '域名不能为空', trigger: 'blur'}">
                        <div style="width:510px">
                            <el-upload action="#" :class="{'hideUpload':hideUpload}" :on-change="uploadImgChange" list-type="picture-card" :file-list="addForm.fileList" :on-remove="uploadImgRemove" :auto-upload="false">
                                <i slot="default" class="el-icon-plus"></i>
                            </el-upload>
                        </div>
                    </el-form-item>
                </el-form>
			    <span slot="footer" class="dialog-footer">
                    <el-button size="small" @click="noClick()">取&nbsp;消</el-button>
			        <el-button type="primary" size="small" @click="saveEdit('addForm',type)">保&nbsp;存</el-button>
			    </span>
			</el-dialog>
            
			
        </div>
    </div>
</template>
<script>
import BaseUrl from '@/utils/baseUrl';
import regulars from '@/utils/regulars.js';
    export default {
        components: {
        },
        
        data() {
        	////校验手机号，号段主要有(不包括上网卡)：130~139、150~153，155~159，180~189、170~171、    176~178。14号段为上网卡专属号段
			const telReg = /^((0\d{2,3}-\d{7,8})|(((13[0-9])|(14[0-9])|(15[0-9])|(16[0-9])|(17[0-9])|(18[0-9])|(19[0-9]))\d{8}))|400[0-9]{7}$/;
	    	var telValue = regulars.checkRegular(telReg,'请输入手机号码','手机号码输入不合法!');
	    	const personIDReg = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/; 
        	var personIDValue =regulars.checkRegular2(personIDReg,'身份证号码输入不合法');
            //校验手机号重复性
            var checkRepeat = (rule, value, callback) => {
	        	if(value == '' || value == null){
	        		callback(new Error('请输入手机号码'));
	        	}else{
                    this.$http.post(BaseUrl.checkDoctorPhone,{"id":this.addForm.id, "phone":this.addForm.phone}).then(res=>{
						if(res.data.code == 0){
                            if(res.data.data == 0){
                               callback(); 
                            }else{
                               callback(new Error('当前手机号码重复'));
                            }
						}else{
							callback(new Error('手机号码校验失败'));
						}
					});
                }
	        };
            return {
                
                footWidth:'0px',
                loading:true,//列表loading
                tableData:[],
                searchForm:{
                },
				levels:[
	            	{column_level:'一级',column_val:'0'},
	            	{column_level:'二级',column_val:'1'},
				],
                types:[
                    {column_type:'视频'},
                    {column_type:'图文'},
                ],
                models:[
                    {model:'轮播图 + 列表'},
                    {model:'列表'},
                ],
                total:0,
                totalPage:0,
                titleName:'',
                addVisible:false,
                addForm:{},
                addFormRules:{
                    column_name:[
                        { required: true, message: "请输入栏目名称", trigger: "blur" }
                    ],
                    column_type: [
	                    { required: true, message: "请选择栏目类型", trigger: "change" }
	                ],
                    column_level:[
	                    { required: true, message: "请选择栏目级别", trigger: "change" }
	                ],
	                model: [
                        { required: true, message: "请选择内容模式", trigger: "change" }
	                ],
	                // column_url:[
                    //     { required: true, message: "请上传栏目图标", trigger: "blur" }
                    // ],
                },
                type:'',//add,edit
                row:null,
                hideUpload:false,
                
            }
        },
        created(){
            this.getData()
        },
        mounted(){
            var that=this;
            
            that.footWidth=that.$refs.table.bodyWidth;
        },
        methods:{
            //列表数据
            getData(){
                this.loading = true;
                this.$http.post(BaseUrl.getColumnPageList,this.searchForm).then(res=>{
					if(res.data.code==0){
                        this.loading=false;//列表loading
                        if(res.data.data){
                            this.tableData = this.handleTree(res.data.data, "id",null,null,0);
                        }
                        
                    }else{
                        this.$message({
                            message: res.data.msg,
                            type: 'warning'
                        })
                    }
                })
                
            },
            //每页展示条数变化
            handleSizeChange(val){
                this.searchForm.limit = val;
                this.getData();
            },
            //翻页
            handleCurrentChange(val){
                this.searchForm.currentPage = val;
                this.getData();
            },
            //searchForm重置
            reset(){
                this.resetSearchForm(this.searchForm,[{k:'limit',v:this.searchForm.limit},{k:'currentPage',v:this.searchForm.currentPage}]);
                // this.getData();
            },
            //显示新增/编辑弹框
            showAddVisible(type,row){
                let that=this;
                this.addForm={
                    column_url:''
                };
                this.type=type;
                this.row=row;
                this.addForm.column_level='0';
                that.hideUpload=false;
                if(type=='add'){
                    if(row){
                        this.addForm.column_level='1'
                    }
                    this.column_type='';
                    this.addForm.parent_id=row?row.id:0;
                    let ancestorsArr=row?[row.ancestors,this.addForm.parent_id]:[this.addForm.parent_id];
                    this.addForm.ancestors=ancestorsArr.toString();
                    this.titleName="新增栏目";
                    this.addVisible=true;
                    
                }else{
                    this.titleName="修改栏目";
                    this.$set(row,'editLoading',true);
                    this.$http.post(BaseUrl.getColumnById,{id:row.id}).then(res=>{
                        if(res.data.code==0){
                            that.addForm=res.data.data;
                            that.addForm.fileList=[];
                            if(that.addForm.column_url){
                                var name =that.addForm.column_url.split("zjxy/")[1];
                                that.addForm.fileList.push({name:name,url:that.addForm.column_url+'?v='+new Date().getTime()})
                                that.hideUpload=true;
                            }
                            
                            that.addVisible=true;
                            that.$set(row,'editLoading',false);
                        }else{
                            this.$message({
                                message: res.data.msg,
                                type: 'warning'
                            })
                        }
                    })
                    
                }
                
            },
            
            //保存新增/修改
            saveEdit(form,type){
                this.$refs[form].validate((valid)=>{
                    if(valid){
                        if(type=='add'){
                            
                            this.$http.post(BaseUrl.addColumnInfo,this[form]).then(res=>{
                                if(res.data.code==0){
                                    this.titleName='';
                                    this.getData();
                                    this.addVisible=false;
                                    this.hideUpload=false;
                                    this.$message({
                                        message: res.data.msg,
                                        type: 'success'
                                    })
                                }else{
                                    this.$message({
                                        message: res.data.msg,
                                        type: 'warning'
                                    })
                                }
                            })
                        }else{
                            this.addForm.id=this.row.id;
                            let form=JSON.parse(JSON.stringify(this.addForm));
                            this.$http.post(BaseUrl.updColumnById,form).then(res=>{
                                if(res.data.code==0){
                                    this.titleName='';
                                    this.getData();
                                    this.addVisible=false;
                                    this.hideUpload=false;
                                    this.$message({
                                        message: res.data.msg,
                                        type: 'success'
                                    })
                                }else{
                                    this.$message({
                                        message: res.data.msg,
                                        type: 'warning'
                                    })
                                }
                            })
                        }
                    }
                })

            },
            //删除
            deleteItem(row){
                var that=this;
                this.$confirm('是否确认删除栏目"' + row.column_name + '"的数据项?', "警告", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning"
                }).then(function() {
                    that.$http.post(BaseUrl.delColumn,{id:row.id}).then(res=>{
                        if(res.data.code==0){
                            that.getData();
                            that.$message({
                                message:'删除成功',
                                type:'success'
                            })
                        }else{
                            that.$message({
                                message: res.data.msg,
                                type: 'warning'
                            })
                        }
                        
                    })
                    
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消删除'
                    });          
                });
            },
            //关闭弹框
            noClick(){
                this.getData();
                this.hideUpload=false;
                this.addVisible=false;
            },
            //上传图片
            uploadImgChange(file,fileList){
                let that=this;
                console.log(file);
                console.log(fileList)
                const isJPG = file.raw.type === 'image/jpeg'||file.raw.type === 'image/png';
                if (!isJPG) {
                    this.$message.error('上传图片只能是JPG或PNG 格式!');
                    fileList.splice(fileList.length-1,1)
                    return false;
                }
                let fileName = file.name.lastIndexOf(".");//取到文件名开始到最后一个点的长度
                let fileNameLength = file.name.length;//取到文件名长度
                let fileFormat = file.name.substring(fileName + 1, fileNameLength);//截
                file.name=new Date().getTime()+'.'+fileFormat;
                var newfile = new File([file.raw], file.name,{type:file.raw.type});
                // var name =file.name;
                // console.log(this.addForm.fileList);
                // this.addForm.fileList.push({name:name});
                this.hideUpload = fileList.length >= 1;
                this.saveImg(newfile,'img');
            },
            //上传图片
            saveImg(file,type){
                console.log(type);
                let that=this;
                var fd = new FormData();
                var f=file;
                fd.append('file', f);
                // this.addForm.fileList=[];
                this.$axios.post(BaseUrl.uploadImg, fd, {headers: {'Content-Type': 'multipart/form-data'}}).then(res => {
                    if(res.data.code==0){
                        that.addForm.column_url=res.data.data;
                        this.$refs['addForm'].clearValidate(['column_url']);
                    }else{
                        return false;
                    }
                });
            },
            //删除图片
            uploadImgRemove(file){
                let that=this;
                console.log(file);
                this.$http.post(BaseUrl.delImg,{fileName:file.name}).then(res=>{
                    if(res.data.code==0){
                        that.addForm.fileList=[]
                        that.hideUpload=false;
                        that.addForm.column_url='';
                    }else{
                        this.$message({
                            message: res.data.msg,
                            type: 'warning'
                        })
                    }
                })
            }
            
        }
        
    }
</script>
<style>
.columnPage .el-table__row.expanded .el-table_1_column_1  {
    font-weight: bold;
    color: #333;;
}
.hideUpload .el-upload--picture-card{
    display:none;
}
</style>
<style scoped>

.selectWdith {
    width : 200px;
}

</style>