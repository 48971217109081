<template>
  <el-submenu 
    v-if="menu.list && menu.list.length >= 1"
    :index="menu.id + ''"
    class="el-submenu-yl">
    <template slot="title">
      <i class="iconfont mr10" :class="menu.icon"></i>
      <span>{{ menu.menu_name }}</span>
    </template>
    <sub-menu
      v-for="item in menu.list" 
      :key="item.id"
      :menu="item"
      :dynamicMenuRoutes="dynamicMenuRoutes">
    </sub-menu>
  </el-submenu>
  <el-menu-item v-else :index="menu.url + ''" @click="gotoRouteHandle(menu)"><!--:index="menu.id + ''"-->
    <i class="iconfont mr10" :class="menu.icon"></i>
    <span >{{ menu.menu_name }}</span>
  </el-menu-item>
</template>

<script>
  import SubMenu from './main-sidebar-sub-menu'
  export default {
    name: 'sub-menu',
    props: {
      menu: {
        type: Object,
        required: true
      },
      dynamicMenuRoutes: {
        type: Array,
        required: true
      }
    },
    components: {
      SubMenu
    },
    computed: {
      
    },
    created(){
      // console.log('menu',this.menu)
    },
    methods: {
      // 通过menuId与动态(菜单)路由进行匹配跳转至指定路由
      gotoRouteHandle (menu) {
        var route = this.dynamicMenuRoutes.filter(item => item.meta.id === menu.id)
        if (route.length >= 1) {
          this.$router.push({ name: route[0].name })
        }
      }
    }
  }
</script>
<style scoped>
.mr10{
  margin-right: 10px;
}
</style>
