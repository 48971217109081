import http from '@/plugins/axios'
import BaseUrl from '@/utils/baseUrl'
import axios from 'axios'
import qs from 'qs'

export function login (account, password,captchaVerification) {//code,
  return http.$http.post(BaseUrl.login, { account,password,captchaVerification })//code,

};
export function logout (uid,token) {
  return http.$http.post(BaseUrl.logout, {uid,token})
}
