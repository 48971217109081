<template>
  <div class="table" v-loading="allLoading">
    <div class="container">
      <!--搜索框-->
      <el-form :inline="true" :model="searchForm" ref="searchForm">
        <el-form-item prop="company_name" label="所属药房">
          <el-select v-model="searchForm.company_name" placeholder="请选择所属药房" size="small">
            <el-option v-for="item in companyOptions" :key="item.id" :label="item.company_name" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="订单编号">
          <el-input v-model.trim="searchForm.order_num" style="width: 150px" size="small" placeholder="请输入订单编号"></el-input>
        </el-form-item>
        <el-form-item label="商户订单号">
          <el-input v-model.trim="searchForm.merge_num" style="width: 150px" size="small" placeholder="商户订单号"></el-input>
        </el-form-item>
        <el-form-item label="物流单号" v-if="$route.name == 'send' || $route.name == 'sign'">
          <el-input v-model.trim="searchForm.phy_num" size="small" style="width: 160px" placeholder="请输入物流单号"></el-input>
        </el-form-item>
        <el-form-item label="物流公司" v-if="$route.name == 'send' || $route.name == 'sign'">
          <el-input v-model.trim="searchForm.phy_company" size="small" style="width: 160px" placeholder="请输入物流公司"></el-input>
        </el-form-item>
        <el-form-item label="客户姓名">
          <el-input v-model.trim="searchForm.see_name" size="small" style="width: 160px" placeholder="请输入客户姓名"></el-input>
        </el-form-item>
        <el-form-item label="开方医生" v-if="$route.name == 'order'">
          <el-input v-model.trim="searchForm.doctorName" size="small" style="width: 160px" placeholder="请输入开方医生"></el-input>
        </el-form-item>
        <el-form-item prop="order_state" label="订单状态" v-if="$route.name == 'order'">
          <el-select v-model="searchForm.order_state" placeholder="请选择" size="small" style="width: 160px">
            <el-option label="选择全部" value></el-option>
            <el-option v-for="item in stateOptions" :key="item.typeValue" :label="item.typeLabel" :value="item.typeValue"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="print_state" label="打印状态" v-if="$route.name == 'wait'">
          <el-select v-model="searchForm.print_state" placeholder="请选择" size="small" style="width: 160px">
            <el-option label="选择全部" value></el-option>
            <el-option v-for="item in printOptions" :key="item.value" :label="item.label" :value="item.value"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="order_state" label="下单日期" v-if="$route.name == 'order'">
          <dateChoose v-model="searchForm.time" :time="searchForm.time"></dateChoose>
        </el-form-item>
        <el-form-item prop="order_state" label="支付日期" v-if="$route.name == 'wait'">
          <dateChoose v-model="searchForm.time" :time="searchForm.time"></dateChoose>
        </el-form-item>
        <el-form-item prop="order_state" label="发货日期" v-if="$route.name == 'send'">
          <dateChoose v-model="searchForm.time" :time="searchForm.time"></dateChoose>
        </el-form-item>
        <el-form-item prop="pay_time" label="支付日期" v-if="$route.name == 'order'">
          <dateChoose dateType="0" v-model="searchForm.payTime" :time="searchForm.payTime"></dateChoose>
        </el-form-item>
        <el-form-item prop="order_state" label="签收日期" v-if="$route.name == 'sign'">
          <dateChoose v-model="searchForm.time" :time="searchForm.time"></dateChoose>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" plain icon="el-icon-search" size="small" @click="searchData()">查询
          </el-button>
          <el-button type="warning" plain icon="el-icon-refresh" size="small" @click="reset()">重置
          </el-button>
          <el-button type="primary" plain size="small" icon="el-icon-upload2" @click="exportFile()">导出
          </el-button>
        </el-form-item>
        <br/>
        <!-- 	<el-form-item label="打印机">
					<el-select v-model="printerName" style="width: 300px;" size="mini" placeholder="请选择打印机"
						@change="selectPrinter">
						<el-option v-for="item in printersOptions" :key="item.index" :label="item.name"
							:value="item.index">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="顺丰面单尺寸">
					<el-select v-model="templateCode" style="width: 250px;" size="mini" placeholder="请选择顺丰面单尺寸"
						@change="selecttemplate">
						<el-option v-for="item in templateCodeOptions" :key="item.value" :label="item.label"
							:value="item.value">
						</el-option>
					</el-select>
				</el-form-item> -->
      </el-form>
      <!--语音播放-->
      <div v-if="$route.name == 'wait'">
        <el-card class="tipsCardBox">
          <div class="tit">
            <span>订单提醒</span> <span @click="showVideoBox = !showVideoBox" style="color: teal; cursor: pointer">{{ showVideoBox ? "收起" : "显示" }}</span>
          </div>
          <el-collapse-transition>
            <div v-show="showVideoBox" style="padding-top: 10px">
              <div style="font-size: 14px; line-height: 2" class="tit">
                <span>未处理订单：</span> <span style="color: red">{{ waitOlderTotal }}个</span>
              </div>
              <div style="font-size: 14px; line-height: 2" class="tit">
                <span>语音播报：</span>
                <el-switch v-model="openVideo" @change="speakFn"></el-switch>
                <!-- <input type="checkbox" v-model="openVideo" @change="speakFn"/>  -->
              </div>
            </div>
          </el-collapse-transition>
        </el-card>
      </div>
      <el-table v-loading="loading" ref="table" highlight-current-row @current-change="handleCurrentChangeT" :data="tableData" border stripe size="mini" style="width: fit-content">
        <el-table-column prop="order_num" label="订单编号" width="180" :key="1" show-overflow-tooltip></el-table-column>
        <el-table-column prop="company_name" label="所属药房" width="100" show-overflow-tooltip></el-table-column>
        <el-table-column prop="sign_time" v-if="$route.name == 'sign'" label="签收日期" width="140" :key="2" show-overflow-tooltip></el-table-column>

        <el-table-column prop="create_time" label="下单日期" width="140" :key="3" show-overflow-tooltip></el-table-column>
        <!-- <el-table-column prop="see_id" v-if="$route.name=='order'" label="应诊ID" width="100" :key="4"
					show-overflow-tooltip></el-table-column> -->
        <el-table-column prop="order_state" v-if="$route.name == 'order'" label="订单状态" width="70" :key="5" show-overflow-tooltip></el-table-column>

        <el-table-column prop="pay_time" v-if="$route.name == 'wait' || $route.name == 'order'" label="支付日期" width="140" :key="6" show-overflow-tooltip></el-table-column>
        <el-table-column prop="deliver_time" v-if="$route.name == 'send' || $route.name == 'sign'" label="发货日期" width="140" :key="7" show-overflow-tooltip></el-table-column>
        <el-table-column prop="deliver_person" v-if="$route.name == 'send' || $route.name == 'sign'" label="发货人" width="80" :key="8" show-overflow-tooltip></el-table-column>
        <el-table-column prop="phy_num" label="物流单号" width="130" :key="9" show-overflow-tooltip></el-table-column>
        <el-table-column prop="merge_num" label="商户订单号" width="200" show-overflow-tooltip></el-table-column>
        <el-table-column prop="doctorName" label="开方医生" width="120" v-if="$route.name == 'order'" show-overflow-tooltip></el-table-column>
        <el-table-column prop="phy_company" v-if="$route.name == 'send' || $route.name == 'sign'" label="物流公司" width="130" :key="10" show-overflow-tooltip></el-table-column>

        <el-table-column prop="see_name" label="客户姓名" width="80" :key="11" show-overflow-tooltip></el-table-column>
        <el-table-column prop="person" label="收货人" width="80" :key="12" show-overflow-tooltip></el-table-column>
        <el-table-column prop="phone" label="收货电话" width="100" :key="13" show-overflow-tooltip></el-table-column>
        <el-table-column prop="address" label="收货地址" width="250" :key="14" show-overflow-tooltip></el-table-column>
        <el-table-column label="订单所属地区" width="250" v-if="$route.name == 'order'" show-overflow-tooltip>
          <template slot-scope="scope">
            {{ getArea(scope.row.province, scope.row.city, scope.row.county) }}
          </template>
        </el-table-column>
        <el-table-column prop="print_state" v-if="$route.name == 'wait'" label="打印状态" width="130" :key="15" show-overflow-tooltip></el-table-column>
        <el-table-column prop="" label="操作" align="left" :width="tableBtnWidth">
          <template slot-scope="scope">
            <el-button size="mini" type="warning" plain @click="detailVisible(scope.row)">明细
            </el-button>
            <el-button size="mini" type="danger" plain v-if="scope.row.order_state == '待发货'" @click="cancelOrder(scope.row)">取消订单
            </el-button>
            <!-- <el-button size="mini" type="success" plain @click="showPrintFn(scope.row,'factory')"
							v-if="scope.row.order_state == '待发货'&&($route.name=='order'||$route.name=='wait')">打印工厂联</el-button>
						<el-button size="mini" type="success" plain @click="showPrintFn(scope.row,'user')"
							v-if="scope.row.order_state == '待发货'&&($route.name=='order'||$route.name=='wait')">打印客户联</el-button>
						<el-button size="mini" type="primary" plain @click="showAddVisible(scope.row)"
							:loading="scope.row.editLoading">打印面单</el-button> -->
            <el-button size="mini" type="primary" plain v-show="scope.row.phy_num" @click="showWuLiu(scope.row)">物流信息
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <!--TODO 分页-->
      <div class="pagination table-width-b" v-if="totalPage != 0" :style="'width:' + footWidth + ';max-width:100%;'">
        <el-pagination @size-change="handleSizeChange" :page-sizes="[10, 20, 50]" :page-size="searchForm.limit" :current-page.sync="searchForm.currentPage" @current-change="handleCurrentChange" layout="jumper, prev, pager, next, sizes, total" :total="total"></el-pagination>
      </div>
      <!--物流弹框-->
      <el-dialog :close-on-click-modal="false" :title="titleName" v-if="addVisible" :visible.sync="addVisible" width="600px" top="20vh" :append-to-body="true" :before-close="noClick">
        <div>
          <el-timeline :reverse="reverse" v-show="wuliuTreeShow">
            <el-timeline-item v-for="(activity, index) in WuliuInfo" :key="index" :timestamp="activity.acceptTime" :color="activity.color">
              【{{ activity.acceptAddress }}】{{ activity.remark }}
            </el-timeline-item>
          </el-timeline>
          <div v-show="!wuliuTreeShow" style="padding: 10px; background-color: #ebebeb; border-radius: 5px">
            <div><strong>物流追踪</strong></div>
            <div>暂未查到与您单号相关的物流信息，请稍后再试。</div>
          </div>
        </div>
        <span slot="footer" class="dialog-footer">
          <!-- <el-button size="small" @click="noClick()">取&nbsp;消</el-button> -->
          <!-- <el-button type="primary" size="small" @click="saveEdit('addForm',type)">保&nbsp;存</el-button> -->
          <el-button type="primary" size="small" @click="noClick">确&nbsp;定</el-button>
        </span>
      </el-dialog>
      <!-- 订单明细弹框 -->
      <detail-info v-if="detVisible" ref="detailInfo" :titleName="titleName" :seeInfo="seeInfo" :openRecipe="openRecipe" :orders="orders" :showOrder="showOrder" @refreshTable="searchData"></detail-info>
      <!--打印-->
      <print v-if="showPrint" :daiJianFei="daiJianFei" :tableData="printData" @reload="reload" :type="printType" v-model="showPrint"></print>

      <!-- 点击开始打占 -->
      <!-- <button id="print" @click="print()">打印</button> -->
    </div>
  </div>
</template>
<script>
import SCPPrint from "@/assets/js/SCPPrint";
import BaseUrl from "@/utils/baseUrl";
import detailInfo from "@/components/detail-info";
import print from "@/components/print";
import dateChoose from "@/components/date-choose";
import moment from "moment";

export default {
  components: {
    detailInfo,
    print,
    dateChoose,
  },
  created() {
    if (this.$route.name == "order") {
      this.searchForm.order_state = "";
      this.tableBtnWidth = "220";
      this.getDataUrl = "getOrderPageList";
      this.exportUrl = "allOrderExport";
      this.exportName = "所有订单报表.xls";
    } else if (this.$route.name == "wait") {
      this.searchForm.order_state = "1";
      this.tableBtnWidth = "200";
      this.getDataUrl = "getOrderPageListOfWait";
      this.exportUrl = "beforeOrderExport";
      this.exportName = "待发货订单报表.xls";
    } else if (this.$route.name == "send") {
      this.searchForm.order_state = "2";
      this.tableBtnWidth = "220";
      this.getDataUrl = "getOrderPageListOfSend";
      this.exportUrl = "afterOrderExport";
      this.exportName = "已发货订单报表.xls";
    } else if (this.$route.name == "sign") {
      this.searchForm.order_state = "3";
      this.tableBtnWidth = "220";
      this.getDataUrl = "getOrderPageListOfSign";
      this.exportUrl = "enterOrderExport";
      this.exportName = "已签收订单报表.xls";
    }
    // this.getReturnParam() //获取打印面板Code
    // this.$http.post(BaseUrl.getPartnerID, {}).then(res => {
    // 	if (res.data.code == 0) {
    // 		let partnerID = res.data.data //getPartnerID获取顾客编码
    // 		console.log('partnerID: ', partnerID)
    // 		// 引入SDK后初始化实例，仅执行一欠
    // 		const sdkCallback = result => {};
    // 		const sdkParams = {
    // 			env: "sbox", // 生产：pro；沙箱：sbox。不传默认生产，转生产需要修改这釠
    // 			partnerID: partnerID,
    // 			callback: sdkCallback,
    // 			notips: false
    // 		};
    // 		this.printSdk = new SCPPrint(sdkParams);
    // 		// 获取打印机列衠
    // 		const getPrintersCallback = result => {
    // 			if (result.code === 1) {
    // 				const printers = result.printers;
    // 				console.log('printers: ', printers)
    // 				this.printerName = printers[0].name
    // 				this.printersOptions = printers
    // 				// 设置默认打印朠
    // 				var printer = 0;
    // 				selectElement.value = printer;
    // 				this.printSdk.setPrinter(printer);
    // 			}
    // 		};
    // 		this.printSdk.getPrinters(getPrintersCallback);

    // 	} else {
    // 		this.$message({
    // 			message: res.data.msg,
    // 			type: 'warning'
    // 		})
    // 	}

    // })
  },
  data() {
    return {
      footWidth: "0px",
      loading: true, //列表loading
      tableData: [],
      searchForm: {
        company_name: "",
        order_num: "",
        order_state: "",
        merge_num: "",
        print_state: "",
        limit: limit,
        currentPage: 1,
        start_time: "",
        end_time: "",
        see_name: "",
        pay_start_time: "",
        pay_end_time: "",
        payTime: [],
      },
      total: 0,
      totalPage: 0,
      titleName: "",
      addVisible: false,
      detVisible: false,
      deviceSpotList: [], //抽检结果列表
      companyOptions: [],
      stateOptions: [],
      addForm: {
        phy_num: "",
        phy_company: "",
        deliver_time: "",
        id: "",
      },
      addFormRules: {
        phy_num: [
          {
            required: true,
            message: "发货单号不能为空",
            trigger: "blur",
          },
        ],
        phy_company: [
          {
            required: true,
            message: "物流公司不能为空",
            trigger: "blur",
          },
        ],
      },
      type: "", //add,edit
      row: null,
      isHisPage: "",
      deptOptions: [], //组织机构树
      deptInfo: {
        id: "",
        name: "",
      },
      seeInfo: {},
      openRecipe: {},
      showOrder: true,
      orders: [],
      showPrint: false,
      printData: null,
      tableBtnWidth: "100", //页面链接最后一个字段
      getDataUrl: "", //请求地址
      exportUrl: "", //导出请求地址\
      exportName: "", //导出文件名
      timer: null, //定时器
      waitOlderTotal: 0, //未处理订单数
      openVideo: false, //是否开启语音播报
      speech: null,
      showVideoBox: true,
      printOptions: [
        {
          label: "已打印",
          value: "1",
        },
        {
          label: "未打印",
          value: "0",
        },
      ],
      daiJianFei: "",
      printSdk: null, //打印函数
      printParam: {}, //打印需要的参数
      reverse: true,
      WuliuInfo: [], //物流信息
      allLoading: false,
      printerName: "", //默认打印机
      printersOptions: [],
      templateCode: "", //打印面单模板
      templateCodeOptions: [], //打印面单模板数组
      wuliuTreeShow: true, //默认显示物流树，如果物流为空显示其他
    };
  },
  mounted() {
    //this.getTableProp();
    var that = this;
    that.getCompanySelect();
    that.getStateSelect();
    that.footWidth = that.$refs.table.bodyWidth;
    this.searchForm.start_time = this.searchForm.time[0];
    this.searchForm.end_time = this.searchForm.time[1];
    this.getData();

    if (this.$route.name == "wait") {
      //待支付页面每五分钟刷新一下未处理总数
      this.getOrderListCountOfWait();
      clearInterval(this.timer);
      this.timer = setInterval(() => {
        this.getOrderListCountOfWait();
      }, 5 * 60 * 1000); //5*60*1000

      //语音播报初始化
      this.speech = window.speechSynthesis;
    }
  },
  beforeDestroy() {
    console.log(this.$route.name);
    clearInterval(this.timer);

    this.waitOlderTotal = 0;
    this.openVideo = false;
  },
  watch: {
    "searchForm.time": {
      handler(newName, oldName) {
        this.searchForm.start_time = newName[0];
        this.searchForm.end_time = newName[1];
        //执行代码
      },
      deep: true,
    },
    "searchForm.payTime": {
      handler(newName, oldName) {
        this.searchForm.pay_start_time = newName[0];
        this.searchForm.pay_end_time = newName[1];
        //执行代码
      },
      deep: true,
    },
  },
  methods: {
    searchData() {
      if (this.$route.name == "wait") {
        //待支付页面每五分钟刷新一下未处理总数
        this.getOrderListCountOfWait();
        clearInterval(this.timer);
        this.timer = setInterval(() => {
          this.getOrderListCountOfWait();
        }, 5 * 60 * 1000); //5*60*1000
      }
      this.getData();
    },
    // //获取未处理总数
    getOrderListCountOfWait() {
      this.$http.post(BaseUrl.getOrderListCountOfWait, this.searchForm).then((res) => {
        //,this.searchForm
        if (res.data.code == 0) {
          this.waitOlderTotal = res.data.data;
          this.speakFn();
        } else {
          this.$message({
            message: res.data.msg,
            type: "warning",
          });
        }
      });
    },
    speakFn() {
      if (this.waitOlderTotal > 0 && this.openVideo) {
        if (this.speech.speaking) {
          this.speech.cancel(this.utterance);
        }
        this.playVoice("当前有未处理订单，请及时处理！");
      } else {
        if (this.speech.speaking) {
          this.speech.cancel(this.utterance);
        }
      }
    },
    playVoice(str) {
      let that = this;
      that.utterance = null;
      that.utterance = new window.SpeechSynthesisUtterance();
      that.utterance.text = str;
      that.utterance.lang = "zh-CN";
      that.utterance.volume = "1";
      that.utterance.rate = 1;
      that.utterance.pitch = 1;
      that.utterance.onerror = (event) => {
        alert(
          "刷新操作导致浏览器语音播放出现错误，重启浏览器后，语音播放恢复正常！"
        );
      };
      that.speech.speak(that.utterance);
    },
    getCompanySelect() {
      //药房下拉
      this.$http.post(BaseUrl.getCompanyPageList, this.searchForm).then((res) => {
        if (res.data.code == 0) {
          this.companyOptions = res.data.data.data;
        } else {
          this.$message({
            message: res.data.msg,
            type: "warning",
          });
        }
      });
    },
    getStateSelect() {
      this.$http.post(BaseUrl.orderTypeList).then((res) => {
        if (res.data.code == 0) {
          this.stateOptions = res.data.data;
        } else {
          this.$message({
            message: res.data.msg,
            type: "warning",
          });
        }
      });
    },
    handleCurrentChangeT(row, index) {
      this.currentChange = true;
      this.$refs.table.setCurrentRow(row);
    },
    getData() {
      this.loading = true; //列表loading
      this.$http.post(BaseUrl[this.getDataUrl], this.searchForm).then((res) => {
        if (res.data.code == 0) {
          this.tableData = res.data.data.data;
          this.totalPage = res.data.data.totalPage;
          this.total = res.data.data.total;
          this.loading = false; //列表loading
        } else {
          this.$message({
            message: res.data.msg,
            type: "warning",
          });
        }
      });
    },
    handleSizeChange(val) {
      this.searchForm.limit = val;
      this.getData();
    },
    handleCurrentChange(val) {
      this.searchForm.currentPage = val;
      this.getData();
    },
    reset() {
      this.resetSearchForm(this.searchForm, [
        {
          k: "limit",
          v: this.searchForm.limit,
        },
        {
          k: "currentPage",
          v: this.searchForm.currentPage,
        },
        {
          k: "start_time",
          v: moment().subtract(7, "days").format("YYYY-MM-DD"),
        },
        {
          k: "end_time",
          v: moment().format("YYYY-MM-DD"),
        },
        {
          k: "time",
          v: [
            moment().subtract(7, "days").format("YYYY-MM-DD"),
            moment().format("YYYY-MM-DD"),
          ],
        },
        {
          k: "pay_start_time",
          v: null,
        },
        {
          k: "pay_end_time",
          v: null,
        },
        {
          k: "payTime",
          v: [],
        },
        {
          k: "doctorName",
          v: null,
        },
      ]);
      // this.getData();
    },
    detailVisible(row) {
      var that = this;
      that.titleName = "订单明细";
      //药品详情
      that.$http.post(BaseUrl.getOrderBySeeId, {
        seeId: row.see_id,
        openId: row.openId,
      }).then((res) => {
        if (res.data.code == 0) {
          that.seeInfo = res.data.data.seeInfo;
          /*
           * @Author: 刘帅崎
           * @Date: 2023-06-06 17:12:41
           * @Description: 描述=>代煎费：在这里直接进行截取
           */
          // for (var i = 0; i < res.data.data.openRecipe.recipseDetail.length; i++) {
          // 	if (res.data.data.openRecipe.recipseDetail[i].drugName == '代煎费') {
          // 		//截取代煎费
          // 		res.data.data.openRecipe.recipseDetail.splice(i, 1)
          // 		// console.log('代煎费在父组件获取到的对象为=>', i, res.data.data.openRecipe.recipseDetail)
          // 	}
          // 	that.openRecipe = res.data.data.openRecipe;
          // }
          that.openRecipe = res.data.data.openRecipe;
          that.orders = res.data.data.orders;
          that.detVisible = true;
          that.$nextTick(() => {
            that.$refs.detailInfo.init();
          });
        } else {
          that.$message({
            message: res.data.msg,
            type: "error",
          });
        }
      });
    },
    showAddVisible(row) {
      this.allLoading = true;
      //查询顺丰运单号
      this.getSFOrderByNum(row);
    },
    //获取顺丰运单号
    getSFOrderByNum(row) {
      this.$http.post(BaseUrl.sendSFOrderByNum, {
        orderNum: row.order_num,
      }).then((res) => {
        if (res.data.code == 0) {
          console.log("获取顺丰运单号: ", res.data.data.phy_num);
          this.getReturnParam(res.data.data.phy_num);
        } else {
          this.$message({
            message: res.data.msg,
            type: "warning",
          });
        }
      }).catch((error) => {
        this.allLoading = false;
      });
    },
    //获取打印参数
    getReturnParam(code) {
      this.$http.post(BaseUrl.returnParam, {}).then((res) => {
        if (res.data.code == 0) {
          console.log("获取打印参数: ", res);
          this.printParam = {
            requestID: res.data.data.requestID,
            accessToken: res.data.data.accessToken,
            templateCode: this.templateCode,
            templateVersion: "",
            documents: [
              {
                masterWaybillNo: code,
              },
            ],
            extJson: {},
          };
          this.getTepmodel(res.data.data.templateCode);
          if (!!code) {
            //如果有code则是点击打印/没有就是初始化得到templateCode数组

            let that = this;
            // 打印面单实际的回调函数
            const callback = function (result) {
              that.allLoading = false;
              console.log("result: ", result);
              console.log("回调函数loading:", that.allLoading);
            };
            const options = {
              lodopFn: "PRINT", // 默认打印：PRINT，预览传PREVIEW
            };
            that.printSdk.print(this.printParam, callback, options);
            console.log("this.printParam: ", this.printParam);
          }
        } else {
          this.$message({
            message: res.data.msg,
            type: "warning",
          });
          this.allLoading = false;
        }
      }).catch((error) => {
        this.allLoading = false;
      });
    },

    //保存新增/修改
    saveEdit(form, type) {
      this.$refs[form].validate((valid) => {
        if (valid) {
          delete this[form].dept_name;
          this.$http.post(BaseUrl.saveOrderById, this[form]).then((res) => {
            if (res.data.code == 0) {
              this.titleName = "";
              this.searchData();
              this.addVisible = false;
              this.$message({
                message: res.data.msg,
                type: "success",
              });
            } else {
              this.$message({
                message: res.data.msg,
                type: "warning",
              });
            }
          });
        }
      });
    },
    noClick() {
      this.searchData();
      this.addVisible = false;
    },
    noClick1() {
      this.getData();
      this.detVisible = false;
    },
    //打印
    showPrintFn(row, type) {
      this.printType = type;

      this.$http.post(BaseUrl.printOrderInfo, {
        seeId: row.see_id,
        openId: row.openId,
      }).then((res) => {
        if (res.data.code == 0) {
          /*
           * @Author: 刘帅崎
           * @Date: 2023-06-06 17:12:41
           * @Description: 描述=>代煎费：在这里直接进行截取
           */
          for (
            var i = 0;
            i < res.data.data.openRecipe.recipseDetail.length;
            i++
          ) {
            if (
              res.data.data.openRecipe.recipseDetail[i].drugName == "代煎费"
            ) {
              //截取代煎费
              this.daiJianFei =
                res.data.data.openRecipe.recipseDetail[i].totalPrice;
              // res.data.data.openRecipe.recipseDetail.splice(i, 1)//如果需要截取某个药品打开它并更改条件
              console.log("代煎费=====>: ", this.daiJianFei);
              console.log(
                "代煎费在父组件获取到的对象为=>",
                i,
                res.data.data.openRecipe.recipseDetail
              );
              //有代煎费-1味药
              res.data.data.openRecipe.drugNum =
                res.data.data.openRecipe.drugNum - 0 - 1;
              console.log("共有", res.data.data.openRecipe.drugNum, "味药");
              this.$forceUpdate();
            }
          }

          this.printData = res.data.data;
          console.log("this.printData: ", this.printData);
          this.printData.rowId = row.id;
          this.showPrint = true;
          // this.showPrint=false;
        } else {
          this.$message({
            message: res.data.msg,
            type: "warning",
          });
        }
      });
    },
    reload(val) {
      console.log("reload", val);
      if (val) {
        this.searchData();
      }
    },
    /**
     * 导出
     */
    exportFile() {
      let obj = this.searchForm;
      this.$http.postFile(BaseUrl[this.exportUrl], obj, {
        responseType: "arraybuffer",
      }).then((res) => {
        var blob = new Blob([res.data], {
          type: "application/vnd.ms-excel;charset=utf-8",
        });
        var downloadElement = document.createElement("a");
        var objectUrl = URL.createObjectURL(blob);
        downloadElement.href = objectUrl;
        downloadElement.download = this.exportName; //下载后文件名
        document.body.appendChild(downloadElement);
        downloadElement.click(); //点击下载
        document.body.removeChild(downloadElement); //下载完成移除元素
        window.URL.revokeObjectURL(objectUrl); //释放掉blob对象
      }).catch(function (res) {
      });
    },
    // 选择打印机
    selectPrinter(e) {
      // 设置打印机
      this.printSdk.setPrinter(e);
      console.log("打印机为", e);
    },
    //选择打印模板
    selecttemplate(e) {
      console.log("打印模板: ", e);
      this.templateCode = e;
    },
    //展示物流信息
    showWuLiu(row) {
      this.$http.post(BaseUrl.getOrderByWaybillNo, {
        waybillNo: row.phy_num,
        receivePhone: row.phone,
      }).then((res) => {
        if (res.data.code == 0) {
          this.titleName = "物流信息";
          this.addVisible = true;

          if (!!res.data.data.length > 0) {
            this.wuliuTreeShow = true;
            this.WuliuInfo = res.data.data;
            this.WuliuInfo[this.WuliuInfo.length - 1].color = "#0bbd87";
            console.log("this.WuliuInfo: ", this.WuliuInfo);
          } else {
            this.wuliuTreeShow = false;
          }
        } else {
          this.$message({
            message: res.data.msg,
            type: "warning",
          });
        }
      }).catch((error) => {
        this.$message({
          message: "查询接口异常，请联系管理员！",
          type: "warning",
        });
      });
    },
    getTepmodel(data) {
      this.templateCodeOptions = [];
      let name = ["丰密一联面单76mm*130mm", "丰密二联面单100mm*150mm"];
      var arr = data.split(",");
      for (var i = 0; i < arr.length; i++) {
        var obj = {
          label: name[i],
          value: arr[i],
        };
        this.templateCodeOptions.push(obj);
      }
      this.templateCode = this.templateCodeOptions[0].value;
      console.log("this.templateCode: ", this.templateCode);
      console.log("模板数组: ", this.templateCodeOptions);
    },
    //取消订单
    cancelOrder(row) {
      var that = this;

      this.$confirm(
        '是否确认取消订单为"' + row.order_num + '"的数据项?',
        "警告",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      ).then(function () {
        that.$http.post(BaseUrl.cancelOrderById, {
          id: row.id,
        }).then((res) => {
          if (res.data.code == 0) {
            that.getData();
            that.$message({
              message: "取消成功",
              type: "success",
            });
          } else {
            that.$message({
              message: res.data.msg,
              type: "warning",
            });
          }
        });
      }).catch(() => {
        this.$message({
          type: "info",
          message: "已取消",
        });
      });
    },
    getArea(province, city, county) {
      // 获取省市区,province,city,county都有值的时候进行拼接返回
      if ((province, city, county)) {
        return province + "/" + city + "/" + county;
      } else {
        return "无";
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.tipsCardBox {
  position: fixed;
  top: 120px;
  right: 20px;
  width: 200px;
  z-index: 9;

  .tit {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
</style>