<template>
    <div class="table">
        <div class="container">
            <el-form :inline="true" :model="searchForm" ref="searchForm">
                <el-row>
                    <el-form-item label="医生名称">
                        <el-input v-model.trim="searchForm.doctorName" size="small" placeholder="请输入医生名称"></el-input>
                    </el-form-item>
                    <el-form-item label="手机号">
                        <el-input v-model.trim="searchForm.phone" size="small" placeholder="请输入手机号"></el-input>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" plain icon="el-icon-search" size="small"
                            @click="getData()">查询</el-button>
                        <el-button type="warning" plain icon="el-icon-refresh" size="small"
                            @click="reset()">重置</el-button>
                    </el-form-item>
                </el-row>
            </el-form>
            <el-table v-loading="loading" ref="table" highlight-current-row @current-change="handleCurrentChangeT"
                :data="tableData" border stripe size="mini" style="width: fit-content;">
                <el-table-column type="index" label="序号" width="80" show-overflow-tooltip></el-table-column>
                <el-table-column prop="doctorName" label="医生姓名" width="150" show-overflow-tooltip></el-table-column>
                <el-table-column prop="phone" label="手机号码" width="150" show-overflow-tooltip></el-table-column>
                <el-table-column prop="hospital_name" label="所属医院" width="350" show-overflow-tooltip></el-table-column>
                <el-table-column prop="dept_name" label="科室" width="150" show-overflow-tooltip></el-table-column>
                <el-table-column prop="level" label="职称级别" width="150" show-overflow-tooltip></el-table-column>
                <el-table-column prop="no_tran_money" label="可用余额" width="150" show-overflow-tooltip></el-table-column>
                <el-table-column prop="" label="操作" align="center" width="160" fixed="right">
                    <template slot-scope="scope">
                        <el-button size="mini" type="warning" plain icon="el-icon-document"
                            @click="deleteStation(scope.row)">积分明细</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <!--TODO 分页-->
            <div class="pagination table-width-b" v-if="totalPage != 0" :style="'width:' + footWidth + ';max-width:100%;'">
                <el-pagination @size-change="handleSizeChange" :page-sizes="[10, 20, 50]" :page-size="searchForm.limit"
                    :current-page.sync="searchForm.currentPage" @current-change="handleCurrentChange"
                    layout="jumper, prev, pager, next, sizes, total" :total="total">
                </el-pagination>
            </div>
            <!--积分明细弹框-->
            <el-dialog :close-on-click-modal="false" :title='titleName' v-if="addVisible" :visible.sync="addVisible"
                width="1200px" top="20vh" :append-to-body="true" :before-close="noClick">
                <el-form :inline="true" :model="searchFormDetail" ref="searchFormDetail">
                    <el-row>
                        <el-form-item prop="tran_time" label="交易时间">
                            <dateChoose v-model="searchFormDetail.time" :time="searchFormDetail.time" width="220px"
                                dateType="11">
                            </dateChoose>
                        </el-form-item>
                        <el-form-item>
                            <el-button type="primary" plain icon="el-icon-search" size="small"
                                @click="getDataDetail()">查询</el-button>
                            <el-button type="warning" plain icon="el-icon-refresh" size="small"
                                @click="resetDetail()">重置</el-button>
                            <el-button type="primary" plain size="small" icon="el-icon-upload2"
                                @click="exportFile()">导出</el-button>
                        </el-form-item>
                    </el-row>
                    <el-row>
                        医生姓名：{{ this.row.doctorName }} 可用余额：{{ this.row.no_tran_money }}
                    </el-row>
                </el-form>
                <el-table v-loading="loading1" ref="table" highlight-current-row :max-height="500"
                    @current-change="handleCurrentChangeT" :data="tableDataDetail" border stripe size="mini"
                    style="width: fit-content;">
                    <el-table-column type="index" label="序号" width="80" show-overflow-tooltip></el-table-column>
                    <el-table-column prop="tran_time" label="交易时间" width="150" show-overflow-tooltip></el-table-column>
                    <el-table-column prop="tran_type" label="交易类型" width="120" show-overflow-tooltip></el-table-column>
                    <el-table-column prop="transaction_id" label="交易流水号" width="220"
                        show-overflow-tooltip></el-table-column>
                    <el-table-column prop="tran_amount" label="交易金额" width="130"
                        show-overflow-tooltip></el-table-column>
                    <el-table-column prop="rate" label="提成比例" width="100" show-overflow-tooltip></el-table-column>
                    <el-table-column prop="tc_amount" label="提成金额" width="150" show-overflow-tooltip></el-table-column>
                    <el-table-column prop="remark" label="备注" width="150" show-overflow-tooltip></el-table-column>
                </el-table>
                <!--TODO 分页 200223.09.13分页有问题  去掉-->
                <!-- <div class="pagination table-width-b" v-if="totalPage!=0" :style="'width:'+footWidth+';max-width:100%;'">
	                <el-pagination 
	                    @size-change="handleSizeChange" 
	                    :page-sizes="[10,20,50]"
	                    :page-size="searchFormDetail.limit"
	                    :current-page.sync="searchFormDetail.currentPage"
	                    @current-change="handleCurrentChange"
	                    layout="jumper, prev, pager, next, sizes, total"
	                    :total="total">
	                </el-pagination>
	            </div> -->
            </el-dialog>
        </div>
    </div>
</template>
<script>
import BaseUrl from '@/utils/baseUrl';
import regulars from '@/utils/regulars.js';
import options from '@/utils/country-data.js';
import dateChoose from "@/components/date-choose"
import moment from 'moment'

export default {
    components: {
        dateChoose
    },
    created() {

    },
    watch: {
        'searchFormDetail.time': {
            handler(newName, oldName) {
                this.searchFormDetail.start_time = newName[0];
                this.searchFormDetail.end_time = newName[1];
                //执行代码
            },
            deep: true
        }
    },
    data() {
        return {
            fileList1: [],
            fileList2: [],
            fileList3: [],
            showBigImg: false,
            showBigImgUrl: '',
            footWidth: '0px',
            loading: true,//列表loading
            loading1: true,//列表loading
            tableData: [],
            tableDataDetail: [],
            searchForm: {
                hospital_name: '',
                doctorName: '',
                limit: limit,
                currentPage: 1,
                start_time: '',
                end_time: '',
                time: []
            },
            searchFormDetail: {
                hospital_name: '',
                doctor_code: '',
                doctorName: '',
                limit: 99999,
                currentPage: 1,
                start_time: '',
                end_time: '',
                time: []
            },

            total: 0,
            totalPage: 0,
            titleName: '',
            addVisible: false,
            isAuditVisible: false,
            addForm: {},
            auditForm: {
                is_audit: '',
                audit_script: '',
                audit_spec: ''
            },
            addFormRules: {
                doctorName: [
                    { required: true, message: "请输入医生名称", trigger: "blur" }
                ],
                workYearStr: [
                    { required: true, message: "请输入从医年限", trigger: "blur" }
                ],

            },

            type: '',//add,edit
            row: null,
            deptOptions: [],//组织机构树
            deptSeOptions: [],
            aduitOptions: [],
            restaurants: [],
            deptInfo: {
                id: '',
                name: ''
            },
            referrerOptions: [],
            hospitalOptions: [],
            options: options,
        }
    },
    mounted() {
        this.getSeDeptOptions();
        var that = this;

        document.getElementsByClassName('content')[0].onclick = function () {
            if (that.currentChange) {
                that.currentChange = false;
            } else {
                that.setCurrent();
            }
        }
        that.footWidth = that.$refs.table.bodyWidth;
        this.searchFormDetail.start_time = moment().subtract(7, 'days').format('YYYY-MM-DD');
        this.searchFormDetail.end_time = moment().format('YYYY-MM-DD');
        this.getData()
    },
    methods: {
        querySearch(queryString, cb) {
            var restaurants = this.restaurants;
            var results = queryString ? restaurants.filter(this.createFilter(queryString)) : restaurants;
            // 调用 callback 返回建议列表的数据
            cb(results);
        },
        createFilter(queryString) {
            return (restaurant) => {
                return (restaurant.value.indexOf(queryString) > -1);
            };
        },
        handleSelect(item) {
            console.log(item);
            this.auditForm.audit_script = item.audit_script;
        },
        handleHospital(val) {
            this.addForm.deptId = '';
            this.deptOptions = [];
            if (val != '') {
                this.getDeptOptions();
            }
        },
        handleIsAduit(val) {
            let that = this;
            this.auditForm.audit_script = '';
            this.aduitOptions = [];
            console.log(val);
            if (val != '') {
                // this.restaurants = this.getAuditOptions();
                this.getAuditOptions().then(res => {
                    that.restaurants = res;
                });
            }
        },
        getAuditOptions() {
            return new Promise((resolve, reject) => {
                this.$http.post(BaseUrl.getAuditSelect, { "id": this.auditForm.is_audit }).then(res => {
                    if (res.data.code == 0) {
                        this.aduitOptions = res.data.data;
                        let restaurants = res.data.data.map(item => {
                            item.value = item.audit_script;
                            return item;
                        })
                        resolve(restaurants);

                    } else {
                        this.$message({
                            message: res.data.msg,
                            type: 'warning'
                        })
                    }
                });
            })

        },
        handleDept() {
            //render函数没有自动更新，需手动强制刷新
            //不加这个，导致选中科室，框里没有回显，需要改变其他框的值才能正常显示
            this.$forceUpdate();
        },
        //更改所属区域
        handleChangeAddr(value) {
            this.addForm.addrArry = value;
            this.addForm.hospitalId = '';
            this.hospitalOptions = [];

            this.getHospitalOptions('', this.addForm.addrArry);
        },
        getHospitalOptions(hospital_name, addrArry) {
            this.$http.post(BaseUrl.getHospitalSelectByArea, { hospital_name: hospital_name, province: addrArry[0], city: addrArry[1], county: addrArry[2] }).then(res => {
                if (res.data.code == 0) {
                    this.hospitalOptions = res.data.data;
                }
            });
        },
        getSeDeptOptions() {
            this.$http.post(BaseUrl.getDept).then(res => {
                if (res.data.code == 0) {
                    this.deptSeOptions = res.data.data;
                } else {
                    this.$message({
                        message: res.data.msg,
                        type: 'warning'
                    })
                }
            });
        },
        getDeptOptions() {
            this.$http.post(BaseUrl.getDeptSelect).then(res => {
                if (res.data.code == 0) {
                    this.deptOptions = res.data.data;
                } else {
                    this.$message({
                        message: res.data.msg,
                        type: 'warning'
                    })
                }
            });
        },
        getReferrerOptions() {
            this.$http.post(BaseUrl.getReferrerList).then(res => {
                if (res.data.code == 0) {
                    this.referrerOptions = res.data.data;
                } else {
                    this.$message({
                        message: res.data.msg,
                        type: 'warning'
                    })
                }
            });
        },
        setCurrent(row) {//取消单选
            //this.$refs.table.setCurrentRow(row);
        },
        handleCurrentChangeT(row, index) {
            this.currentChange = true;
            // this.$refs.table.setCurrentRow(row);
        },
        getData() {
            this.loading = true;//列表loading
            this.$http.post(BaseUrl.getDoctorPageLimit, this.searchForm).then(res => {
                if (res.data.code == 0) {
                    this.tableData = res.data.data.data;
                    this.totalPage = res.data.data.totalPage;
                    this.total = res.data.data.total;
                    this.loading = false;//列表loading
                } else {
                    this.$message({
                        message: res.data.msg,
                        type: 'warning'
                    })
                }

            })
        },
        getDataDetail() {

            this.loading1 = true;//列表loading
            this.searchFormDetail.doctor_code = this.row.id;
            this.$http.post(BaseUrl.getDoctorTranById, this.searchFormDetail).then(res => {
                if (res.data.code == 0) {
                    this.tableDataDetail = res.data.data.data;
                    // this.totalPage = res.data.data.totalPage;
                    // this.total = res.data.data.total;
                    this.loading1 = false;//列表loading
                } else {
                    this.$message({
                        message: res.data.msg,
                        type: 'warning'
                    })
                }

            })
        },
        handleSizeChange(val) {
            this.searchForm.limit = val;
            this.getData();
        },
        handleCurrentChange(val) {
            this.searchForm.currentPage = val;
            this.getData();
        },
        reset() {
            this.resetSearchForm(this.searchForm, [
                { k: 'limit', v: this.searchForm.limit },
                { k: 'currentPage', v: this.searchForm.currentPage },
                { k: 'start_time', v: moment().subtract(7, 'days').format('YYYY-MM-DD') },
                { k: 'end_time', v: moment().format('YYYY-MM-DD') },
                { k: 'time', v: [moment().subtract(7, 'days').format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')] }
            ]);
            // this.getData();
        },
        resetDetail() {
            this.resetSearchForm(this.searchFormDetail, [
                { k: 'limit', v: this.searchFormDetail.limit },
                { k: 'currentPage', v: this.searchFormDetail.currentPage },
                { k: 'start_time', v: moment().subtract(7, 'days').format('YYYY-MM-DD') },
                { k: 'end_time', v: moment().format('YYYY-MM-DD') },
                { k: 'time', v: [moment().subtract(7, 'days').format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')] }
            ]);
            // this.getData();
        },
        deleteStation(row) {
            this.resetDetail()
            this.addVisible = true;
            this.titleName = "积分明细";
            this.row = row;
            this.getDataDetail();
        },
        noClick() {
            this.getData();
            this.addVisible = false;
        },

        /**
         * 导出
         */
        exportFile() {
            let obj = {
                start_time: this.searchFormDetail.start_time,
                end_time: this.searchFormDetail.end_time,
                doctor_code: this.row.id
            };
            this.$http.postFile(BaseUrl.doctorJDetailExport, obj, {
                responseType: 'arraybuffer'
            }).then(res => {
                var blob = new Blob([res.data], {
                    type: 'application/vnd.ms-excel;charset=utf-8'
                });
                var downloadElement = document.createElement('a');
                var objectUrl = URL.createObjectURL(blob);
                downloadElement.href = objectUrl;
                downloadElement.download = '医生积分明细报表.xls'; //下载后文件名
                document.body.appendChild(downloadElement);
                downloadElement.click(); //点击下载
                document.body.removeChild(downloadElement); //下载完成移除元素
                window.URL.revokeObjectURL(objectUrl); //释放掉blob对象
            }).catch(
                function (res) { }
            )

        },

    }

}
</script>
<style scoped>
.selectWdith {
    width: 200px;
}
</style>